import axios from "axios";
import React from "react";
import styled, { keyframes } from "styled-components";
import { BASE_URL } from "../../api/constants";

interface IProps {
  nameFile: string;
  setNameFile: React.Dispatch<React.SetStateAction<string>>;
  loadingPopUp: boolean;
  afterLoadingPopUP: boolean;
  setLoadingPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setPopUpRename: React.Dispatch<React.SetStateAction<boolean>>;
  setAfterLoadingPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  node: any;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  refreshParent?: Function;
}

export const WarRenamePopup = ({
  nameFile,
  setNameFile,
  loadingPopUp,
  setLoadingPopUp,
  setAfterLoadingPopUp,
  afterLoadingPopUP,
  node,
  setRefresh,
  setPopUpRename,
  refreshParent,
}: IProps) => {
  return (
    <>
      <DivPopUp>
        <DivTextPopUp>
          <label>אנא כתוב את השם החדש </label>
          <input
            type="text"
            value={nameFile}
            onChange={(e) => {
              setNameFile(e.target.value);
            }}
          ></input>
          {!loadingPopUp ? (
            <DivButtonPopUp>
              <button
                id="yes"
                onClick={() => {
                  setLoadingPopUp(true);
                  setAfterLoadingPopUp(false);
                  axios
                    .post(
                      `${BASE_URL}/warTree/renameFile`,
                      { idFile: node?._id, name: nameFile },
                      {
                        withCredentials: true,
                      }
                    )
                    .then((result) => {
                      setAfterLoadingPopUp(true);
                      setRefresh(true);
                      refreshParent && refreshParent();
                      setTimeout(() => {
                        setLoadingPopUp(false);
                      }, 2000);
                      setTimeout(() => {
                        setPopUpRename(false);
                      }, 1000);
                    });
                }}
              >
                אישור
              </button>
              <button
                id="no"
                onClick={() => {
                  setPopUpRename(false);
                }}
              >
                ביטול
              </button>
            </DivButtonPopUp>
          ) : afterLoadingPopUP ? (
            <DivPositionLoading>השם שונה ל - {nameFile}</DivPositionLoading>
          ) : (
            <DivInmationLoading className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </DivInmationLoading>
          )}
        </DivTextPopUp>
      </DivPopUp>
    </>
  );
};

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }

`;
const ellipsis3 = keyframes` 
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsis2 = keyframes` 
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const DivInmationLoading = styled.div`
  &&.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 20%;
  }
  &&.lds-ellipsis div {
    position: absolute;
    top: 18px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #8a8989;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  &&.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ${ellipsis1} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;

const DivPopUp = styled.div`
  position: absolute;
  top: 20%;
  right: 11%;
  z-index: 2;
`;
const DivTextPopUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 17rem;
  height: 12rem;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  border: 3px solid #d1d0d0;
  box-shadow: 5px 25px 25px 10px #8a8989;
  label {
    font-size: 1.5rem;
    /* border-bottom: 1px double black; */
  }
  input {
    /* border-radius: 25px; */
    font-size: 1rem;
    outline: none;
    border: none;
    border-bottom: 1px solid black;
  }
`;
const DivButtonPopUp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  height: 20%;
  #yes {
    width: 5rem;
    height: 3rem;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    outline: none;
    background: white;
    padding: 0.8rem;
    border-radius: 5px;
    border: none;
    border: 2px solid green;
  }
  #no {
    width: 5rem;
    height: 3rem;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    outline: none;
    background: white;
    padding: 0.8rem;
    border-radius: 5px;
    border: none;
    border: 2px solid red;
  }
`;
const DivPositionLoading = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: green;
  height: 20%;
`;
