import React from "react";
import { Switch, SwitchLabel, SwitchRadio, SwitchSelection } from "./styles";

const ClickableLabel = ({
  title,
  onChange,
  id,
  width,
  fontsize,
}: {
  title: string;
  onChange: Function;
  id: string;
  width?: string;
  fontsize?: string;
}) => (
  <SwitchLabel
    onClick={() => onChange(title)}
    className={id}
    width={width}
    fontsize={fontsize}
  >
    {title}
  </SwitchLabel>
);

const ConcealedRadio = ({
  value,
  selected,
}: {
  value: string;
  selected: string;
}) => <SwitchRadio type="radio" name="switch" checked={selected === value} />;

interface IProps {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  values: string[];
  width?: string;
  fontsize?: string;
}

export const ToggleSwitch = ({
  selected,
  setSelected,
  values,
  width,
  fontsize,
}: IProps) => {
  const handleChange = (val: string) => {
    setSelected(val);
  };

  const selectionStyle = () => {
    return {
      left: `${(values.indexOf(selected) / values.length) * 100}%`,
    };
  };

  return (
    <Switch>
      {values.map((val) => {
        return (
          <span>
            <ConcealedRadio value={val} selected={selected} />
            <ClickableLabel
              id={val}
              title={val}
              onChange={handleChange}
              width={width}
              fontsize={fontsize}
            />
          </span>
        );
      })}
      <SwitchSelection width={width} style={selectionStyle()} />
    </Switch>
  );
};
