import React, {
  Dispatch,
  useEffect,
  useState,
  useRef,
  ReactElement,
} from "react";
import styled, { keyframes } from "styled-components";
import backSvg from "../../assets/Svgs/Physiological/back.svg";

import { ReactComponent as HeatSvg } from "../../assets/Svgs/Physiological/heat.svg";
import { ReactComponent as InjuriesSvg } from "../../assets/Svgs/Physiological/injuries.svg";
import { ReactComponent as LiquidSvg } from "../../assets/Svgs/Physiological/liquid.svg";
import { ReactComponent as CaloriesSvg } from "../../assets/Svgs/Physiological/calories.svg";
import { ReactComponent as ColdnessSvg } from "../../assets/Svgs/Physiological/coldness.svg";

interface IProps {
  children: (func: () => void | undefined) => ReactElement;
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
  option: string;
}

export const PopupForm = ({ option, children, isOpen, setIsOpen }: IProps) => {
  const [animationSeconds, setAnimationSeconds] = useState(0);
  const myRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setAnimationSeconds(0.7);
    }, 1000);
  }, []);

  const executeScroll = () => {
    console.log(true);
    myRef.current?.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };
  return (
    <Wrapper
      open={isOpen}
      key={isOpen ? 0 : 1}
      animationSeconds={animationSeconds}
      ref={myRef}
    >
      <Header>
        {option == "coldness" && (
          <IconText className="coldness">
            <ColdnessSvg />
            <p>{"עומס קור"}</p>
          </IconText>
        )}
        {option == "heat" && (
          <IconText className="heat">
            <HeatSvg />
            <p>{"עומס חום"}</p>
          </IconText>
        )}
        {option == "liquid" && (
          <IconText className="liquid">
            <LiquidSvg />
            <div className="title-and-subtitle">
              <p className="title">{"נוזלים ומלחים"}</p>
              {/* <p className="subtitle">{"חישוב תצרוכת מים"}</p> */}
            </div>
          </IconText>
        )}
        <Back
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <img src={backSvg} alt={"סגירה"} />
          <p>{"סגירה"}</p>
        </Back>
      </Header>
      <br />
      <br />
      {children(executeScroll)}
    </Wrapper>
  );
};

const Header = styled.div`
  position: sticky;
  top: 0px;
  background: white;
  padding-bottom: 1rem;
  z-index: 10;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
  .heat {
    svg {
      fill: #eb5e00;
    }
  }
  .coldness {
    svg {
      fill: #8cc9ce;
    }
  }
  .liquid {
    svg {
      fill: #07446d;
    }
  }
`;

const Back = styled.div`
  position: absolute;
  display: flex;

  top: 0;
  left: 0;
  color: #3c85dc;
  margin-left: 0.5rem;
  margin-top: 1.25rem;
  img {
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
  }
  p {
    margin: 0 0.5rem;
    text-align: center;
    font-weight: 600;
  }
`;

const openModal = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
  `;

const closeModal = keyframes`
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateY(100%);
    }
  `;

interface WrapperProps {
  open: boolean;
  animationSeconds: number;
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  bottom: 0;
  height: 90vh;
  width: 100vw;
  max-width: 100vw;

  background-color: white;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  z-index: 3;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  animation: ${(props) => (props.open ? openModal : closeModal)}
    ${(props) => props.animationSeconds}s ease-out forwards;
`;

const IconText = styled.div`
  /* position: absolute; */
  /* padding: 1rem; */
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
  p {
    margin-right: 0.5rem;
    color: black !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .title-and-subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* margin-top: 1rem; */
  }
  .title {
    margin: 0;
  }

  .subtitle {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin: 0;
  }
`;
