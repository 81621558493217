
import usePermissions from "../../hooks/usePermissions";
import { ContactUser } from "./ContactUser";
import { ContactSupportTickets } from "./ContactSupportTickets";

export interface ITicket {
  _id: string;
  user: string;
  type: string;
  fullName: string;
  reply?: string;
  content: string;
  status: string;
  createdAt: Date;
  respondentNameId?: string;
  respondentDate?: Date;
}

export const Contact = () => {
  const { isSupportUser } = usePermissions();

  return <>{isSupportUser ? <ContactSupportTickets /> : <ContactUser />}</>;
};
