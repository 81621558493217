import {
  PersonalArea,
  SectionWrapper,
  Img,
  TopWrapper,
  UserName,
  PersonalSectionWrapper,
  BigSectionWrapper,
  PersonalImg,
} from "../AdminTop/styles";
import PersonalAreaImg from "../../../assets/user_1 1 (1).png";
import messages from "../../../assets/Group 599.png";
import heart from "../../../assets/star.png";
import whatsapp from "../../../assets/XMLID_468_.png";
import phone from "../../../assets/Vector.png";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfo } from "../../../store/userInfo";
import { useHistory } from "react-router";
import { closeContactNumber, shouldShowFavorites } from "..";
import { Notification } from "../AdminTop";
interface IProps {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserTop = ({ setMenuOpen }: IProps) => {
  const user = useRecoilValue(userInfo);
  const history = useHistory();
  const setShouldShowFavorites = useSetRecoilState(shouldShowFavorites);
  const ContactNumber = useRecoilValue(closeContactNumber);
  const userName = user.user.displayName;

  return (
    <>
      <TopWrapper>
        {ContactNumber !== 0 && (
          <Notification profileOpen={false}>
            <div>{ContactNumber}</div>
          </Notification>
        )}
        <UserName>
          <p>{` שלום, ${userName} `}</p>
        </UserName>
      </TopWrapper>
      <PersonalSectionWrapper>
        <PersonalArea>
          <PersonalImg src={PersonalAreaImg} alt=""></PersonalImg>
          <p>איזור האישי</p>
        </PersonalArea>
      </PersonalSectionWrapper>

      <BigSectionWrapper>
        <SectionWrapper
          onClick={() => {
            setShouldShowFavorites(true);
            setMenuOpen(false);
            history.push("/");
          }}
        >
          <Img src={heart} alt=""></Img>
          <p>מועדפים</p>
        </SectionWrapper>
      </BigSectionWrapper>

      <BigSectionWrapper>
        <SectionWrapper
          onClick={() => {
            setMenuOpen(false);
            // setShouldShowContact(true);
            history.push("/contact");
          }}
        >
          <Img src={messages} alt=""></Img>
          <p>פנו אלינו</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper href={`tel:972529410491`}>
          <Img src={phone} alt=""></Img>
          <p>דברו איתנו</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper
          href={`https://wa.me/972529410491?text`}
          data-action="share/whatsapp/share"
          target="_blank"
        >
          <Img src={whatsapp} alt=""></Img>
          <p>כתבו לנו</p>
        </SectionWrapper>
      </BigSectionWrapper>
    </>
  );
};
