import axios from "axios";
import {
  DivInmationLoading,
  Gap,
  InputWrapper,
  Message,
  NameInput,
  TextInput,
  TextWrapper,
} from "./styles";
import { Dispatch, useEffect, useState } from "react";
import { BASE_URL } from "../../../../api/constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../../../store/userInfo";
import {
  ContactBottom,
  P0,
  Button,
  ButtonBackground,
  BottomBackground,
} from "../styles";
import { ITicket } from "../../../Contact";

const sendTicket = (
  email: String,
  content: String,
  type: String,
  setLoading: Dispatch<React.SetStateAction<boolean>>,
  setContinueButton: Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);
  return axios
    .post(
      `${BASE_URL}/contactTickets/sendTicket`,
      {
        email,
        content,
        type,
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res?.data?.success) {
        setContinueButton(false);
        setLoading(false);
        return true;
      }
      setContinueButton(true);
      setLoading(false);
      return false;
    });
};
interface IProps {
  setMode: Dispatch<React.SetStateAction<string>>;
}
export const ContactPage = ({ setMode }: IProps) => {
  const user = useRecoilValue(userInfo);
  const [content, setContent] = useState("");
  const email = user.user.userPrincipalName;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [continueButton, setContinueButton] = useState(true);
  const [viewTicket, setViewTicket] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [ticket, setTicket] = useState<ITicket>({} as ITicket);

  return (
    <>
      <InputWrapper>
        <NameInput disabled={true} value={user.user.displayName}></NameInput>
      </InputWrapper>
      <Gap />
      <TextWrapper>
        <TextInput
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="הקלד\י תוכן פנייה"
        ></TextInput>
      </TextWrapper>

      {!message && !loading ? (
        <ContactBottom>
          <Button
            onClick={async () => {
              const sent = await sendTicket(
                email,
                content,
                "פנייה לעזרה",
                setLoading,
                setContinueButton
              );
              if (sent) {
                setMessage("הפנייה נשלחה בהצלחה.");
                console.log("ההודעה נשלחה");
              } else {
                setMessage("קרתה תקלה בעת שליחת הפנייה");
                console.log("ההודעה לא נשלחה");
              }
            }}
          >
            <P0>סיום ושליחה</P0>
          </Button>
        </ContactBottom>
      ) : (
        <ButtonBackground>
          <Button>
            <P0 onClick={() => setMode("ראשי")}>חזור</P0>
          </Button>
        </ButtonBackground>
      )}

      {loading ? (
        <BottomBackground>
          <DivInmationLoading className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </DivInmationLoading>
        </BottomBackground>
      ) : (
        message && (
          <BottomBackground>
            <Message>{message}</Message>{" "}
          </BottomBackground>
        )
      )}
    </>
  );
};
