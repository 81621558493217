import axios from "axios";
import React, { Dispatch, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled, { keyframes } from "styled-components";
import { BASE_URL } from "../../api/constants";
import { userInfo } from "../../store/userInfo";
import { CardFile } from "../CardFile";
import { TreeNodeType } from "../TreeNode";
import { ReactComponent as rename } from "../../assets/Svgs/Rename.svg";
import { ReactComponent as addFolder } from "../../assets/Svgs/AddFolder.svg";
import { ReactComponent as IconClose } from "../../assets/Svgs/iconClose.svg";
import { ReactComponent as cloudUpload } from "../../assets/Svgs/cloudUpload.svg";
import { ReactComponent as deleteSvgrepo } from "../../assets/Svgs/deleteSvgrepo.svg";
import { useTimer } from "../../hooks/useTimer";
import { SortFiles, SortForm } from "../SafetyLiterature/SortFiles";

const handleUpload = async (
  sortForm: SortForm,
  files: any,
  nodeId: string,
  userId: string
) => {
  const formData = new FormData();

  Array.from(files).map((file: any) => {
    formData.append("files", file);
  });
  Object.entries(sortForm).forEach(([key, value]) => {
    formData.append(key, value);
  });
  const { data } = await axios.post(
    `${BASE_URL}/tree/file/${nodeId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        id: userId,
      },
      withCredentials: true,
    }
  );
  return data;
};
interface extendedTreeNode extends TreeNodeType {
  onClickFunction: Function;
}
interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  selectedFolder: extendedTreeNode | undefined;
  setNodes?: Dispatch<React.SetStateAction<TreeNodeType[]>>;
  nodes?: TreeNodeType[];
  isCatalog?: boolean;
  isThereNodes?: boolean;
  setIsThereNodes: Dispatch<React.SetStateAction<boolean>>;
  isFilmCatalog: boolean;
}

export const UploadModal = ({
  setShowModal,
  selectedFolder,
  showModal,
  setNodes,
  isCatalog,
  nodes,
  isThereNodes,
  setIsThereNodes,
  isFilmCatalog,
}: IProps) => {
  const user = useRecoilValue(userInfo);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [folderName, setFolderName] = useState("");
  const [message, setMessage] = useState("");
  const [isNewFolder, setIsNewFolder] = useState(false);
  const [isNewName, setIsNewName] = useState(false);
  const [newName, setNewName] = useState(selectedFolder?.displayName);
  const [animationSeconds, setAnimationSeconds] = useState(0);
  const [popUpDelete, setPopUpDelete] = useState(false);
  const { timeLeft, setActive, resetTimer } = useTimer(5);
  const [validData, setValidData] = useState(false);
  const [isIcon, setIsIcon] = useState(false);
  const [openSortFiles, setOpenSortFiles] = useState(false);
  const [sortForm, setSortForm] = useState({
    activityType: "",
    individualActivity: "",
    FilmType: "",
    researchType: "",
    year: 0,
  });
  const validateFields = () => {
    setValidData(
      sortForm.activityType === "מאפיין תחומי" &&
        sortForm.individualActivity === "מאפיין פעילות הפרט" &&
        sortForm.FilmType === "סוג סרט" &&
        sortForm.researchType === "סוג תחקיר" &&
        sortForm.year === 0
    );
    if (
      sortForm.activityType !== "מאפיין תחומי" &&
      sortForm.individualActivity !== "מאפיין פעילות הפרט" &&
      sortForm.FilmType !== "סוג סרט" &&
      sortForm.researchType !== "סוג תחקיר" &&
      sortForm.year !== 0
    ) {
      handleUploadClick();
    }
  };
  // const [timerForDelete, setTimerForDelete] = useState<number>(5);

  // useEffect(() => {
  //   let interval = null;
  //   if (timerForDelete > 0 && popUpDelete) {
  //     interval = setInterval(() => {
  //       setTimerForDelete((prev) => prev - 1);
  //     }, 1000);
  //   }
  // }, [popUpDelete]);

  useEffect(() => {
    setTimeout(() => {
      setAnimationSeconds(0.7);
    }, 1000);
  }, []);

  useEffect(() => {
    if (showModal) {
      setMessage("");
    }
  }, [showModal]);

  useEffect(() => {
    setNewName(selectedFolder?.displayName);
  }, [selectedFolder]);

  const handleUploadClick = () => {
    hiddenFileInput.current!.click();
  };

  const handleChangeFiles = async (e: any) => {
    if (selectedFolder?._id) {
      const data = await handleUpload(
        sortForm,
        e.target.files,
        selectedFolder?._id,
        user.user.userPrincipalName
      );
      if (data.success) {
        setMessage(`הקובץ צורף בהצלחה`);

        setTimeout(() => setShowModal(false), 3000);
        console.log(isThereNodes);
        if (isThereNodes) {
          setNodes?.((prev) => [
            ...prev,
            {
              ...data.newFiles,
            },
          ]);
        } else {
          setNodes?.([{ ...data.newFiles }]);
          setIsThereNodes(true);
          console.log("nodes");
        }

        setSortForm({
          activityType: "",
          individualActivity: "",
          FilmType: "",
          researchType: "",
          year: 0,
        });
      }
      // if (selectedFolder?.onClickFunction) {
      //   selectedFolder?.onClickFunction();
      // }
    }
  };
  const handleNewFolder = () => {
    setMessage("טוען...");
    if (folderName) {
      axios
        .post(
          `${BASE_URL}/tree/folder/${folderName}`,
          {
            parent: selectedFolder?._id,
            isIcon: isIcon,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          // if (selectedFolder?.onClickFunction) {
          //   selectedFolder?.onClickFunction();
          // }

          setTimeout(() => {
            setShowModal(false);
          }, 3000);
          setMessage(`התיקייה  ${folderName} נוצרה בהצלחה`);
          setIsNewFolder(false);
          setIsIcon(false);
          setFolderName("");
          setNodes?.((prev) => [
            ...prev,
            {
              ...res.data,
            },
          ]);
        });
    } else {
      setMessage(`יש להזין את את שם התקייה החדשה`);
    }
  };

  const handleDeleteFolder = () => {
    resetTimer();
    setPopUpDelete(false);
    setMessage("טוען...");
    axios
      .post(
        `${BASE_URL}/tree/deleteFolder`,
        { node: selectedFolder },
        {
          withCredentials: true,
        }
      )
      .then(() => {
        // if (selectedFolder?.onClickFunction) {
        //   selectedFolder?.onClickFunction();
        // }
        setMessage(`התיקייה  ${selectedFolder?.displayName} נמחקה בהצלחה`);
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      });
  };

  const handleChangeFolderName = () => {
    setMessage("טוען...");
    if (newName) {
      axios
        .post(
          `${BASE_URL}/tree/changeFileName`,
          {
            id: selectedFolder?._id,
            displayName: newName,
          },
          {
            withCredentials: true,
          }
        )
        .then((_) => {
          // if (selectedFolder?.onClickFunction) {
          //   selectedFolder?.onClickFunction();
          // }
          setMessage(`השם שונה בהצלחה ל"${newName}"`);
          setIsNewName(false);
          setNewName(selectedFolder?.displayName);
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
        });
    } else {
      setMessage(`יש למלא שם חדש לתיקייה`);
    }
  };

  return (
    <Wrapper open={showModal} animationSeconds={animationSeconds}>
      <DivCloseButton>
        <IconClose
          onClick={() => {
            setShowModal(false);
            setPopUpDelete(false);
            setIsNewFolder(false);
            setIsNewName(false);
            resetTimer();
          }}
        />
      </DivCloseButton>

      <Title>
        <div id="title1">{`בחרת בתיקייה `}</div>
        <div id="title2">{`"${selectedFolder?.displayName}"`}</div>
      </Title>
      {/* <DownloadFile />//זה של קומפוננתה של הצגת העלת קבצים כמה אחוז הם */}
      {openSortFiles && (
        <SortFiles
          validData={validData}
          onClick={handleUploadClick}
          bottomText={"העלאה"}
          isFilm={isFilmCatalog}
          setSortForm={setSortForm}
          sortForm={sortForm}
        />
      )}
      <Buttons>
        <StyledFileInput
          onClick={() => {
            isCatalog ? setOpenSortFiles(true) : handleUploadClick();
          }}
        >
          {" "}
          <input
            type="file"
            name="file"
            ref={hiddenFileInput}
            multiple
            onChange={handleChangeFiles}
            style={{ display: "none" }}
          />
          <CardFile Icon={cloudUpload} text1="העלאת" text2="קבצים למערכת" />
        </StyledFileInput>
        <CardFile
          Icon={addFolder}
          text1="יצירת"
          text2="תיקיה חדשה"
          OnClick={() => {
            setIsNewFolder(!isNewFolder);
            setIsNewName(false);
          }}
        />
      </Buttons>
      <Buttons>
        <CardFile
          Icon={deleteSvgrepo}
          text1="מחיקה"
          text2=""
          // OnClick={handleDeleteFolder}
          OnClick={() => {
            if (
              selectedFolder?.displayName !== "ספרות הבטיחות" &&
              selectedFolder?.displayName !== "נושאים במיקוד" &&
              selectedFolder?.displayName !== "קטלוג התחקירים" &&
              selectedFolder?.displayName !== "קטלוג הסרטונים" &&
              selectedFolder?.displayName !== `ביה"ס לבטיחות`
            ) {
              setPopUpDelete(true);
              setActive(true);
            }
          }}
        />
        <CardFile
          Icon={rename}
          text1="שנה שם"
          text2=""
          OnClick={() => {
            setIsNewName(!isNewName);
            setIsNewFolder(false);
          }}
        />
        {/* <button onClick={handleDeleteFolder}>מחיקה</button>
        <button
          onClick={() => {
            setIsNewName(!isNewName);
            setIsNewFolder(false);
          }}
        >
          {"שנה שם"}
        </button> */}
      </Buttons>

      {popUpDelete && (
        <PopupDeleteFolder>
          <TitleDeleteText>
            <div id="title1">
              שים לב מחיקת התיקייה תוביל למחיקת כל הקבצים והתיקיות שנמצאים
              בתוכה!{" "}
            </div>
            <div id="title1">האם אתה בטוח שאתה רוצה למחוק את</div>
            <div id="nameFolder">{selectedFolder?.displayName} ?</div>
          </TitleDeleteText>
          <div id="buttons">
            <DivButtonDelete
              background="green"
              disabled={timeLeft != 0}
              onClick={handleDeleteFolder}
            >
              {`מאשר ${timeLeft != 0 ? timeLeft : ""}`}
            </DivButtonDelete>
            <DivButtonDelete
              background="red"
              onClick={() => {
                setPopUpDelete(false);
                resetTimer();
              }}
            >
              ביטול
            </DivButtonDelete>
          </div>
        </PopupDeleteFolder>
      )}

      <Loading>
        {message === "טוען..." && (
          <DivInmationLoading className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </DivInmationLoading>
        )}
        {message != "טוען..." && <div id="messageTexst">{message}</div>}
      </Loading>

      <Buttons>
        {isNewFolder && (
          <DivLabalTexst>
            <InputTexst
              value={folderName}
              onChange={(e) => {
                setFolderName(e.target.value);
                setMessage("");
              }}
            />
            <label>האם אייקון</label>

            <input
              type="checkbox"
              onChange={() => {
                setIsIcon((prev) => !prev);
              }}
            />
            <Button onClick={handleNewFolder}>צור</Button>
          </DivLabalTexst>
        )}

        {isNewName && (
          <DivLabalTexst>
            <InputTexst
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value);
                setMessage("");
              }}
            />
            <Button onClick={handleChangeFolderName}>שנה</Button>
          </DivLabalTexst>
        )}
      </Buttons>
    </Wrapper>
  );
};

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }

`;
const ellipsis3 = keyframes` 
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsis2 = keyframes` 
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const DivInmationLoading = styled.div`
  &&.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 35px;
  }
  &&.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  &&.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ${ellipsis1} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;

const PopupDeleteFolder = styled.div`
  background: white;
  color: black;
  font-size: 1.2rem;
  position: absolute;
  height: 35vh;
  width: 75vw;
  padding: 1rem;
  padding-top: -5rem;
  top: 15rem;
  right: 8%;
  z-index: 13;
  border-radius: 20px;

  #buttons {
    display: flex;
    flex-direction: row;
    width: 90%;
    padding-top: 10rem;
    margin: auto;
    color: white;
    justify-content: space-around;
    margin-top: -5rem;
  }
`;

const TitleDeleteText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  #title1 {
    text-align: center;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
  }
  #nameFolder {
    font-weight: bold;
    font-size: 1.5rem;
  }
`;

const DivIcone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  #icon {
    font-size: 10rem;
    height: 2rem;
    padding: 1rem;
    margin-top: 3rem;
    border-radius: 50%;
    background: green;
  }
`;

interface ButtonProps {
  background: string;
}

const DivButtonDelete = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  color: white;
  background: ${(props) => props.background};
  :disabled {
    background: gray;
    opacity: 0.5;
  }
  font-size: 1.2rem;
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-weight: bold;
  letter-spacing: 2px;
`;
const DivButtonNo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  padding: 1rem;
  border-radius: 10px;
  font-weight: bold;
  letter-spacing: 2px;
`;

const DivLabalTexst = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  height: 1rem;
`;

const InputTexst = styled.input`
  border: none;
  border-radius: 10px;
  width: 40vw;
  height: 3vh;
  outline: none;
  font-size: 1.2rem;
  padding-right: 0.5rem;
`;
const Button = styled.button`
  font-weight: bold;
  font-size: 1.5rem;
  width: 8rem;
  height: 4rem;
  margin: 1rem;
  border-radius: 1.5rem;
  border: none;
  padding: 0.7rem;
  background: #3c85dc;
  color: white;
  letter-spacing: 2px;
`;

const DivCloseButton = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-bottom: 4.5rem;
  svg {
    fill: white;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  margin-bottom: 3.5rem;
  #title1 {
    font-size: 1rem;
    margin-bottom: -0.2rem;
  }
  #title2 {
    font-size: 1.5rem;
  }
`;

const Loading = styled.div`
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  letter-spacing: 2px;
  #messageTexst {
    margin-top: 2.2rem;
  }
`;

const StyledUploadModal = styled.div`
  z-index: 12;
  margin: 1rem;
  background-color: blue;
  /* p {
    font-size: 0.9rem;
    font-weight: 600;
  } */
`;

const StyledFileInput = styled.div``;

const Buttons = styled.div`
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const openModal = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
        height: 90vh;
      padding-top: 1rem;

    }
  `;

const closeModal = keyframes`
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
      height: 0;
      transform: translateY(100%);
      padding-top: 0rem;
    }
  `;

interface WrapperProps {
  open: boolean;
  animationSeconds: number;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  bottom: 0;
  height: 90vh;
  /* height: ${(props) => (props.open ? "90vh" : "0vh")}; */
  width: 100vw;
  max-width: 100vw;
  padding-top: 1rem;

  background-color: #3664c2;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  z-index: 12;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  animation: ${(props) => (props.open ? openModal : closeModal)}
    ${(props) => props.animationSeconds}s ease-out forwards;
`;
