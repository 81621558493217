import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/Svgs/selectedArrow.svg";
import { MapContainer } from "react-leaflet";
import { MapSearch } from "./map/mapSearch";
export const Wrapper = styled.div``;
export const SafetyForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  svg {
    width: 500px;
    height: 200px;
    fill: blue;
  }
`;
export const WhatssappDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
`;

export const FieldNameActivity = styled.p`
  margin-left: 10vw;
  margin-right: 5vw;
  width: 70vw;
  height: 2vh;
  font-size: 1.2rem;
`;

export const TitleWrapper = styled.div`
  width: 97vw;
  height: 4vh;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-end;
  /* justify-items: start; */
`;

export const Instruction = styled.a`
  text-decoration: none;
  width: 20vw;
  height: 3vh;
  border-radius: 20vw;
  background-color: #5164bf;
  display: flex;
  /* margin-right: 2vw; */
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
`;
export const Title = styled.div`
  width: 60vw;
  /* height: 5vh; */
  text-align: center;
  font-size: 1.7rem;
`;

export const SendButtonWrapper = styled.div`
  width: 100vw;
  height: 10vh;

  /* max-height: 15vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;
export const SendButton = styled.div`
  width: 35vw;
  height: 10vh;
  border-radius: 20vw;
  background-color: #5164bf;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
`;
export const ErrorMes = styled.div`
  width: 98vw;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 0.7rem;
  height: 0.5vh;
  color: red;
  font-size: 1.2rem;
`;

export const RigthMes = styled.div`
  width: 98vw;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 0.7rem;
  font-size: 1.2rem;

  height: 0.5vh;
  color: green;
`;

export const Image = styled.div``;
export const P = styled.p`
  height: 0.3vh;
  display: flex;
  flex-direction: row;
`;
export const BamSentence = styled.p`
  /* height: 0.3vh; */
  width: 70vw;
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: row;
`;
export const P2 = styled.p`
  margin-left: 2vw;
  /* margin-bottom: 2vh; */
`;
export const P1 = styled.div`
  margin-top: -0.5vh;
`;

export const EventSeverity = styled.div`
  width: 85vw;
  height: 4vh;
  appearance: none;
  outline: none;
  margin-right: 5vw;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  border: 1px solid #a39e9e;
  background-color: ${(props) => props.color};

  /* display: inline-block; */
  font: inherit;
`;
export const Severity = styled.p``;

export const InjuriesNumberSelectt = styled.input`
  width: 83vw;
  height: 4vh;
  /* -moz-appearance: textfield; */
  appearance: none;
  outline: none;
  margin-right: 5vw;
  padding-right: 5px;
  color: white;
  /* border: none; */
  border-radius: 0px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  border: 1px solid #a39e9e;
  background-color: #e4e4e4;
  -ms-word-break: normal;
  word-break: normal;
  display: inline-block;
  font: inherit;
`;

export const InjuriesButton = styled.button`
  width: 7vw;
  height: 5vh;
  font-size: 21px;
  margin-right: 1rem;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
`;

export const FieldName = styled.p`
  margin-right: 5vw;
  width: 70vw;
  height: 2vh;
  font-size: 1.2rem;
`;

export const FieldName2 = styled.p`
  margin-right: 5vw;
  margin-bottom: 3vh;
  width: 90vw;
  text-align: center;
  color: red;
  height: 1vh;
  font-size: 1rem;
`;
export const MapAlert = styled.p`
  width: 90vw;
  text-align: center;
  color: red;
  height: 1vh;
  font-size: 1rem;
`;
export const FieldNameImage = styled.p`
  width: 70vw;
  height: 1vh;
  text-align: center;
  font-size: 1.2rem;
`;

export const PicDiv = styled.div`
  margin-top: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0.9rem;
`;

export const SvgExam = styled.svg`
  margin-right: 3.5rem;
  width: 1rem;
  height: 2rem;
`;
export const ExamWrapper = styled.div`
  /* margin-right: 1rem; */

  position: absolute;
  z-index: 2;
  top: 92rem;
  right: 2rem;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  border: 1px solid #a39e9e;
  border-radius: 10px;
  background-color: #f0e9e9;
`;

export const WrapperLoc = styled.div`
  width: max-content;
`;
export const ImageSvgWrapper = styled.div`
  width: 25vw;
  height: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1f1e1e;
  border-radius: 10px;
`;

export const Span = styled.span`
  color: red;
`;
export const Raz = styled.div`
  width: 80vw;
`;

export const CorrectedSelect = styled.div`
  & .MuiSelect-icon {
    padding-right: 77vw;
    color: #333;
  }
  & .MuiSelect-iconOpen {
    display: none;
  }
  & .MuiSelect-select {
    padding-right: 2vw !important;
    border-color: #333;
    /* outline: none;
    border: none; */
  }
`;
export const Div = styled.div`
  width: 87vw;
  height: 30vh;
  background-color: #e1e6eb;
  /* margin-right: 5vh; */
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  .leaflet-container {
    width: 100%;
    height: 28vh;
  }
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
export const MapWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const UrlDiv = styled.div`
  background-color: grey;
  width: 87vw;
  height: 10vh;
`;
export const StyledMapContainer = styled(MapContainer)`
  .reset {
    display: none;
  }
  .leaflet-pane {
    z-index: 1;
  }
  .leaflet-control-geosearch form {
    width: 60vw;
  }
  .leaflet-top,
  .leaflet-bottom {
    z-index: 1;
  }
`;
export const BottomDiv = styled.div`
  height: 7vh;
`;
