import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 10;

  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(127, 127, 127, 0.6);
`;
export const FieldName = styled.p`
  text-align: center;
  color: red;
  height: 1vh;
  font-size: 1rem;
`;
export const Wrapper = styled.div`
  width: max-content;
`;
export const X = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: black;
`;
export const ModalBody = styled.div`
  position: fixed;
  margin: 10% auto;
  padding: 2rem;
  width: 50%;
  border-radius: 1rem;
  text-align: center;
  color: green;
  font-size: 1.5rem;

  display: flex;
  flex-direction: column;
  white-space: pre-line;

  /* border-radius: 10vw; */
  background-color: white;
`;

export const TextDiv = styled.div`
  /* width: 50vw;
  font-size: 3rem;
  height: 20vh; */
`;
export const BoldWhatsapp = styled.b`
  margin-top: 2vh;
  color: black;
  font-size: 1rem;
`;
