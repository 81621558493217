import {
  BigSectionWrapper,
  Img,
  PersonalArea,
  PersonalSectionWrapper,
  SectionWrapper,
  TimronImg,
} from "../AdminTop/styles";
import group from "../../../assets/Svgs/Group 164.svg";
import smartBase from "../../../assets/Svgs/smartBase.svg";
import click from "../../../assets/Svgs/DESIGNED_BY_FREEPIK.svg";
import weather from "../../../assets/Svgs/אייקון מזג האוויר 1.svg";
import { useHistory } from "react-router";

export const Middle = () => {
  const history = useHistory();

  return (
    <>
      <PersonalSectionWrapper>
        <PersonalArea>
          <Img src={group} alt=""></Img>
          <p>קישורים חיצוניים</p>
        </PersonalArea>
      </PersonalSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper onClick={() => history.push("/smartBasePage")}>
          <Img src={smartBase} alt=""></Img>
          <p>בסיס חכם</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper href="https://go.idf.il/TimrunClick">
          <TimronImg src={click} alt=""></TimronImg>
          <p>תמרון בקליק</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper href="https://ims.gov.il/he/Fires">
          <TimronImg src={weather} alt=""></TimronImg>
          <p>שירות מטאורלוגי</p>
        </SectionWrapper>
      </BigSectionWrapper>
    </>
  );
};
