
import { atom} from "recoil";
import styled from "styled-components";

import { LeftSide } from "./LeftSide";
import { RightSide } from "./RightSide";
import { Center } from "./Center";
import blueBg from "../../assets/blueBackgroung.jpeg";
import greyBg from "../../assets/waterSun.png";
import whiteBg from "../../assets/pure-white-background-85a2a7fd.jpg";
interface IProps {
  plus?: boolean;
  background?: string;
  updatesNumber?: number;
}
export const shouldShowSearch = atom({
  key: "shouldShowSearch", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const shouldShowUpdates = atom({
  key: "shouldShowUpdates", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const shouldShowContact = atom({
  key: "shouldShowContact", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const Header = ({ plus }: IProps) => {
  return (
    <>
      <HeaderWarper>
        <HeaderStyle background="blue">
          <RightSide />
          <Center />
          <LeftSide />
        </HeaderStyle>
        <GoldLine></GoldLine>
      </HeaderWarper>
    </>
  );
};

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9vh;
  background-image: ${(props: IProps) =>
    props.background === "blue"
      ? `url(${blueBg})`
      : props.background === "grey"
      ? `url(${greyBg})`
      : `url(${whiteBg})`};

`;
const GoldLine = styled.div`
  background-color: #eacda3;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 0.5vh;
`;
const HeaderWarper = styled.div`
  z-index: 10;
  top: 0;
  left: 0;
  max-width: 100vw;
  margin-bottom: 2.5vh;
`;
