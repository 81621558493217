import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CARDS } from "../../constants";
import { DefaultContainer } from "../../containers/DefaultContainer";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfo } from "../../store/userInfo";
import { Updates } from "./Updates";
import { Markup } from "interweave";
import { Popup } from "./Popup";
import { HomeModal } from "./HomeModal";
import {
  shouldShowContact,
  shouldShowSearch,
  shouldShowUpdates,
} from "../Header/Header";
import { SearchField } from "../Search/SearchField";
import { NewCard } from "../../atoms/NewCard/newCard";
import { MiddleShield } from "./MiddleShield";
import {
  closeContactNumber,
  shouldShowFavorites,
  updatesNumber,
} from "../BurgerMenu";
import { Favorites } from "./Updates copy";
import { Contact } from "./Contact";
import { IUpdate } from "../AdminsUpdates/Update";
import { ITicket } from "../Contact";
const CURRENT_APP_VERSION = "1.1.0";

export const Home = () => {
  const history = useHistory();
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;
  const [markup, setMarkup] = useState("");
  const isNewUser = useCallback(() => {
    setTimeout(() => {
      axios.get(`${BASE_URL}/auth/isNewUser`, {
        withCredentials: true,
      });
      axios.post(
        `${BASE_URL}/userLogs/sendUserLog`,
        { email },
        { withCredentials: true }
      );
    }, 1000);
  }, []);

  useEffect(() => {
    isNewUser();
  }, [isNewUser]);

  const [shouldShow, setShouldShow] = useState(false);
  const contact = useRecoilValue(shouldShowContact);
  const search = useRecoilValue(shouldShowSearch);
  const favorites = useRecoilValue(shouldShowFavorites);
  const setShouldShowFavorites = useSetRecoilState(shouldShowFavorites);
  const setShouldShowUpdates = useSetRecoilState(shouldShowUpdates);
  const setShouldShowContact = useSetRecoilState(shouldShowContact);
  const setRecoilUpdatesNumber = useSetRecoilState(updatesNumber);
  const setRecoilContactNumber = useSetRecoilState(closeContactNumber);

  const [realUpdates, setUpdates] = useState<IUpdate[]>([]);
  const [tickets, setTickets] = useState<ITicket[]>([]);

  useEffect(() => {
    const lastAppVersion = window.localStorage.getItem("lastAppVersion");

    if (lastAppVersion !== CURRENT_APP_VERSION) {
      window.localStorage.setItem("lastAppVersion", CURRENT_APP_VERSION);
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/adminUpdates/getUserUpdates`, {
        withCredentials: true,
      })
      .then((res) => {
        res?.data?.success ? setUpdates(res.data.updates) : setUpdates([]);
      });
    axios
      .post(
        `${BASE_URL}/contactTickets/getMyTickets`,
        { email: user.user.userPrincipalName },
        { withCredentials: true }
      )
      .then((res) => {
        if (res?.data?.success) {
          setTickets(res.data.tickets);
        }
      });
  }, []);

  setRecoilUpdatesNumber(realUpdates.length);
  useEffect(() => {
    const countClosedTickets = tickets.filter(
      (ticket) => ticket.status === "closed"
    ).length;
    setRecoilContactNumber(countClosedTickets);
  }, [tickets]);

  return (
    <DefaultContainer search={true} background="blue">
      <HomeWrapper>
        <HomeModal shouldShow={false} setShouldShow={setShouldShow}>
          {""}
        </HomeModal>

       <SearchField />
       <Updates setMarkup={setMarkup} />
       <Favorites />
        {contact && <Contact />}

        <CardsWrapper>
          <CardsColWrapper>
            <NewCard
              isActive={true}
              text={"דיווח אירוע בטיחות"}
              textSize={16}
              onClickFunc={() => {
                history.push("/safetyreports");
                setShouldShowFavorites(false);
                setShouldShowUpdates(false);
                setShouldShowContact(false);
              }}
            />
          </CardsColWrapper>
          <CardsColWrapper>
            <NewCard
              isActive={true}
              text={CARDS[39]}
              color="#e9dddd"
              bgColor="#fff0f0"
              textSize={16}
              onClickFunc={() => {
                history.push("/physiological");
                setShouldShowFavorites(false);
                setShouldShowUpdates(false);
                setShouldShowContact(false);
              }}
            />
          </CardsColWrapper>
        </CardsWrapper>
        <MiddleShield />
        <CardsWrapper>
          <CardsColWrapper>
            <NewCard
              marginBottom
              isActive={true}
              text={CARDS[32]}
              color="#181b1c"
              bgColor="#f5f5f5"
              textSize={16}
              onClickFunc={() => {
                history.push("/safetyliterature");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[3] },
                  {
                    withCredentials: true,
                  }
                );
                setShouldShowFavorites(false);
                setShouldShowUpdates(false);
                setShouldShowContact(false);
              }}
            />
            <CardsColWrapper>
              <NewCard
                marginBottom
                isActive={true}
                text={CARDS[33]}
                color="#141718"
                bgColor="#f5f5f5"
                textSize={16}
                onClickFunc={() => {
                  history.push("/professionalareas");
                  axios.post(
                    `${BASE_URL}/areaLogs/addAreaLog`,
                    { email, name: CARDS[33] },
                    {
                      withCredentials: true,
                    }
                  );
                  setShouldShowFavorites(false);
                  setShouldShowUpdates(false);
                  setShouldShowContact(false);
                }}
              />
            </CardsColWrapper>
            <CardsColWrapper>
              <NewCard
                isActive={true}
                text={CARDS[35]}
                color="#141718"
                bgColor="#f5f5f5"
                textSize={16}
                onClickFunc={() => {
                  history.push("/safetyschool");
                  axios.post(
                    `${BASE_URL}/areaLogs/addAreaLog`,
                    { email, name: CARDS[35] },
                    {
                      withCredentials: true,
                    }
                  );
                  setShouldShowFavorites(false);
                  setShouldShowUpdates(false);
                  setShouldShowContact(false);
                }}
              />
            </CardsColWrapper>
          </CardsColWrapper>
          <CardsColWrapper>
            <NewCard
              isActive={true}
              text={CARDS[36]}
              color="#141718"
              bgColor="#f5f5f5"
              textSize={16}
              onClickFunc={() => {
                history.push("/subjects");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[36] },
                  {
                    withCredentials: true,
                  }
                );
                setShouldShowFavorites(false);
                setShouldShowUpdates(false);
                setShouldShowContact(false);
              }}
            />
            <CardsColWrapper>
              <NewCard
                isActive={true}
                text={CARDS[37]}
                color="#000000"
                bgColor="#f5f5f5"
                textSize={16}
                onClickFunc={() => {
                  history.push("/videoscatalog");
                  axios.post(
                    `${BASE_URL}/areaLogs/addAreaLog`,
                    { email, name: CARDS[37] },
                    {
                      withCredentials: true,
                    }
                  );
                  setShouldShowFavorites(false);
                  setShouldShowUpdates(false);
                  setShouldShowContact(false);
                }}
              />
            </CardsColWrapper>
            <CardsColWrapper>
              <NewCard
                isActive={true}
                text={CARDS[38]}
                color="#000000"
                bgColor="#f5f5f5"
                textSize={16}
                onClickFunc={() => {
                  history.push("/researchcatalog");
                  axios.post(
                    `${BASE_URL}/areaLogs/addAreaLog`,
                    { email, name: CARDS[38] },
                    {
                      withCredentials: true,
                    }
                  );
                  setShouldShowFavorites(false);
                  setShouldShowUpdates(false);
                  setShouldShowContact(false);
                }}
              />
            </CardsColWrapper>
          </CardsColWrapper>
        </CardsWrapper>
        <ButtonsWrapper></ButtonsWrapper>
        {markup && (
          <Popup setMarkup={setMarkup}>
            <Markup content={markup} />
          </Popup>
        )}
      </HomeWrapper>
    </DefaultContainer>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  height: 20vh;
  justify-content: space-between;
`;

const HomeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CardsColWrapper = styled.div`
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;
  width: 45vw;
`;
