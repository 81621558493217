import styled from "styled-components";

export const Location = styled.div`
  width: 98vw;
  height: 16vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  .text {
    font-weight: 900;
  }
`;
export const SvgWrapper = styled.div`
  width: 25vw;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d1b1b;
  border-radius: 10px;
`;
export const SvgLocation = styled.svg`
  margin-bottom: 0.2rem;
  width: 5rem;
  height: 3rem;
`;
export const P = styled.p`
  height: 0.3vh;
`;
