import { BsXLg } from "react-icons/bs";
import {
  Button,
  ButtonWrap,
  ContactBottom,
  MainBottom,
  P,
  P0,
  Top,
  TopWrapper,
  UpdatesWrapper,
  Vector,
} from "./styles";
import Plus from "../../../assets/Svgs/plus 3.svg";
import { ContactPage } from "./ContactPage";
import { useEffect, useState } from "react";
import { ContactSupportTickets } from "../../Contact/ContactSupportTickets";
import { AiOutlineArrowRight } from "react-icons/ai";
import { ITicket } from "../../Contact";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Ticket } from "../../Contact/Ticket";
import styled from "styled-components";
import axios from "axios";
import { BASE_URL } from "../../../api/constants";
import { userInfo } from "../../../store/userInfo";
import { ViewTicket } from "./ViewTicket";
import { shouldShowContact } from "../../Header/Header";

export const Contact = () => {
  const user = useRecoilValue(userInfo);
  const setShouldShowContact = useSetRecoilState(shouldShowContact);

  const [mode, setMode] = useState("ראשי");
  const history = useHistory();
  const [ticketsOpen, setTicketsOpen] = useState<ITicket[]>([]);
  const [ticketsClosed, setTicketsClosed] = useState<ITicket[]>([]);
  const [viewTicket, setViewTicket] = useState(false);
  const [ticket, setTicket] = useState<ITicket>({} as ITicket);
  const [isLoading, setIsLoading] = useState(true);
  const [sliderInquiries, setSliderInquiries] = useState(false);
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [countCloseTickets, setCountCloseTickets] = useState(0);

  useEffect(() => {
    if (!viewTicket) {
      setIsLoading(true);
      axios
        .post(
          `${BASE_URL}/contactTickets/getMyTickets`,
          {
            email: user.user.userPrincipalName,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res?.data?.success) {
            setTickets(res.data.tickets);
            setIsLoading(false);
          }
        });
      axios
        .get(`${BASE_URL}/contactTickets/getClosedTickets`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res?.data?.success) {
            setTicketsClosed(res.data.tickets);
            console.log(res.data.tickets);
            setIsLoading(false);
          }
        });
    }
  }, [viewTicket]);

  return (
    <UpdatesWrapper>
      <Top>
        {/* <TopWrapper> */}
        <BsXLg
          onClick={() => {
            setShouldShowContact(false);
          }}
        />

        <P>
          <Vector />
          <P0>הפניות שלי</P0>

          <Vector />
        </P>
        {/* </TopWrapper> */}
      </Top>
      {viewTicket && (
        <ViewTicket
          isSupportUser={false}
          setViewTicket={setViewTicket}
          ticket={ticket}
        />
      )}
      {isLoading && <ContactBottom>טוען פניות...</ContactBottom>}
      {mode !== "פנייה" && !isLoading && (
        <TicketsWrapper>
          {tickets.length
            ? tickets.map((ticket) => (
                <Ticket
                  key={ticket._id}
                  isSupportUser={true}
                  ticket={ticket}
                  setViewTicket={setViewTicket}
                  setTicket={setTicket}
                />
              ))
            : ""}
        </TicketsWrapper>
      )}

      {mode === "ראשי" && !viewTicket && !isLoading ? (
        <ButtonWrap border={tickets.length > 0}>
          <Button onClick={() => setMode("פנייה")}>
            {" "}
            <img src={Plus} />
            <P0> פניה חדשה</P0>
          </Button>
        </ButtonWrap>
      ) : !viewTicket && !isLoading ? (
        <ContactPage setMode={setMode} />
      ) : (
        ""
      )}

      {!tickets.length && !viewTicket && !isLoading && mode !== "פנייה" ? (
        <MainBottom>
          <P>
            <Vector />
            <P0> אין פניות פתוחות</P0>
            <Vector />
          </P>
        </MainBottom>
      ) : (
        ""
      )}
    </UpdatesWrapper>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-top: 1rem;
  #or {
    color: black;
    /* font-size: 1.2rem; */
    font-weight: bold;
  }
`;

const Titles = styled.h3<{ sliderInquiries: boolean }>`
  margin: 0;
  &&#titleOpen {
    border-bottom: ${(props) =>
      props.sliderInquiries ? "1px solid white" : "1px solid red"};
    color: ${(props) => (props.sliderInquiries ? "#8c8c8c" : "black")};
    transition: all 0.4s ease-out;
    margin-left: 0.5rem;
  }
  &&#titleClose {
    border-bottom: ${(props) =>
      props.sliderInquiries ? "1px solid red" : "1px solid white"};
    color: ${(props) => (props.sliderInquiries ? "black" : "#8c8c8c")};
    transition: all 0.4s ease-out;
    margin-right: 0.5rem;
  }
`;

const TicketsWrapper = styled.div`
  background-color: #cdeaff;

  max-height: 60vh;
  width: 80vw;
  width: inherit;
  margin: 0;

  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: white;
  border-radius: 0.5rem;
  width: 90vw;
  margin-right: 5vw;
  /* max-height: 95vh; */
  padding-bottom: 1rem;
  margin-top: 2.8rem;
  .back {
    position: absolute;
    top: 1.3rem;
    right: 2rem;
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 1px; */
  }
  /* button {
    position: absolute;
    top: 1rem;
    right: 10rem;
  } */
`;
