import styled from "styled-components";

export const Selectt = styled.select`
  width: 87vw;
  height: 5vh;
  appearance: none;
  outline: none;
  margin-right: 5vw;
  padding-right: 5px;
  color: white;
  /* border: none; */
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  border: 1px solid #a39e9e;
  -ms-word-break: normal;
  word-break: normal;
  display: inline-block;
  font: inherit;

  background-image: linear-gradient(45deg, transparent 50%, #545252 50%),
    linear-gradient(135deg, #545252 50%, transparent 50%);
  /* linear-gradient(to right, white, white); */
  background-position: calc(10% - 20px) calc(0.9em + 2px),
    calc(10% - 15px) calc(0.9em + 2px), 10% 0;
  background-size: 5px 5px, 5px 7px, 5em 3em;
  background-repeat: no-repeat;
  :focus {
    background-image: linear-gradient(45deg, #545252 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #545252 50%);
    // linear-gradient(to right, white, white);
    background-position: calc(10% - 15px) 0.9em, calc(10% - 20px) 0.9em, 0% 0;
    background-size: 4px 6px, 5px 6px, 2.5em 4em;

    background-repeat: no-repeat;
  }
`;
