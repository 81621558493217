import Shield from "../../../assets/homepage/logo.png";
import { BigWrapper, Img, ImgWrapper, Wrapper } from "./styles";
import greyLine from "../../../assets/Svgs/greyLine.svg";
export const MiddleShield = () => {
  return (
    <BigWrapper>
      <Wrapper>
        <img src={greyLine} alt="" />
        <ImgWrapper>
          {" "}
          <Img src={Shield} alt=""></Img>
        </ImgWrapper>
        <img src={greyLine} alt="" />
      </Wrapper>
    </BigWrapper>
  );
};
