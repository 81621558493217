import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { BASE_URL } from "../../api/constants";
import { Modal } from "../../atoms/Modal";
import usePermissions from "../../hooks/usePermissions";
import { useHistory } from "react-router";
import NewUpdate from "./NewUpdate";
import { Button } from "../Button";
import { Update, IUpdate } from "./Update";
import { ViewUpdate } from "./Update/ViewUpdate";

export const AdminUpdates = () => {
  const history = useHistory();
  const { isAdminUser } = usePermissions();
  const [newUpdate, setNewUpdate] = useState(false);
  const [updates, setUpdates] = useState<IUpdate[]>([]);
  const [viewUpdate, setViewUpdate] = useState(false);
  const [update, setUpdate] = useState<IUpdate>({} as IUpdate);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAdminUser && !viewUpdate && !newUpdate) {
      setIsLoading(true);
      axios
        .get(`${BASE_URL}/adminUpdates/getUpdates`, { withCredentials: true })
        .then((res) => {
          if (res?.data?.success) {
            setUpdates(res.data.updates);
            setIsLoading(false);
          }
        });
    } else if (isAdminUser === false) {
      history.push("/");
    }
  }, [isAdminUser, viewUpdate, newUpdate]);

  return (
    <DefaultContainer background="blue">
      {isAdminUser && (
        <Wrapper>
          <Button onClick={() => setNewUpdate(true)}>הודעה חדשה</Button>
          <Modal setShowModal={setNewUpdate} showModal={newUpdate}>
            <NewUpdate />
          </Modal>

          <Modal setShowModal={setViewUpdate} showModal={viewUpdate}>
            <ViewUpdate update={update} setViewUpdate={setViewUpdate} />
          </Modal>
          {isLoading ? (
            <p>{`טוען..`}</p>
          ) : (
            <>
              <Title>הודעות קיימות</Title>
              <UpdatesWrapper>
                {updates.length ? (
                  updates.map((update) => (
                    <Update
                      key={update._id}
                      update={update}
                      setViewUpdate={setViewUpdate}
                      setUpdate={setUpdate}
                    />
                  ))
                ) : (
                  <p>{`אין הודעות פתוחות`}</p>
                )}
              </UpdatesWrapper>
            </>
          )}
        </Wrapper>
      )}
    </DefaultContainer>
  );
};

export default AdminUpdates;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.h4`
  color: white;
`;

const UpdatesWrapper = styled.div`
  max-height: 50vh;
  width: 80%;
  margin: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
`;
