import styled from "styled-components";
import { CARDS } from "../../constants";
import physioligical from "../../assets/Svgs/physioligical.svg";
import war from "../../assets/Svgs/Group 4.svg";
import guns from "../../assets/Svgs/נהלי בטיחות copy 6.svg";
import Icon1 from "../../assets/Svgs/טבלא copy 2.svg";
import Icon2 from "../../assets/Svgs/נהלי בטיחות copy 2.svg";
import Icon3 from "../../assets/Svgs/פנקסי עזר למפקד copy 1.svg";
import Icon4 from "../../assets/Svgs/כרכי בטיחות copy 2.svg";
import Icon5 from "../../assets/Svgs/מנחי מבקא copy 1.svg";
import Icon6 from "../../assets/Svgs/תחקיר הבטיחות 1.svg";
import Icon7 from "../../assets/Svgs/ניהול הידע בבטיחות 1.svg";
import Icon8 from "../../assets/Svgs/חופשה 1.svg";
import Icon11 from "../../assets/Svgs/דגשים לתחום האש 1.svg";
import Icon14 from "../../assets/Svgs/לוחם copy 1.svg";
import Icon15 from "../../assets/Svgs/מחקר 1.svg";
import Icon18 from "../../assets/Svgs/Redesign/New Icons 018-06.svg";
import Icon21 from "../../assets/Svgs/Redesign/New Icons 021-06.svg";
import Icon22 from "../../assets/Svgs/Redesign/New Icons 022-06.svg";
import Icon24 from "../../assets/Svgs/Redesign/New Icons 024-06.svg";
import Icon25 from "../../assets/Svgs/Isolation_Mode.svg";
import Icon26 from "../../assets/Svgs/איש שחפץ צבאי 1.svg";
import Icon27 from "../../assets/Svgs/טנק_2 1 (1).svg";
import Icon28 from "../../assets/Svgs/טרקטור 1.svg";
import Icon29 from "../../assets/Svgs/טנק 1.svg";
import Icon30 from "../../assets/Svgs/Isolation_Mode (1).svg";
import Icon31 from "../../assets/Svgs/מכונית לבנה 1.svg";
import Icon32 from "../../assets/Svgs/חבית 1.svg";
import Icon33 from "../../assets/Svgs/אש 1.svg";
import fire from "../../assets/Svgs/אש 2.svg";
import safeInRoads from "../../assets/Svgs/בטיחות בדרכים 1.svg";
import Homas from "../../assets/Svgs/חבית 1.svg";
import Icon34 from "../../assets/Svgs/פטיש 1.svg";
import Icon35 from "../../assets/Svgs/אקדח 1.svg";
import Icon36 from "../../assets/Svgs/ניוד מבצע 1.svg";
import Icon37 from "../../assets/Svgs/אזעקה 1.svg";
import pdfText from "../../assets/Svgs/iwwa_file-pdf.svg";
import mp4Text from "../../assets/Svgs/Vector.svg";
import Icon38 from "../../assets/Svgs/לוחם_2 copy 1.svg";
import Books from "../../assets/Svgs/Redesign/Books.svg";
import graf from "../../assets/Svgs/גרף.svg";
import ezer from "../../assets/Svgs/חומרי עזר (1).svg";
import courses from "../../assets/Svgs/מצגות לקורסים.svg";
import safetyweek from "../../assets/Svgs/תו איכות ובטיחות 1.svg";
import mavraka from "../../assets/Svgs/158 1.svg";
import safetyrules from "../../assets/Svgs/נהלי בטיחות copy 2 (1).svg";
import mavkarules from "../../assets/Svgs/מנחי מבקא copy 1 (1).svg";
import { useEffect, useState } from "react";
interface IProps {
  text: string;
  url?: string;
  color: string;
  bgColor: string;
  isActive: boolean;
  onClickFunc?: () => void;
  textSize?: Number;
  isIcon: boolean;
}

interface cardProps {
  color: string;
  isActive: boolean;
  textSize?: string;
  height?: string;
}

const logos: any = {
  [CARDS[0]]: Icon1,
  [CARDS[1]]: Icon2,
  [CARDS[2]]: Icon3,
  [CARDS[3]]: Icon4,
  [CARDS[4]]: Icon5,
  [CARDS[5]]: Icon6,
  [CARDS[6]]: Icon7,
  [CARDS[7]]: Icon8,
  [CARDS[8]]: safeInRoads,
  [CARDS[9]]: fire,
  [CARDS[10]]: Icon11,
  [CARDS[11]]: Icon27,
  [CARDS[12]]: Homas,
  [CARDS[13]]: Icon14,
  [CARDS[14]]: Icon15,
  [CARDS[15]]: Icon21,
  [CARDS[16]]: Icon22,
  [CARDS[17]]: Icon24,
  [CARDS[18]]: Icon25,
  [CARDS[19]]: Icon26,
  [CARDS[20]]: Icon27,
  [CARDS[21]]: Icon28,
  [CARDS[22]]: Icon29,
  [CARDS[23]]: Icon30,
  [CARDS[24]]: Icon31,
  [CARDS[25]]: Icon32,
  [CARDS[26]]: Icon33,
  [CARDS[27]]: Icon34,
  [CARDS[28]]: Icon35,
  [CARDS[29]]: Icon36,
  [CARDS[30]]: Icon37,
  [CARDS[31]]: Icon38,
  [CARDS[32]]: Books,
  [CARDS[34]]: Icon18,
  [CARDS[39]]: physioligical,
  [CARDS[41]]: war,
  [CARDS[42]]: guns,
  [CARDS[43]]: graf,
  [CARDS[44]]: ezer,
  [CARDS[45]]: courses,
  [CARDS[46]]: mavraka,
  [CARDS[47]]: safetyweek,
  [CARDS[48]]: Icon27,
  [CARDS[49]]: guns,
  [CARDS[50]]: safetyrules,
  [CARDS[51]]: fire,
  [CARDS[52]]: Icon24,
  [CARDS[53]]: mavkarules,
};
export const FolderCard = ({
  text,
  color,
  bgColor,
  isActive,
  onClickFunc,
  isIcon,
}: IProps) => {
  let logo = text;
  const fileType = text.split(".").slice(-1)[0];
  // console.log(fileType);
  const realText = text.slice(0, -4);
  if (fileType === "pdf" || "mp4") {
    logo = fileType;
  }
  const [height, setHeight] = useState("");
  const [TextSize, setTextSize] = useState("17px");
  const isTextInArray = CARDS.some(
    (logo: string) => logo.toLowerCase().includes(text) && text !== "דרכים"
  );
  useEffect(() => {
    if (text.length > 20) {
      setTextSize("14px");
    }
  }, []);
  useEffect(() => {
    if (text.length < 30) {
      setHeight("11vh");
    } else if (text.length < 40) {
      setHeight("13vh");
    } else if (text.length < 50) {
      setHeight("15vh");
    } else if (text.length < 80) {
      setHeight("22vh");
    } else setHeight("25vh");
    // if(text == "דרכים"){setistext}
  }, [text]);
  return (
    <>
      {isTextInArray && isIcon ? (
        <Wrapper
          onClick={() => {
            isActive && onClickFunc && onClickFunc();
          }}
        >
          <CardWrapper
            onClick={() => {
              isActive && onClickFunc && onClickFunc();
            }}
            color={color}
            isActive={isActive}
            className={isActive ? "" : "active"}
            height={height}
          >
            {" "}
          </CardWrapper>
          <ImgWrapper
            onClick={() => {
              isActive && onClickFunc && onClickFunc();
            }}
            color={bgColor}
            isActive={isActive}
          >
            {logos[logo] && <Img src={logos[logo]} alt="logo" />}
          </ImgWrapper>
          <Text isActive color="" textSize={TextSize}>
            {text}
          </Text>{" "}
        </Wrapper>
      ) : fileType === "pdf" ? (
        <PdfWrapper
          onClick={() => {
            isActive && onClickFunc && onClickFunc();
          }}
        >
          <PdfImg src={pdfText}></PdfImg>
          <PdfText> {realText}</PdfText>
        </PdfWrapper>
      ) : fileType === "mp4" ? (
        <MP4Wrapper
          onClick={() => {
            isActive && onClickFunc && onClickFunc();
          }}
        >
          <Mp4Img src={mp4Text}></Mp4Img>
          <MP4Text> {realText}</MP4Text>
        </MP4Wrapper>
      ) : (
        <FolderWrapper
          onClick={() => {
            isActive && onClickFunc && onClickFunc();
          }}
        >
          <FolderRight>
            <RightSideLine></RightSideLine>
            <Tag>{"<"}</Tag>
          </FolderRight>

          <FolderText> {text}</FolderText>
          <Folderleft></Folderleft>
        </FolderWrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  /* border-radius: 11px; */
  width: 40vw;
  height: 15vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2vh;
  margin-left: 3vw;
  margin-right: 5.5vw;
`;

const CardWrapper = styled.div<cardProps>`
  border-radius: 11px;
  min-width: 40vw;
  height: 15vh;

  background-color: #ffffff;
  position: absolute;
  mix-blend-mode: soft-light;
  /* background-filter: grayscale(80%); */
  display: flex;
  align-items: right;
  justify-content: center;
  flex-direction: column;
`;
export const ImgWrapper = styled.div<cardProps>`
  display: flex;
  position: absolute;

  justify-content: center;
  @media (max-width: 768px) {
    margin-top: 1vh;
  }
`;

const Text = styled.p<cardProps>`
  width: 40vw;
  display: flex;
  text-align: center;
  font-size: ${(props) => props.textSize};
  height: 7vh;
  justify-content: center;
  align-items: center;
  margin-top: 11vh;
  color: #ffffff;
`;
const Img = styled.img`
  /* width: 6rem; */
  height: 4.5rem;
`;
const FolderWrapper = styled.div`
  width: 45vw;
  display: flex;
  align-items: center;

  flex-direction: row;
  height: 7vh;
  border-radius: 0.5rem;
  color: white;
  background-color: #6c95af;
  margin-bottom: 3vh;
  margin-left: 2vw;
  margin-right: 2.5vw;
`;
const FolderRight = styled.div`
  width: 13vw;
  height: 7vh;
  display: flex;
  align-items: center;
  background: #c00;
  /* fallback */

  background: -webkit-linear-gradient(15deg, transparent 10px, #ffffff 10px),
    -webkit-linear-gradient(135deg, transparent 0px, #ffffff 0px),
    -webkit-linear-gradient(225deg, transparent 0px, #ffffff 0px),
    -webkit-linear-gradient(345deg, transparent 10px, #ffffff 10px);
  background-position: bottom left, bottom right, top right, top left;
  /* -moz-background-size: 50% 50%; */
  /* -webkit-background-size: 50% 50%; */
  background-size: 51% 51%;
  background-repeat: no-repeat;
  flex-direction: row;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: white;
  background-color: #6c95af;
`;
const Folderleft = styled.div`
  width: 0.5vw;
  height: 5vh;
  color: white;
  background-color: white;
`;
const RightSideLine = styled.div`
  width: 0.5vw;
  height: 5vh;
  margin-left: 0.5vh;
  margin-right: 1vh;
  background-color: #6c95af;
`;
const FolderText = styled.b`
  width: 30vw;
  height: 5vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: small;
  color: white;
`;
const Tag = styled.div`
  font-size: 50px;
  color: #6c95af;
`;

const PdfWrapper = styled.div`
  width: 100vw;
  height: 5vh;
  background-color: #ead1bf;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin-bottom: 1vh;
`;
const MP4Wrapper = styled.div`
  width: 100vw;
  height: 5vh;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin-bottom: 1vh;
`;
const PdfText = styled.p`
  width: 90vw;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: right;
  color: #000000;
`;
const MP4Text = styled.p`
  width: 90vw;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: right;
  color: #000000;
`;
const PdfImg = styled.img`
  /* width: 1rem;
  height: 1rem; */
`;
const Mp4Img = styled.img`
  margin-left: 1vh;
  margin-right: 1vh;
`;
