import React, { useEffect, useState } from "react";

import { ReactComponent as QuestionMark } from "../../assets/Svgs/questionMark.svg";
import {
  ModalBackground,
  ModalBody,
  ToolTipContainer,
  Wrapper,
} from "./styles";

interface IProps {
  children: React.ReactNode;
  text: string;
  top: number;
  right: number;
  setShouldShow: React.Dispatch<React.SetStateAction<boolean>>;
  setToolTipMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const NewToolTip = ({
  text,
  children,
  top,
  right,
  setShouldShow,
  setToolTipMessage,
}: IProps) => {
  return (
    <Wrapper>
      <ToolTipContainer top={top} right={right}>
        <div className="messageContainer">
          <div className="messageContainer2">
            {text ? (
              <QuestionMark
                className="svg"
                onClick={() => {
                  setToolTipMessage(text);
                  setShouldShow(true);
                }}
              />
            ) : null}
            {children}
          </div>
        </div>
      </ToolTipContainer>
    </Wrapper>
  );
};
