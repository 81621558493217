import styled from "styled-components";

export const Switch = styled.div`
  /* font-family: "Lucida Grande", Tahoma, Verdana, sans-serif; */
  position: relative;
  height: fit-content;
  width: fit-content;
  background-color: #e4e4e4;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 2rem;
`;

export const SwitchRadio = styled.input`
  display: none;
`;
interface IProps {
  width?: string;
  fontsize?: string;
}

export const SwitchSelection = styled.span<IProps>`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: ${(props) => (props.width ? props.width : "6rem")};
  /* width: 6rem; */
  height: 26px;
  background: #216ba5;
  border-radius: 3px;
  transition: left 0.25s ease-out;
`;

export const SwitchLabel = styled.label<IProps>`
  position: relative;
  z-index: 2;
  float: left;
  width: ${(props) => (props.width ? props.width : "6rem")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "1rem")};
  /* width: 6rem; */
  line-height: 26px;
  /* font-size: 1rem; */
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  cursor: pointer;

  ${SwitchRadio}:checked + & {
    transition: 0.15s ease-out;
    color: #fff;
  }
`;
