export const CARDS = [
  "טבלאות",
  "נהלי בטיחות",
  "פנקסי עזר למפקד",
  "כרכי בטיחות",
  'מנחי מבק"א',
  "תחקיר הבטיחות",
  "ניהול הידע בבטיחות",
  "בטיחות בחופשה",
  "בטיחות בדרכים",
  "בטיחות באש",
  "בטיחות בעבודה",
  "בטיחות באימונים והכשרות",
  'בטיחות בחומ"ס',
  'בטיחות בפע"ם',
  "מחקר",
  "גרף",
  "חומרי ",
  "מצגות לקורסים",
  `כרך א' - שגרה ואימונים`,
  `כרך ב' - חי"ר`,
  `כרך ג' - חש"ן`,
  `כרך ד' - חה"ן`,
  `כרך ה' - חת"ם`,
  `כרך ו' - פעילות מבצעית`,
  `כרך ז' - דרכים`,
  `כרך ח' - חומ"ס`,
  `כרך ט' - אש`,
  `כרך י' - עבודה`,
  `כרך ל' - לוט"ר`,
  `כרך נ' - ניוד מבצעי`,
  `כרך ע' - פיקוד העורף`,
  `ירי כוחותינו על כוחותינו (דו"צ)`,
  "ספרות הבטיחות",
  "תחומים מקצועיים",
  "מערכות בטיחות",
  'ביה"ס לבטיחות',
  "נושאים במיקוד",
  "קטלוג הסרטונים",
  "קטלוג התחקירים",
  "מחשבון פיזיולוגי",
  "דיווח אירוע בטיחות",
  "חרבות ברזל",
  "עבריינות בנשק",
  "גרף",
  "חומרי עזר",
  "מצגות לקורסים",
  `מברק"א`,
  `שבוע האיכות והבטיחות`,
  `דגשים לבטיחות 
  בדרכים ורכב או רק”ם`,
  `דגשים לבטיחות ובטחון 
  כוחותינו בלחימה`,
  `דגשים לנשק אישי 
  ומניעת דו”צים`,
  `דגשים לאש, חשמל
  ולחומרים מסוכנים`,
  `סרטונים
  איך אנחנו נראים`,
  `תחקירים
  מבצעיים`,
  ``,
];
export const SMART_BASE_URL = "https://www.base.idf.il/";
export const ACADEMY_COURSES_URL =
  "https://academy.digital.idf.il/courses?categories=81";
export const Fires = "https://ims.gov.il/he/Fires";
