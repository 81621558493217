import { useState } from "react";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import { ContactMyTickets } from "./ContactMyTickets";
import { NewTicket } from "./NewTicket";
export const ContactUser = () => {
  const user = useRecoilValue(userInfo);
  const [newTicket, setNewTicket] = useState(false);

  return (
    <div>
      {newTicket ? (
        <NewTicket setNewTicket={setNewTicket} />
      ) : (
        <ContactMyTickets setNewTicket={setNewTicket} />
      )}
    </div>
  );
};
