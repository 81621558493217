import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { TreeNode, TreeNodeType } from "../TreeNode";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { BASE_URL } from "../../api/constants";
import { Plus } from "../../assets/Svgs/Plus";
import { Modal } from "../../atoms/Modal";
import { UploadModal } from "../UploadModal";
import usePermissions from "../../hooks/usePermissions";
import { FileView } from "../FileView";
import { VideoView } from "../VideoView";
import { useFile } from "../../hooks/useFile";
import { AiModal } from "../File/FilePopup/AiModal";
import { useRecoilValue } from "recoil";
import { aiState, shouldShowState } from "../sumPdf";
import { PlusButton } from "../SafetyLiterature/styles";
import { FileViewWrapper, FilesWrapper } from "./styles";
import { NewFolder } from "../SafetyLiterature";
import { shouldShowSearch } from "../Header/Header";

interface IProps {
  rootId: string;
  name: string;
}

export const NewFiles = ({ rootId, name }: IProps) => {
  const [nodes, setNodes] = useState<TreeNodeType[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isMp4, setIsMp4] = useState<boolean>(false);
  const buttonPosition = useRecoilValue(shouldShowSearch);
  const [isCatalog, setIsCatalog] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isThereNodes, setIsThereNodes] = useState(true);
  const [isFilmCatalog, setisFilmCatalog] = useState(false);

  const aiAnswer = useRecoilValue(aiState);
  const shouldShow = useRecoilValue(shouldShowState);

  const [selectedFolder, setSelectedFolder] = useState<
    // TreeNodeType |
    (TreeNodeType & { onClickFunction: Function }) | undefined
  >(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { isContentManagerUser, isAdminUser } = usePermissions();
  const [file, setFile] = useState<string>("");
  useEffect(() => {
    console.log(file);
  }, [file]);
  const { treeFile, resetFile } = useFile();

  useEffect(() => {
    if (treeFile) {
      resetFile();
    }
    axios
      .post(`${BASE_URL}/tree/bigFolder`, { rootId }, { withCredentials: true })
      .then((res) => {
        setNodes(res.data);
        // splitStringArrayEvenOdd(res.data);
      });
  }, []);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);
  useEffect(() => {
    console.log(nodes);
  }, [nodes]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  useEffect(() => {
    if (treeFile.fileType?.toLowerCase() === "mp4") {
      setIsMp4(true);
    } else setIsMp4(false);
  }, [treeFile]);

  return (
    <div>
      <DefaultContainer overflow="scroll" plus={true} background="blue">
        {selectedFolder && (
          <>
            {isContentManagerUser && (
              <div>
                <PlusButton
                  buttonPosition={buttonPosition}
                  onClick={() => setIsOpen(true)}
                >
                  <Plus size={26} />
                </PlusButton>
                {/* <Modal showModal={isOpen} setShowModal={setIsOpen}> */}
                <UploadModal
                  isThereNodes={isThereNodes}
                  setIsThereNodes={setIsThereNodes}
                  nodes={nodes}
                  setNodes={setNodes}
                  selectedFolder={selectedFolder}
                  setShowModal={setIsOpen}
                  showModal={isOpen}
                  isCatalog={isCatalog}
                  isFilmCatalog={isFilmCatalog}
                />
                {/* </Modal> */}
              </div>
            )}
          </>
        )}

        {treeFile.fileSrc ? (
          <FileViewWrapper mp4={isMp4}>
            {treeFile.fileType?.toLowerCase() === "mp4" ? (
              <VideoView type={"video/mp4"} source={treeFile.fileSrc} />
            ) : (
              <FileView
                setRefresh={setRefresh}
                setShowModal={setShowModal}
                setFile={setFile}
                file={file}
              />
            )}
          </FileViewWrapper>
        ) : (
          ""
        )}

        <NewFolder
          isThereNodes={isThereNodes}
          setIsThereNodes={setIsThereNodes}
          setNodes={setNodes}
          nodes={nodes}
          setFile={setFile}
          setSelectedFolder={setSelectedFolder}
          rootId={rootId}
          name={name}
          setIsCatalog={setIsCatalog}
          setShowModal={setShowModal}
          showModal={showModal}
          isFilmCatalog={isFilmCatalog}
          setisFilmCatalog={setisFilmCatalog}
        />

        <AiModal shouldShow={shouldShow}>{aiAnswer}</AiModal>
      </DefaultContainer>
    </div>
  );
};
