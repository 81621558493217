import styled, { keyframes } from "styled-components";

export const InputWrapper = styled.div`
  color: #000000;
  background-color: #e6ebef;
  width: 100%;

  /* height: 5vh; */
`;
export const TextWrapper = styled.div`
  width: 100%;

  /* height: 5vh; */
`;
export const NameInput = styled.input`
  width: 90%;
  background-color: #e6ebef;
  height: 5vh;
  font-size: 16px;
  border: none;
  outline: none;
  color: #000000;
`;
export const TextInput = styled.input`
  width: 96%;
  height: 10vh;
  border: none;
  outline: none;
  font-family: "Assistant";
  font-size: 16px;
  color: #000000;
  /* margin-bottom: 2vh; */
`;
export const Gap = styled.div`
  width: 100%;
  height: 7vh;
  background-color: #cdeaff;
`;
const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }

`;
const ellipsis3 = keyframes` 
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsis2 = keyframes` 
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

export const DivInmationLoading = styled.div`
  margin-top: 1vh;
  &&.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 2.3rem;
  }
  &&.lds-ellipsis div {
    position: absolute;
    top: 0rem;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #8a8989;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  &&.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ${ellipsis1} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;

export const Message = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
`;
