import styled from "styled-components";

export const FileViewWrapper = styled.div<{ mp4?: boolean }>`
  position: fixed;
  display: flex;
  background-color: white;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  z-index: 100;
  margin-top: -12vh !important;
`;
export const FilesWrapper = styled.div<{ file?: string }>`
  display: ${(props) => (props?.file ? "none" : "")};
`;
