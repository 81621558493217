import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CalcInput } from "../../../CalcInput";
import { ImageSelect } from "../../../ImageSelect";
import { ReactComponent as Temp1 } from "../../../../assets/Svgs/Physiological/Calculator/Temp1.svg";
import { ReactComponent as Temp2 } from "../../../../assets/Svgs/Physiological/Calculator/Temp2.svg";
import { ReactComponent as Temp3 } from "../../../../assets/Svgs/Physiological/Calculator/Temp3.svg";
import { ReactComponent as Temp4 } from "../../../../assets/Svgs/Physiological/Calculator/Temp4.svg";
import { ReactComponent as Sort } from "../../../../assets/Svgs/Physiological/Calculator/sort.svg";
import { ReactComponent as Exercise } from "../../../../assets/Svgs/Physiological/Calculator/exercise.svg";
import { ReactComponent as Effort1 } from "../../../../assets/Svgs/Physiological/Calculator/running.svg";
import { ReactComponent as Effort2 } from "../../../../assets/Svgs/Physiological/Calculator/manInHike.svg";
import { ReactComponent as Effort3 } from "../../../../assets/Svgs/Physiological/Calculator/shoot.svg";
import { ReactComponent as Effort4 } from "../../../../assets/Svgs/Physiological/Calculator/education.svg";
import { ReactComponent as effortExtremlyHard } from "../../../../assets/Svgs/Physiological/Calculator/timeManagement.svg";
import { ReactComponent as Exclamation } from "../../../../assets/Svgs/Physiological/Calculator/Exclamation.svg";
import { ReactComponent as KestrelHeat } from "../../../../assets/Svgs/Physiological/Calculator/KestrelHeat.svg";
import page10 from "../../../../assets/hotCalcImage.jpg";

import { Option } from "../../../ImageOption";
import { between } from "../utils/validation";
import { CgCloseO } from "react-icons/cg";
import { BiCheckCircle } from "react-icons/bi";
import { fixNumber } from "../utils/fixValue";
import { ToggleSwitch } from "../../../Switch";
import { CheckBox } from "../../../CheckBox";
import { CalcProps } from "../..";
import { NewToolTip } from "../../../NewToolTip";
import { log } from "console";
import {
  Abutton,
  CheckBoxWrapper,
  ImageWrapperrrrr,
  ModalBackground,
  ModalBody,
  Text,
  TitleP,
  X,
  YesandNo,
} from "./styles";
import { HeatCheckBox } from "../../../heatCheckBox";
import { HeatCheckBoxNo } from "../../../heatCheckBoxNo";
import { BottomDiv, ImageWrapper, StyledButtonA } from "../LiquidCalc/styles";
import { ImageSvgWrapper } from "../../../SafetyReports/styles";
import { ConditionModaL } from "./condition image";
import { constSelector } from "recoil";
import { HeatCalcToolTip } from "./HeatCalcToolTip";

interface SpecialProps {
  shouldShowSpecial: boolean;
  setShouldShowSpecial: React.Dispatch<React.SetStateAction<boolean>>;
}

const Choose = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #b7c7d6;
  border-radius: 1rem;
  padding-top: 1rem;
  width: 95%;
`;

const Calc = styled.div`
  margin-top: -4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  max-width: 100vw;
  .seperator {
    width: 95%;
    height: 1px;
    margin: 1rem 0;
    background-color: #b7c7d6;
  }
  h5 {
    padding: 0;
    font-size: 16px;
    margin: 0;
    margin-bottom: 2rem;
    /* justify-self: center; */
    text-align: center;
    color: #2e3841;
  }
  .error {
    width: 80vw;
    color: red;
    font-size: medium;
  }
`;

const StyledButton = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 5rem;
  background-color: #3195ed;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: large;
`;

const YanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  svg {
    position: absolute;
    top: 30%;
    left: 10%;
    justify-self: right;
    align-self: right;
    width: 5rem;
    height: 2.8rem;
  }
`;
interface ResultsProps {
  wrapperColor: string;
}

const ResultsWrapper = styled.div<ResultsProps>`
  border: 0.3rem solid;
  border-color: ${(props) => props.wrapperColor};
  padding-bottom: 1rem;
  padding-right: 0.5rem;
`;

const Results = styled.div`
  width: 90%;
  margin-right: 5%;
  display: flex;
  /* justify-content: center; */

  /* align-items: center; */
  flex-direction: column;
  margin-top: -2rem;
  h4 {
    align-self: center;
  }

  .asd {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
    }
  }
  .note {
    margin: 0;
  }
`;

export const HeatCalc = ({ setShouldShow, setToolTipMessage }: CalcProps) => {
  const values = ["לחות וטמפ'", "עומס חום", `יא"נ`];

  const [relativeHumidity, setRelativeHumidity] = useState("");
  const [temp, setTemp] = useState("");

  const [uncomfterableUnits, setUncomfterableUnits] = useState("");
  const [isUncomfterableUnits23, setIsUncomfterableUnits23] = useState(false);

  const [heatLevel, setHeatLevel] = useState("");
  const [yanLevel, setYanLevel] = useState("");
  const [wrapperColor, setWrapperColor] = useState("");

  const [activity, setActivity] = useState("אימון");
  const [effort, setEffort] = useState("ללא מאמץ (0)");
  const [NospecialPopulation, setNoSpecialPopulation] = useState(false);
  const [YesspecialPopulation, setYesSpecialPopulation] = useState(false);
  const [shouldShowSpecial, setShouldShowSpecial] = useState(false);
  const [openImage, setOpenImage] = useState(false);

  const [error, setError] = useState("");
  const [isTakingPlace, setIsTakingPlace] = useState("");
  const [limitations, setLimitations] = useState("");
  const [icon, setIcon] = useState("");
  const [notes, setNotes] = useState(
    "אם במהלך האימון התפתח עומס חום בינוני, חלה על המפקד החובה להטיל הגבלות על האימון ולוודא כי היחידות קיבלו את המידע"
  );
  const [notesYan, setnotesYan] = useState(
    `בעומס חום מעל 23.0 יא"ן, על המפקד לוודא מדידה של עומס החום בטרם ובמהלך האימונים כל 30 דקות`
  );
  const [liquidsReturn, setLiquidReturn] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [selected, setSelected] = useState(`יא"נ`);
  const [isResults, setIsResults] = useState(false);

  useEffect(() => {
    if (activity == "מיון פיזי") {
      setNoSpecialPopulation(true);
    }
  }, [activity]);

  useEffect(() => {
    if (NospecialPopulation) {
      setYesSpecialPopulation(false);
    }
  }, [NospecialPopulation, YesspecialPopulation]);

  const clearForm = () => {
    setIsResults(false);
    setTemp("");
    setRelativeHumidity("");
    setUncomfterableUnits("");
    setHeatLevel("");
    setActivity("אימון");
    setEffort("ללא מאמץ (0)");
    setNoSpecialPopulation(false);
    setShouldShowSpecial(false);
  };

  const validateFields = () => {
    if (!activity) {
      setError("* יש לבחור אופי פעילות");
    } else if (!effort) {
      setError("* יש לבחור רמת מאמץ");
    } else if (
      !heatLevel &&
      !uncomfterableUnits &&
      !(temp && relativeHumidity)
    ) {
      setError('* יש למלא לחות יחסית וטמפרטורה / ערך יא"נ / דרגת עומס חום.');
    } else {
      setError("");
      return true;
    }
    return false;
  };

  const validateFieldsValues = () => {
    if (relativeHumidity) {
      if (!Number(relativeHumidity)) {
        setError("הלחות היחסית שהוכנסה אינה ערך מספרי.");
        return false;
      } else if (!between(Number(relativeHumidity), 0, 100)) {
        setError("הלחות היחסית שהוכנסה איננה בטווח הרשום.");
        return false;
      }
    }

    if (temp) {
      if (!Number(temp)) {
        setError("הטמפרטורה שהוכנסה אינה ערך מספרי.");
        return false;
      } else if (!between(Number(temp), -50, 60)) {
        setError("הטמפרטורה שהוכנסה איננה בטווח הרשום.");
        return false;
      }
    }

    if (uncomfterableUnits) {
      if (!Number(uncomfterableUnits)) {
        setError("ערך אי הנוחות שהוכנס אינו ערך מספרי.");
        return false;
      } else if (!between(Number(uncomfterableUnits), 10, 50)) {
        setError("ערך אי הנוחות שהוכנס איננו בטווח הרשום.");
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    if (heatLevel === "ללא עומס (0)") {
      setYanLevel("עד 22.0");
    }
    if (heatLevel === "קל (1)") {
      setYanLevel("22.1-24.0");
    }
    if (heatLevel === "מתון (2)") {
      setYanLevel("24.1-26.0");
    }
    if (heatLevel === "בינוני (3)") {
      setYanLevel("26.1-28.0");
    }
    if (heatLevel === "כבד (4)") {
      setYanLevel("28.1-30.0");
    }
    if (heatLevel === "קיצוני (5)") {
      setYanLevel("מעל 30.1");
    }
  }, [heatLevel]);

  const Check = () => {
    const yan = Number(uncomfterableUnits);
    let tempCheck = uncomfterableUnits;
    tempCheck = String(
      -2.394479 +
        0.784533 * Number(temp) +
        0.022226 * Number(relativeHumidity) +
        0.0023765 * Number(temp) * Number(relativeHumidity)
    );
    const tempCheckNumber = Number(tempCheck);
    if (26 < yan && yan <= 28) {
      return true;
    } else if (heatLevel === "בינוני (3)") {
      return true;
    } else if (tempCheckNumber > 26 && tempCheckNumber <= 28) {
      return true;
    }
    return false;
  };

  const calculate = () => {
    if (uncomfterableUnits === "23") {
      console.log(true);
      setIsUncomfterableUnits23(true);
    } else setIsUncomfterableUnits23(false);
    let tmpUncomfterableUnits = uncomfterableUnits;
    let tmpHeatLevel = heatLevel;

    if (selected === values[0]) {
      tmpUncomfterableUnits = String(
        -2.394479 +
          0.784533 * Number(temp) +
          0.022226 * Number(relativeHumidity) +
          0.0023765 * Number(temp) * Number(relativeHumidity)
      );
      const numericUncomfterableUnits = Number(tmpUncomfterableUnits);
      if (numericUncomfterableUnits <= 22) {
        tmpHeatLevel = "ללא עומס (0)";
      } else if (
        22 < numericUncomfterableUnits &&
        numericUncomfterableUnits <= 24
      ) {
        tmpHeatLevel = "קל (1)";
      } else if (
        24 < numericUncomfterableUnits &&
        numericUncomfterableUnits <= 26
      ) {
        tmpHeatLevel = "מתון (2)";
      } else if (
        26 < numericUncomfterableUnits &&
        numericUncomfterableUnits <= 28
      ) {
        console.log("true");
        if (YesspecialPopulation) {
          tmpHeatLevel = "כבד (4)";
        } else {
          tmpHeatLevel = "בינוני (3)";
        }
      } else if (
        28 < numericUncomfterableUnits &&
        numericUncomfterableUnits <= 30
      ) {
        tmpHeatLevel = "כבד (4)";
      } else if (numericUncomfterableUnits > 30) {
        tmpHeatLevel = "קיצוני (5)";
      }
    } else if (selected === values[1]) {
      if (
        tmpHeatLevel == "בינוני (3)" &&
        YesspecialPopulation &&
        effort != "ללא מאמץ (0)"
      ) {
        tmpHeatLevel = "כבד (4)";
      }
      switch (tmpHeatLevel) {
        case "ללא עומס (0)":
          tmpUncomfterableUnits = "עד 22.0";
          break;

        case "קל (1)":
          tmpUncomfterableUnits = "22.1-24.0";
          break;

        case "מתון (2)":
          tmpUncomfterableUnits = "24.1-26.0";

          break;

        case "בינוני (3)":
          tmpUncomfterableUnits = "26.1-28.0";

          break;

        case "כבד (4)":
          tmpUncomfterableUnits = "28.1-30.0";

          break;

        case "קיצוני (5)":
          tmpUncomfterableUnits = "מעל 30.1";

          break;
      }
    } else if (selected === values[2]) {
      const numericUncomfterableUnits = Number(tmpUncomfterableUnits);
      if (numericUncomfterableUnits <= 22) {
        tmpHeatLevel = "ללא עומס (0)";
      } else if (
        21 < numericUncomfterableUnits &&
        numericUncomfterableUnits <= 24
      ) {
        tmpHeatLevel = "קל (1)";
      } else if (
        24 < numericUncomfterableUnits &&
        numericUncomfterableUnits <= 26
      ) {
        tmpHeatLevel = "מתון (2)";
      } else if (
        26 < numericUncomfterableUnits &&
        numericUncomfterableUnits <= 28
      ) {
        if (NospecialPopulation) {
          tmpHeatLevel = "בינוני (3)";
        } else if (YesspecialPopulation && effort == "ללא מאמץ (0)") {
          tmpHeatLevel = "בינוני (3)";
        } else {
          tmpHeatLevel = "כבד (4)";
        }
      } else if (
        28 < numericUncomfterableUnits &&
        numericUncomfterableUnits <= 30
      ) {
        tmpHeatLevel = "כבד (4)";
      } else if (numericUncomfterableUnits > 30) {
        tmpHeatLevel = "קיצוני (5)";
      }
    }

    if (activity === "אימון") {
      setIsTakingPlace((training.isTakingPlace as any)[effort][tmpHeatLevel]);
      if (isTakingPlace == "ניתן לבצע פעילות") {
        setLimitations((training.limitations as any)[effort][tmpHeatLevel]);
      } else {
        setLimitations((training.limitations as any)[effort][tmpHeatLevel]);
      }
    } else if (activity === "מיון פיזי") {
      setIsTakingPlace(
        (examination.isTakingPlace as any)[effort][tmpHeatLevel]
      );
      setLimitations((examination.limitations as any)[effort][tmpHeatLevel]);
    }
    setLiquidReturn((liquids as any)[effort][tmpHeatLevel]);
    setIsResults(true);
    setHeatLevel(tmpHeatLevel);

    if (
      fixNumber(tmpUncomfterableUnits, 1) === "0.0" ||
      fixNumber(tmpUncomfterableUnits, 1) === "NaN"
    ) {
      setUncomfterableUnits(tmpUncomfterableUnits);
    } else {
      setUncomfterableUnits(fixNumber(tmpUncomfterableUnits, 1));
    }

    // setUncomfterableUnits(
    //   fixNumber(tmpUncomfterableUnits, 1) == "0.0"
    //     ? tmpUncomfterableUnits
    //     : fixNumber(tmpUncomfterableUnits, 1)
    // );
  };
  useEffect(() => {
    switch (limitations) {
      case "ללא":
        setWrapperColor("#2bcf4b");
        setIcon("green");
        break;
      case "אין הגבלת פעילות מעבר למתחייב מהוראות הבטיחות באימונים":
        setWrapperColor("#2bcf4b");
        setIcon("green");
        break;
      case `פעילות מותנית באישור סא"ל בהתייעצות רופא וקא"ג. בהינתן אישורם, ניתן לבצע פעילות: כל 60 דקות עבודה יש לבצע 15 דקות הפסקה בצל.`:
        setWrapperColor("#ff9215");
        setIcon("orange");
        break;
      case `פעילות מותנית באישור אל"מ המהווה סמכות פיקודית על היחידה המתאמת ובהתייעצות רופא. בהינתן אישורם, ניתן לבצע פעילות: לאחר 45 דקות עבודה יש לבצע 15 דקות הפסקה בצל.
      `:
        setWrapperColor("#ff9215");
        setIcon("orange");
        break;
      case "לא ניתן לבצע פעילות.":
        setWrapperColor("#ff0404");
        setIcon("red");
        break;
      case "לא ניתן לבצע מיון בחום זה":
        setWrapperColor("#ff0404");
        setIcon("red");
        break;
      default:
        setWrapperColor("#fdff76");
        setIcon("orange");
    }
  }, [limitations]);

  const Modal = ({ shouldShowSpecial, setShouldShowSpecial }: SpecialProps) => {
    //   const [shouldShow, setShouldShow] = useState(false);
    return (
      <>
        {/* <button onClick={() => setShouldShow(true)}>Show</button> */}
        {shouldShowSpecial && (
          <ModalBackground
            onClick={() => {
              setShouldShowSpecial(false);
              handleCalc();
            }}
          >
            <ModalBody onClick={(e) => e.stopPropagation()}>
              <X
                onClick={() => {
                  setShouldShowSpecial(false);
                  handleCalc();
                }}
              >
                X
              </X>
              <div>האם מדובר באוכלוסיות מיוחדת?</div>
              <Text>
                {" "}
                הכשרות יסוד, ו\או חיילים עד חודשיים בשירות, מילואים, מלש"בים,
                גדנ"ע, קד"צ.
              </Text>
              <CheckBoxWrapper>
                <YesandNo>כן</YesandNo>
                <YesandNo>לא</YesandNo>
              </CheckBoxWrapper>
              <CheckBoxWrapper>
                {" "}
                <HeatCheckBox
                  text={""}
                  clicked={YesspecialPopulation}
                  setClicked={() => {
                    setNoSpecialPopulation(false);
                    setYesSpecialPopulation(true);
                  }}
                />
                <HeatCheckBoxNo
                  text={""}
                  clicked={NospecialPopulation}
                  setClicked={() => {
                    setNoSpecialPopulation(true);
                    setYesSpecialPopulation(false);
                  }}
                />
              </CheckBoxWrapper>
              <StyledButton onClick={() => handleCalc()}>
                {"חישוב"}
              </StyledButton>
            </ModalBody>
          </ModalBackground>
        )}
      </>
    );
  };

  const handleCalc = () => {
    if (validateFields() && validateFieldsValues()) {
      console.log(uncomfterableUnits);
      calculate();
    }
  };

  return (
    <>
      {isResults && (
        <Results>
          <h4>{`הגבלות לפעילות בעומס חום`}</h4>
          <p className="asd">
            {icon === "green" ? (
              <BiCheckCircle color="green" />
            ) : icon === "orange" ? (
              <Exclamation />
            ) : (
              <CgCloseO style={{ width: "5rem", height: "2rem" }} color="red" />
            )}
            {icon === "green" ? (
              <b>{`${isTakingPlace} ברמה: ${effort}`}</b>
            ) : icon === "orange" ? (
              <b>{`ניתן לבצע פעילות בהתאם להגבלות ברמת מאמץ: ${effort}`}</b>
            ) : (
              <b>{`לא ניתן לבצע פעילות ברמת מאמץ: ${effort}`}</b>
            )}
          </p>
          <p>
            <b>{`ערך יא"נ (DI) הוא: `} </b>
            {uncomfterableUnits}
          </p>

          <p>
            <b>{`מגבלות בהתאם לדרגת עומס חום: `} </b>
            {heatLevel} {yanLevel}
          </p>
          <ResultsWrapper wrapperColor={wrapperColor}>
            <p>
              <b>{`הגבלות:`} </b>
              {limitations}
            </p>

            {isTakingPlace ===
              `לא ניתן לבצע פעילות, אלא באישור אל"מ המהווה סמכות פיקודית על היחידה המתאמנת ובהתייעצות רופא` && (
              <>
                {activity === "מיון פיזי" ? (
                  <p>
                    <b>{`הערות:`} </b>
                    {isUncomfterableUnits23 &&
                      `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה`}
                    {!isUncomfterableUnits23 &&
                    heatLevel !== "ללא עומס (0)" &&
                    heatLevel !== "קל (1)"
                      ? `בעומס חום מעל 23.0 יא"ן, על המפקד לוודא מדידה של עומס החום בטרם ובמהלך האימונים כל 30 דקות. \n 
                      בעקבות עומס החום, חלה על המפקד החובה להטיל הגבלות על האימון ולוודא כי היחידות קיבלו את המידע.                    `
                      : !isUncomfterableUnits23 && heatLevel !== "ללא עומס (0)"
                      ? `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.\n עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 &&
                        `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.`}
                  </p>
                ) : (
                  <p>
                    <b>{`הערות:`} </b>
                    {isUncomfterableUnits23 &&
                      `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה`}
                    {!isUncomfterableUnits23 &&
                    heatLevel !== "ללא עומס (0)" &&
                    heatLevel !== "קל (1)"
                      ? `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 && heatLevel !== "ללא עומס (0)"
                      ? `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.\n עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 &&
                        `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.`}
                  </p>
                )}
                <p>
                  <b>{`החזר נוזלים: `} </b>
                  {`${liquidsReturn} מ"ל (${
                    Number(liquidsReturn) / 1000
                  } ליטר) מים לשעה.`}
                </p>
                <p
                  className={"note"}
                >{`* תקף לאדם השוקל 70 ק"ג ביום שמשי. לחישוב מדוייק אנא גש למחשבון נוזלים.`}</p>
              </>
            )}
            {isTakingPlace ===
              `לא ניתן לבצע פעילות, אלא באישור סא"ל בהתייצות קופא וקא"ג` && (
              <>
                {activity === "מיון פיזי" ? (
                  <p>
                    <b>{`הערות:`} </b>
                    {isUncomfterableUnits23 &&
                      `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה`}
                    {!isUncomfterableUnits23 &&
                    heatLevel !== "ללא עומס (0)" &&
                    heatLevel !== "קל (1)"
                      ? `בעומס חום מעל 23.0 יא"ן, על המפקד לוודא מדידה של עומס החום בטרם ובמהלך האימונים כל 30 דקות. \n 
                      בעקבות עומס החום, חלה על המפקד החובה להטיל הגבלות על האימון ולוודא כי היחידות קיבלו את המידע.                    `
                      : !isUncomfterableUnits23 && heatLevel !== "ללא עומס (0)"
                      ? `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.\n עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 &&
                        `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.`}
                  </p>
                ) : (
                  <p>
                    <b>{`הערות:`} </b>
                    {isUncomfterableUnits23 &&
                      `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה`}
                    {!isUncomfterableUnits23 &&
                    heatLevel !== "ללא עומס (0)" &&
                    heatLevel !== "קל (1)"
                      ? `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 && heatLevel !== "ללא עומס (0)"
                      ? `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.\n עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 &&
                        `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.`}
                  </p>
                )}
                <p>
                  <b>{`החזר נוזלים: `} </b>
                  {`${liquidsReturn} מ"ל (${
                    Number(liquidsReturn) / 1000
                  } ליטר) מים לשעה.`}
                </p>
                <p
                  className={"note"}
                >{`* תקף לאדם השוקל 70 ק"ג ביום שמשי. לחישוב מדוייק אנא גש למחשבון נוזלים.`}</p>
              </>
            )}

            {isTakingPlace === "ניתן לבצע פעילות" && (
              <>
                {activity === "מיון פיזי" && heatLevel !== "ללא עומס (0)" ? (
                  <p>
                    <b>{`הערות:`} </b>
                    {isUncomfterableUnits23 &&
                      `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה`}
                    {!isUncomfterableUnits23 &&
                    heatLevel !== "ללא עומס (0)" &&
                    heatLevel !== "קל (1)"
                      ? `בעומס חום מעל 23.0 יא"ן, על המפקד לוודא מדידה של עומס החום בטרם ובמהלך האימונים כל 30 דקות. \n 
                      בעקבות עומס החום, חלה על המפקד החובה להטיל הגבלות על האימון ולוודא כי היחידות קיבלו את המידע.                    `
                      : !isUncomfterableUnits23 && heatLevel !== "ללא עומס (0)"
                      ? `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.\n עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 &&
                        `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.`}
                  </p>
                ) : (
                  <p>
                    <b>{`הערות:`} </b>
                    {isUncomfterableUnits23 &&
                      `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה`}
                    {!isUncomfterableUnits23 &&
                    heatLevel !== "ללא עומס (0)" &&
                    heatLevel !== "קל (1)"
                      ? `עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 && heatLevel !== "ללא עומס (0)"
                      ? `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.\n עבור עומס חום מעל 23.0 יא"ן יש לבצע בדיקת עומס חום בכל שעה.`
                      : !isUncomfterableUnits23 &&
                        `עבור עומס חום מתחת ל-23.0 יא"ן יש לבצע בדיקת עומס חום בשטח הפעילות בכל 3 שעות.`}
                  </p>
                )}
                <p>
                  <b>{`החזר נוזלים: `} </b>
                  {`${liquidsReturn} מ"ל (${
                    Number(liquidsReturn) / 1000
                  } ליטר) מים לשעה.`}
                </p>
                <p
                  className={"note"}
                >{`* תקף לאדם השוקל 70 ק"ג ביום שמשי. לחישוב מדוייק אנא גש למחשבון נוזלים.`}</p>
              </>
            )}

            {additionalInfo && (
              <p>
                <b>{`מידע נוסף:`} </b>
                {additionalInfo}
              </p>
            )}
          </ResultsWrapper>
          <StyledButton onClick={clearForm}>{"לחישוב מחדש"}</StyledButton>

          <BottomDiv />
        </Results>
      )}
      {!isResults && !openImage && (
        <Calc>
          <TitleP>
            המידע המוצג ביישומון זה נועד למטרות תכנון ואינו מחליף מדידה בשטח
            ביצוע הפעילות, בהתאם לפקודות.
          </TitleP>
          <HeatCalcToolTip
            text={`הממשק הינו תמצית של הורא"ב כרך א', חלק ב' הוראה 1.1 והוראת קחצ"ר מס' 2, 1 ו-4 אך אינה באה להחליפן. אימון בבגדי מגן פחמי ומסיכות אב"כ יבוצע ע"פ המפורט בהורא"ב חה"ן 9.1`}
            top={0.4}
            right={92}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          >
            <div></div>
          </HeatCalcToolTip>
          <Abutton
            onClick={() => {
              setOpenImage(true);
              setIsResults(false);
            }}
          >
            לחץ למדידה נכונה של תנאי אקלים
          </Abutton>
          {activity === "אימון" ? (
            <Modal
              setShouldShowSpecial={setShouldShowSpecial}
              shouldShowSpecial={shouldShowSpecial}
            />
          ) : (
            ""
          )}
          <ToggleSwitch
            values={values}
            selected={selected}
            setSelected={setSelected}
          />
          {/* <Choose> */}
          {/* <h5>{`הכנס יחידות יא"נ, או דרגת עומס חום, או טמפרטורה ולחות יחסית`}</h5> */}
          {selected === `יא"נ` && (
            <YanWrapper>
              <KestrelHeat />

              <CalcInput
                placeholder="לדוגמה - 25"
                value={uncomfterableUnits}
                disabled={
                  !!heatLevel || !!relativeHumidity || !!temp ? true : false
                }
                setValue={setUncomfterableUnits}
                topText={`ערך הנמדד בקסטרל (יא"נ)`}
                bottomText={"טווח : 10 עד 50"}
              />
            </YanWrapper>
          )}
          {/* <div className="seperator"></div> */}
          {selected === "עומס חום" && (
            <ImageSelect
              title={"דרגת עומס חום"}
              options={tempOptions}
              setSelected={setHeatLevel}
              selected={heatLevel}
              disabled={
                !!uncomfterableUnits || !!relativeHumidity || !!temp
                  ? true
                  : false
              }
              setShouldShow={setShouldShow}
              setToolTipMessage={setToolTipMessage}
            />
          )}
          {selected === "לחות וטמפ'" && (
            <>
              <CalcInput
                placeholder="לדוגמה - 60"
                value={relativeHumidity}
                setValue={setRelativeHumidity}
                topText={"לחות יחסית (%)"}
                bottomText={"טווח: 0 עד 100"}
                disabled={!!uncomfterableUnits || !!heatLevel ? true : false}
              />

              <CalcInput
                placeholder="לדוגמה - 25"
                value={temp}
                setValue={setTemp}
                topText={"טמפרטורה (°C)"}
                bottomText={"טווח : מינוס 50 עד 60"}
                disabled={!!uncomfterableUnits || !!heatLevel ? true : false}
              />
            </>
          )}
          {/* </ToolTip> */}
          {/* </Choose> */}
          <ImageSelect
            title={"אופי פעילות"}
            options={activityOptions}
            setSelected={setActivity}
            selected={activity}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          />
          <ImageSelect
            title={`רמת מאמץ (0-${activity == "מיון פיזי" ? "3" : "4"})`}
            options={
              activity == "מיון פיזי"
                ? effortOptions.filter((el) => el.text != "מאמץ עצים (4)")
                : effortOptions
            }
            setSelected={setEffort}
            selected={effort}
            isExam={activity == "מיון פיזי" ? true : false}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          />

          {error && <p className="error">{error}</p>}
          {activity === "אימון" && Check() ? (
            <StyledButton
              onClick={() => {
                if (validateFields() && validateFieldsValues()) {
                  setShouldShowSpecial(true);
                }
              }}
            >
              {"חישוב"}
            </StyledButton>
          ) : (
            <StyledButton onClick={() => handleCalc()}>{"חישוב"}</StyledButton>
          )}
          <BottomDiv />
        </Calc>
      )}
      {openImage ? (
        <ImageWrapperrrrr>
          <StyledButton onClick={() => setOpenImage(false)}>חזור</StyledButton>
          <ConditionModaL />
          <BottomDiv />
        </ImageWrapperrrrr>
      ) : (
        ""
      )}
    </>
  );
};
const Ul = styled.ul`
  padding: 0px 20px 0px 0px;
`;

const limitationList = (
  <div>
    <Ul>
      <li>כל 60 דקות עבודה יש לבצע 15 דקות הפסקה בצל.</li>
      <li>
        אחת ליום ניתן לבצע פעילות רצופה של 120 דקות ולאחריה 30 דקות מנוחה.
      </li>
      <li>
        במסעות - לאחר 4 עד 6 שעות מתחילת המסע, תינתן מנוחה בת 30 דקות לפחות ללא
        ציוד כלל (כולל הסרה של אפוד או ווסט).
      </li>
    </Ul>
  </div>
);
const tempOptions: Option[] = [
  {
    Img: Temp4,
    text: "ללא עומס (0)",
    textt: "22.0 ומטה",
    isSelected: false,
    color: "#b8b8b8",
  },
  {
    Img: Temp3,
    text: "קל (1)",
    textt: "22.1-24.0",
    isSelected: false,
    color: "#2bcf4b",
  },
  {
    Img: Temp2,
    text: "מתון (2)",
    textt: "24.1-26.0",
    isSelected: false,
    color: "#c3c62d",
  },
  {
    Img: Temp1,
    text: "בינוני (3)",
    textt: "26.1-28.0",
    isSelected: false,
    color: "#ff9215",
  },
  {
    Img: Temp1,
    text: "כבד (4)",
    textt: "28.1-30.0",
    isSelected: false,
    color: "#ff0404",
  },
  {
    Img: Temp1,
    text: "קיצוני (5)",
    textt: "30.1 ומעלה",
    isSelected: false,
    color: "#0a0808",
  },
];

const activityOptions: Option[] = [
  {
    Img: Sort,
    text: "מיון פיזי",
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: Exercise,
    text: "אימון",
    isSelected: false,
    color: "#365ABA",
  },
];

const effortOptions: Option[] = [
  {
    Img: Effort4,
    text: "ללא מאמץ (0)",
    explanationExam: "תרגיל חשיבה במנוחה",
    explanation:
      "- פעילות תאורטית בכיתה \\ מקום מוצל\n- הליכה עד למרחק 500 מ' ליום.",
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: Effort3,
    text: "מאמץ קל (1)",
    isSelected: false,
    explanationExam:
      '- הליכה עד למרחק של 500 מטר רצוף ובקצב של עד 4 קמ"ש (כ-8 דקות ל-500 מטר הליכה), ולא מעל 2 ק"מ ביממה, על בגדי ב\' או בגדי ספורט ללא משקל נוסף.\n\
    - תרגילים טכניים הכוללים מאמץ גופני קל כגון פירוק והרכבת נשק.',
    explanation:
      "- מטווחים נייחים\n- הליכה מעל 500 מ' ולא יותר מ- 2000 מ' ליום (לרבות אפוד ונשק)\n- תרגול טכני (פרוק והרכבת נשק קל, קשר, ע\"ר וכד'\n- אימונים ע\"ג רכב ורק\"מ (ללא פעילות רגלית)",
    color: "#365ABA",
  },
  {
    Img: Effort2,
    text: "מאמץ בינוני (2)",
    isSelected: false,
    explanationExam:
      '- תרגול נשיאת תד"ל, מנשא ואלונקה\n\
        - תרגולים טכניים הכוללים מאמץ גופני: חפירות, הקמת מאהל, פק"לי כוח וכדו\'.',
    explanation:
      '- תרגילים\\אימונים רגליים\n\
    - מסע רגיל\\תנועה רגלית (עד 6 קמ"ש במישור עם אפוד, נשק ואגמ"ק) עד 30 ק"מ משוקלל\n\
    - ניווטים\n\
    - מטווחים בתנועה או לאחר מאמץ\n\
    - אימוני כוח וקרב מגע בסיסי\n\
    - ריצה קצב קל',
    color: "#365ABA",
  },
  {
    Img: Effort1,
    text: "מאמץ קשה (3)",
    isSelected: false,
    explanationExam:
      "מסע, ריצה, בוחן כש\"ג, זחילות, שקים, סוציומטרי אלונקה ונשיאת משקל, הליכה עם קורה, הליכה עם אוהל וכדו'.",
    explanation:
      '- תרגילים\\אימונים רגליים\n\
    - תנועה רגלית בתנאי שטח קשים\n\
    - מסע רגיל\\פק"לים ותנועה רגלית מעל 30 ק"מ משוקלל\n\
    - מסע מזורז (מעל 6 קמ"ש)\n\
    - מסע אלונקות\n\
    - קרב מגע מתקדם\n\
    - ריצות',
    color: "#365ABA",
  },
  {
    Img: effortExtremlyHard,
    text: "מאמץ עצים (4)",
    isSelected: false,
    explanation: 'פעילויות במאמץ עצים ע"פ הוראת קחצ"ר בנושא כושר קרבי - מס\' 4',
    color: "#365ABA",
  },
];

const limitsApproved =
  "אין הגבלת פעילות מעבר למתחייב מהוראות הבטיחות באימונים. ";

const training = {
  isTakingPlace: {
    "ללא מאמץ (0)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "ניתן לבצע פעילות",
      "בינוני (3)": "ניתן לבצע פעילות",
      "כבד (4)": "ניתן לבצע פעילות",
      "קיצוני (5)": "ניתן לבצע פעילות",
    },
    "מאמץ קל (1)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "ניתן לבצע פעילות",
      "בינוני (3)": "ניתן לבצע פעילות",
      "כבד (4)": "ניתן לבצע פעילות",
      "קיצוני (5)":
        'לא ניתן לבצע פעילות, אלא באישור אל"מ המהווה סמכות פיקודית על היחידה המתאמנת ובהתייעצות רופא',
    },
    "מאמץ בינוני (2)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "ניתן לבצע פעילות",
      "בינוני (3)": "ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
    "מאמץ קשה (3)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "ניתן לבצע פעילות",
      "בינוני (3)": "ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
    "מאמץ עצים (4)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": 'לא ניתן לבצע פעילות, אלא באישור סא"ל בהתייצות קופא וקא"ג',
      "בינוני (3)": "לא ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
  },

  limitations: {
    "ללא מאמץ (0)": {
      "ללא עומס (0)": "ללא",
      "קל (1)": "ללא",
      "מתון (2)": "ללא",
      "בינוני (3)": "ללא",
      "כבד (4)": "ללא",
      "קיצוני (5)": "ללא",
    },
    "מאמץ קל (1)": {
      "ללא עומס (0)": "ללא",
      "קל (1)": "ללא",
      "מתון (2)": "ללא",
      "בינוני (3)": "ללא",
      "כבד (4)": "לאחר 45 דקות עבודה יש לבצע 15 דקות הפסקה בצל. ",
      "קיצוני (5)": `פעילות מותנית באישור אל"מ המהווה סמכות פיקודית על היחידה המתאמת ובהתייעצות רופא. בהינתן אישורם, ניתן לבצע פעילות: לאחר 45 דקות עבודה יש לבצע 15 דקות הפסקה בצל.
      `,
    },
    "מאמץ בינוני (2)": {
      "ללא עומס (0)": limitationList,
      "קל (1)": limitationList,
      "מתון (2)": limitationList,
      "בינוני (3)": limitationList,
      "כבד (4)": "לא ניתן לבצע פעילות.",
      "קיצוני (5)": "לא ניתן לבצע פעילות.",
    },
    "מאמץ קשה (3)": {
      "ללא עומס (0)": limitationList,
      "קל (1)": limitationList,
      "מתון (2)": limitationList,
      "בינוני (3)": limitationList,
      "כבד (4)": "לא ניתן לבצע פעילות.",
      "קיצוני (5)": "לא ניתן לבצע פעילות.",
    },
    "מאמץ עצים (4)": {
      "ללא עומס (0)": "כל 60 דקות עבודה יש לבצע 15 דקות הפסקה בצל.",
      "קל (1)": "כל 60 דקות עבודה יש לבצע 15 דקות הפסקה בצל.",
      "מתון (2)": `פעילות מותנית באישור סא"ל בהתייעצות רופא וקא"ג. בהינתן אישורם, ניתן לבצע פעילות: כל 60 דקות עבודה יש לבצע 15 דקות הפסקה בצל.`,
      "בינוני (3)": "לא ניתן לבצע פעילות.",
      "כבד (4)": "לא ניתן לבצע פעילות.",
      "קיצוני (5)": "לא ניתן לבצע פעילות.",
    },
  },
};

const oneStarExamination =
  'בזמן הפסקה יאפשרו אנשי הסגל למועמדים להתיר את הציוד (אפודים. פק"לים וכדומה) מעל גופם וינחו את המועמדים לשתות על פי המומלץ';
const examination = {
  isTakingPlace: {
    "ללא מאמץ (0)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "ניתן לבצע פעילות",
      "בינוני (3)": "ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
    "מאמץ קל (1)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "ניתן לבצע פעילות",
      "בינוני (3)": "ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
    "מאמץ בינוני (2)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "ניתן לבצע פעילות",
      "בינוני (3)": "לא ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
    "מאמץ קשה (3)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "לא ניתן לבצע פעילות",
      "בינוני (3)": "לא ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
    "מאמץ עצים (4)": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "מתון (2)": "לא ניתן לבצע פעילות",
      "בינוני (3)": "לא ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
  },

  limitations: {
    "ללא מאמץ (0)": {
      "ללא עומס (0)": "אין הגבלת פעילות מעבר למתחייב מהוראות הבטיחות במיונים",
      "קל (1)": "אין הגבלת פעילות מעבר למתחייב מהוראות הבטיחות במיונים",
      "מתון (2)": "אין הגבלת פעילות מעבר למתחייב מהוראות הבטיחות במיונים",
      "בינוני (3)": "אין הגבלת פעילות מעבר למתחייב מהוראות הבטיחות במיונים",
      "כבד (4)": "לא ניתן לבצע מיון בחום זה",
      "קיצוני (5)": "לא ניתן לבצע מיון בחום זה",
    },
    "מאמץ קל (1)": {
      "ללא עומס (0)": limitsApproved,
      "קל (1)": "אין הגבלת פעילות מעבר למתחייב מהוראות הבטיחות במיונים",
      "מתון (2)":
        "כל מועמד יבצע הפסקה של 15 דקות בצל לאחר כל 90 דקות פעילות." +
        oneStarExamination,
      "בינוני (3)":
        "כל מועמד יבצע הפסקה של 15 דקות בצל לאחר כל 90 דקות פעילות." +
        oneStarExamination,
      "כבד (4)": "לא ניתן לבצע מיון בחום זה",
      "קיצוני (5)": "לא ניתן לבצע מיון בחום זה",
    },
    "מאמץ בינוני (2)": {
      "ללא עומס (0)": limitsApproved,
      "קל (1)":
        "כל מועמד יבצע הפסקה של 15 דקות בצל לאחר כל 30 דקות פעילות. " +
        oneStarExamination,
      "מתון (2)":
        "כל מועמד יבצע הפסקה של 15 דקות בצל לאחר כל 30 דקות פעילות. " +
        oneStarExamination,
      "בינוני (3)": "לא ניתן לבצע מיון בחום זה",
      "כבד (4)": "לא ניתן לבצע מיון בחום זה",
      "קיצוני (5)": "לא ניתן לבצע מיון בחום זה",
    },
    "מאמץ קשה (3)": {
      "ללא עומס (0)": limitsApproved,
      "קל (1)":
        "כל מועמד יבצע הפסקה של 15 דקות בצל לאחר כל 30 דקות פעילות. בזמן זה תתאפשר התרת ציוד ושתייה.",
      "מתון (2)": "לא ניתן לבצע מיון בחום זה",
      "בינוני (3)": "לא ניתן לבצע מיון בחום זה",
      "כבד (4)": "לא ניתן לבצע מיון בחום זה",
      "קיצוני (5)": "לא ניתן לבצע מיון בחום זה",
    },
    "מאמץ עצים (4)": {
      "ללא עומס (0)":
        "30 דק' לכל היותר, למעט מסע שניתן לעשותו 45 דק' לכל היותר ברציפות. אחריהן תתבצע מנוחה בצל בת 15 דק' לפחות. לפני ואחרי מסע יש להימנע מלבצע מאמץ גופני מעל דרגה 1, שעתיים מנוחה לפני על כל 7 ק\"מ מסע משוקלל ושעה על כל 7 ק\"מ משוקללים לאחר המסע . " +
        oneStarExamination,
      "קל (1)":
        'ניתן לבצע פעילות בכל מסע בצל בת 15 דק\' לפחות. לפני ואחרי מסע יש להימנע מלבצע מאמץ גופני מעל דרגה 1, שעתיים מנוחה לפני על כל 7 ק"מ מסע משוקלל ושעה על כל 7 ק"מ משוקללים לאחר המסע . ' +
        oneStarExamination,
      "מתון (2)": "לא ניתן לבצע פעילות",
      "בינוני (3)": "לא ניתן לבצע פעילות",
      "כבד (4)": "לא ניתן לבצע פעילות",
      "קיצוני (5)": "לא ניתן לבצע פעילות",
    },
  },
};

const liquids = {
  "ללא מאמץ (0)": {
    "ללא עומס (0)": 50,
    "קל (1)": 100,
    "מתון (2)": 100,
    "בינוני (3)": 100,
    "כבד (4)": 200,
    "קיצוני (5)": 200,
  },
  "מאמץ קל (1)": {
    "ללא עומס (0)": 250,
    "קל (1)": 500,
    "מתון (2)": 750,
    "בינוני (3)": 750,
    "כבד (4)": 750,
    "קיצוני (5)": 1000,
  },
  "מאמץ בינוני (2)": {
    "ללא עומס (0)": 500,
    "קל (1)": 750,
    "מתון (2)": 1000,
    "בינוני (3)": 1000,
    "כבד (4)": "לא ניתן לבצע פעילות",
    "קיצוני (5)": "לא ניתן לבצע פעילות",
  },
  "מאמץ קשה (3)": {
    "ללא עומס (0)": 750,
    "קל (1)": 1000,
    "מתון (2)": 1250,
    "בינוני (3)": 1250,
    "כבד (4)": "לא ניתן לבצע פעילות",
    "קיצוני (5)": "לא ניתן לבצע פעילות",
  },
  "מאמץ עצים (4)": {
    "ללא עומס (0)": 750,
    "קל (1)": 1000,
    "מתון (2)": 1250,
    "בינוני (3)": 1250,
    "כבד (4)": "לא ניתן לבצע פעילות",
    "קיצוני (5)": "לא ניתן לבצע פעילות",
  },
};
