import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DefaultContainer } from "../../containers/DefaultContainer";

import { ReactComponent as Warning } from "../../assets/Svgs/warning.svg";
import { ReactComponent as Bugs } from "../../assets/Svgs/bugs.svg";

import { ReactComponent as EmployeeCard } from "../../assets/Svgs/employeeCard.svg";
import axios from "axios";
import { ACADEMY_COURSES_URL, CARDS, SMART_BASE_URL } from "../../constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import { BASE_URL } from "../../api/constants";
export const SmartBasePage = () => {
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;
  return (
    <DefaultContainer>
      <Body>
        <Title>בסיס חכם</Title>
        <Divexplanation>
          <div>הנגשת שירותי הבסיס הצה"לי בפלטפורמה דיגיטלית</div>
        </Divexplanation>
        <DivCard>
          <Header color="red">
            <Warning />
            <div id="title">תקלות בטיחות/בינוי</div>
          </Header>
          <div>
            <Text>פתיחת תקלות ע"י החיילים בבסיס</Text>
            <Text>ניהול תקלות ע"י אנשי התחזוקה בבסיס</Text>
            <Text>מדידת שביעות רצון מהטיפול בתקלה</Text>
          </div>
        </DivCard>
        <DivCard>
          <Header color="">
            <Bugs />
            <div id="title">קריאות הדברה</div>
          </Header>
          <div>
            <Text>פתיחת קריאה למקרפ"ר בנושא הדברה ובע"ח</Text>
            <Text>ניהול של הקריאות ע"י קצין בריאות הצבא האוגדתי</Text>
          </div>
        </DivCard>
        <DivCard>
          <Header color=" #595959">
            <EmployeeCard />
            <div id="title">כרטיס עבודה</div>
          </Header>
          <div>
            <Text>שילוח ותדרוך משימות נהיגה</Text>
            <Text>ניהול משימות נהיגה אונליין לקצין הרכב</Text>
            <Text>יכולת אכיפה ותחקור למשטרה צבאית</Text>
          </div>
        </DivCard>

        <ButtonNextPage
          onClick={() => {
            window.location.replace(SMART_BASE_URL);
            axios.post(
              `${BASE_URL}/areaLogs/addAreaLog`,
              { email, name: CARDS[5] },
              {
                withCredentials: true,
              }
            );
          }}
        >
          למעבר לבסיס חכם
        </ButtonNextPage>
        <MoreInfo>
          <div id="title">לפרטים נוספים והרשמה יש לפנות לכתובת</div>
          <Mail href="mailto: smartbase@idf.il">smartbase@idf.il</Mail>
        </MoreInfo>
      </Body>
    </DefaultContainer>
  );
};

const Body = styled.div`
  width: 90%;
  margin: auto;
`;

const DivCard = styled.div`
  margin-top: 0.2rem;
`;
const Title = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #4da6ff;
`;

const Divexplanation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-items: center;
  justify-content: center; */
  font-weight: bold;
`;

interface DivIconProps {
  color: string;
}

const Header = styled.div<DivIconProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  && svg {
    width: 2rem;
    fill: ${(props) => props.color};
    margin-left: 0.5rem;
    margin-right: -0.7rem;
  }
  && #title {
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const Text = styled.div`
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  width: 85%;
  color: white;
  margin: auto;
  background-color: #4da6ff;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ButtonNextPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  letter-spacing: 1px;
  width: 50%;
  margin: auto;
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  text-align: center;
  font-size: 1.1rem;
  color: white;
  background-color: #6a78f6;
  box-shadow: 2px 3px 5px #888888;
`;

const MoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  a {
    color: black;
  }
`;

const Mail = styled.a`
  font-weight: bold;
`;
