import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import "leaflet-geosearch/dist/geosearch.css";

export const MapSearch = () => {
  const map = useMap(); // access to leaflet map

  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = GeoSearchControl({
      provider,
      showMarker: false,
      searchLabel: "הזן כתובת",
      style: "button|bar",
      dir: "rtl",
      keepResult: false,
      //   marker: {
      //     MapMarker,
      //   },
    });

    map.addControl(searchControl);

    return () => {
      map.removeControl(searchControl);
    };
  }, []);
  return null; // don't want anything to show up from this comp
};
