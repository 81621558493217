import { useEffect, useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../api/constants";
import bg from "../../assets/login/bg.png";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { Ticket } from "./Ticket";
import { ITicket } from ".";
import { ViewTicket } from "./ViewTicket";
import { useHistory } from "react-router";
import { AiOutlineArrowRight } from "react-icons/ai";

export const ContactSupportTickets = () => {
  const history = useHistory();
  const user = useRecoilValue(userInfo); //מידע של יוזר
  const [ticketsOpen, setTicketsOpen] = useState<ITicket[]>([]);
  const [ticketsClosed, setTicketsClosed] = useState<ITicket[]>([]);
  const [viewTicket, setViewTicket] = useState(false);
  const [ticket, setTicket] = useState<ITicket>({} as ITicket);
  const [isLoading, setIsLoading] = useState(true);
  const [sliderInquiries, setSliderInquiries] = useState(false);

  useEffect(() => {
    if (!viewTicket) {
      setIsLoading(true);
      if (!sliderInquiries) {
        axios
          .get(`${BASE_URL}/contactTickets/getOpenTickets`, {
            withCredentials: true,
          })
          .then((res) => {
            if (res?.data?.success) {
              setTicketsOpen(res.data.tickets);
              setIsLoading(false);
            }
          });
      } else {
        axios
          .get(`${BASE_URL}/contactTickets/getClosedTickets`, {
            withCredentials: true,
          })
          .then((res) => {
            if (res?.data?.success) {
              setTicketsClosed(res.data.tickets);
              setIsLoading(false);
            }
          });
      }
    }
  }, [viewTicket, sliderInquiries]);
  return (
    <>
      {viewTicket ? (
        <ViewTicket
          setViewTicket={setViewTicket}
          ticket={ticket}
          isSupportUser={true}
        />
      ) : (
        <Wrapper>
          <br />
          <FormWrapper>
            <Header>
              <Titles
                sliderInquiries={sliderInquiries}
                id="titleOpen"
                onClick={() => setSliderInquiries(false)}
              >{`פניות פתוחות`}</Titles>
              <div id="or">|</div>
              <Titles
                sliderInquiries={sliderInquiries}
                onClick={() => setSliderInquiries(true)}
                id="titleClose"
              >{`פניות סגורות`}</Titles>
            </Header>
            {/* <ContactButton
              onClick={() => history.push("/")}
            >{`בחזרה לדף הבית`}</ContactButton> */}
            <p className="back" onClick={() => history.push("/")}>
              {`חזור`}
              <AiOutlineArrowRight />
            </p>

            <br />

            {isLoading ? (
              <p>{`טוען..`}</p>
            ) : !sliderInquiries ? (
              <TicketsWrapper>
                {ticketsOpen.length ? (
                  ticketsOpen.map((ticket) => (
                    <Ticket
                      key={ticket._id}
                      isSupportUser={true}
                      ticket={ticket}
                      setViewTicket={setViewTicket}
                      setTicket={setTicket}
                    />
                  ))
                ) : (
                  <p>{`אין פניות פתוחות`}</p>
                )}
              </TicketsWrapper>
            ) : (
              <TicketsWrapper>
                {ticketsClosed.length ? (
                  ticketsClosed.map((ticket) => (
                    <Ticket
                      key={ticket._id}
                      isSupportUser={true}
                      ticket={ticket}
                      setViewTicket={setViewTicket}
                      setTicket={setTicket}
                    />
                  ))
                ) : (
                  <p>{`אין פניות סגורות`}</p>
                )}
              </TicketsWrapper>
            )}
          </FormWrapper>
        </Wrapper>
      )}
    </>
  );
};

// const Tabs = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   width: 80%;
//   margin: auto;
//   margin-bottom: 2rem;
// `;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-top: 1rem;
  #or {
    color: black;
    /* font-size: 1.2rem; */
    font-weight: bold;
  }
`;

const Titles = styled.h3<{ sliderInquiries: boolean }>`
  margin: 0;
  &&#titleOpen {
    border-bottom: ${(props) =>
      props.sliderInquiries ? "1px solid white" : "1px solid red"};
    color: ${(props) => (props.sliderInquiries ? "#8c8c8c" : "black")};
    transition: all 0.4s ease-out;
    margin-left: 0.5rem;
  }
  &&#titleClose {
    border-bottom: ${(props) =>
      props.sliderInquiries ? "1px solid red" : "1px solid white"};
    color: ${(props) => (props.sliderInquiries ? "black" : "#8c8c8c")};
    transition: all 0.4s ease-out;
    margin-right: 0.5rem;
  }
`;

const TicketsWrapper = styled.div`
  max-height: 60vh;
  width: inherit;
  margin: 0;
  margin-top: 1rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: white;
  border-radius: 0.5rem;
  width: 90vw;
  margin-right: 5vw;
  max-height: 95vh;
  padding-bottom: 1rem;
  margin-top: 2.8rem;
  .back {
    position: absolute;
    top: 1.3rem;
    right: 2rem;
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 1px; */
  }
  /* button {
    position: absolute;
    top: 1rem;
    right: 10rem;
  } */
`;

const Wrapper = styled.div`
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
`;
