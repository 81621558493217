import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IUpdate } from "../../AdminsUpdates/Update";
import { UpdateRow } from "./UpdateRow";

interface IProps {
  updates: IUpdate[];
  isLoading: boolean;
  setMarkup: React.Dispatch<React.SetStateAction<string>>;
}

export const Center: React.FC<IProps> = ({ updates, isLoading, setMarkup }) => {
  return (
    <Wrapper>
      {isLoading && (
        <Row>
          <p>טוען..</p>
        </Row>
      )}
      {!isLoading &&
        updates?.map((update, i) => {
          return (
            <UpdateRow
              setMarkup={setMarkup}
              key={i}
              date={update.createdAt}
              content={update.content}
              category={update.category}
              name={update?.name}
              file={update?.file ? update.file : undefined}
            />
          );
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
`;

const Row = styled.div`
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #cdeaff;
  /* border-top: 1px solid rgba(80, 101, 132, 0.63); */
  border-bottom: 1px solid rgba(80, 101, 132, 0.63);
  padding: 0.3rem 0;
  p {
    margin-right: 1.5rem;
    font-weight: bold;
  }
  /* @media (max-width: 768px) {
    width: 80vw;
  } */
`;
