import React, { Dispatch, useState } from "react";
import styled from "styled-components";

import { ReactComponent as MP4 } from "../../assets/Svgs/mp4.svg";
import { ReactComponent as PDF } from "../../assets/Svgs/pdf.svg";
import { AiFillStar } from "react-icons/ai";
import { IconContext } from "react-icons";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
interface IProps {
  node: any;
  setNode: any;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  setRefresh: Dispatch<React.SetStateAction<boolean>>;
}

export const Favorites = ({
  node,
  setNode,
  setShowModal,
  setRefresh,
}: IProps) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Content>
        <div id="icone">
          {node.displayName.split(".").slice(-1)[0]?.toLowerCase() == "mp4" ? (
            <MP4 />
          ) : (
            <PDF />
          )}
        </div>
        <div
          id="name"
          onClick={() => {
            setNode(node);
            setShowModal(true);
          }}
        >
          {node.displayName.slice(0, node.displayName.length - 4)}
        </div>
        <div
          id="star"
          onClick={() => {
            setShow(true);
            axios
              .post(
                `${BASE_URL}/likes/deleteFavorite`,
                { id: node._id },
                {
                  withCredentials: true,
                }
              )
              .then((e) => {
                setRefresh(true);
              });
          }}
        >
          {show ? (
            <DivRemove>
              <div>{"מסיר"}</div>
              <div>{"קובץ.."}</div>
            </DivRemove>
          ) : (
            <IconContext.Provider value={{ color: "#ED8A19", size: "2rem" }}>
              <div>
                <AiFillStar />
              </div>
            </IconContext.Provider>
          )}
        </div>
      </Content>
    </>
  );
};

const DivRemove = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: green;
  height: 2rem;
`;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 1.6px solid #cccbcb;
  width: 20rem;
  margin: auto;
  #icone {
    width: 3rem;
    height: 2rem;
    font-size: 1.5rem;
    /* border-bottom: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: scale(0.75);
  }
  #name {
    /* display: flex; */
    width: 13.5rem;
    /* align-items: center; */
    margin: auto;
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #star {
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
  }
`;
