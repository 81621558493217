import styled from "styled-components";
interface cardProps {
  color?: string;
  marginBottom?: boolean;
  isActive?: boolean;
  textSize?: number;
}
interface textProps {
  textSize?: number;
  textWidth?: string;
}
export const ListDiv = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* min-width: 50vw; */
  /* max-width: 90vw; */
  height: 5vh;
  border-radius: 1rem;
  font-size: 0.8rem;
`;

export const CardWrapper = styled.div<cardProps>`
  border-radius: 11px;
  width: 30vw;
  height: 15vh;
  background-color: #90afcf54;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: bottom;
  margin-bottom: ${(props) => (props.marginBottom ? "2vh" : "")};
`;

export const Text = styled.p<textProps>`
  color: white;
  margin-top: 0.2vh;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  white-space: normal;
  word-wrap: break-word; 
  text-align: center; 
  width: 68%;
  height: 3%;


`;

export const ImgWrapper = styled.img<cardProps>`
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    margin-top: 1vh;
  }
  height: 55%;
`;

export const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 5vh;
`;
