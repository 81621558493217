import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CalcInput } from "../../../CalcInput";
import { ImageSelect } from "../../../ImageSelect";
import { ReactComponent as Temp1 } from "../../../../assets/Svgs/Physiological/Calculator/Temp1.svg";
import { ReactComponent as Temp2 } from "../../../../assets/Svgs/Physiological/Calculator/Temp2.svg";
import { ReactComponent as Temp3 } from "../../../../assets/Svgs/Physiological/Calculator/Temp3.svg";
import { ReactComponent as Temp4 } from "../../../../assets/Svgs/Physiological/Calculator/Temp4.svg";
import { ReactComponent as Sort } from "../../../../assets/Svgs/Physiological/Calculator/sort.svg";
import { ReactComponent as Exercise } from "../../../../assets/Svgs/Physiological/Calculator/exercise.svg";
import { ReactComponent as Effort1 } from "../../../../assets/Svgs/Physiological/Calculator/running.svg";
import { ReactComponent as Effort2 } from "../../../../assets/Svgs/Physiological/Calculator/manInHike.svg";
import { ReactComponent as Effort3 } from "../../../../assets/Svgs/Physiological/Calculator/shoot.svg";
import { ReactComponent as Effort4 } from "../../../../assets/Svgs/Physiological/Calculator/education.svg";
import { ReactComponent as Effort5 } from "../../../../assets/Svgs/Physiological/Calculator/effort5.svg";
import { ReactComponent as KestrelCold } from "../../../../assets/Svgs/Physiological/Calculator/KestrelCold.svg";
import { ReactComponent as weather1 } from "../../../../assets/Svgs/Physiological/Calculator/weather1.svg";
import { ReactComponent as weather2 } from "../../../../assets/Svgs/Physiological/Calculator/weather2.svg";
import { ReactComponent as weather3 } from "../../../../assets/Svgs/Physiological/Calculator/weather3.svg";
import { ReactComponent as weather4 } from "../../../../assets/Svgs/Physiological/Calculator/weather4.svg";
import { ReactComponent as Exclamation } from "../../../../assets/Svgs/Physiological/Calculator/Exclamation.svg";

import { Option } from "../../../ImageOption";
import { between } from "../utils/validation";
import { CgCloseO } from "react-icons/cg";
import { BiCheckCircle } from "react-icons/bi";
import { fixNumber } from "../utils/fixValue";
import { ToggleSwitch } from "../../../Switch";
import { CalcProps } from "../..";
import {
  Abutton,
  ImageWrapperrrrr,
  TitleP,
  TitlePp,
} from "../Heat load/styles";
import { HeatCalcToolTip } from "../Heat load/HeatCalcToolTip";
import { ConditionModaL } from "../Heat load/condition image";
import { BottomDiv } from "../LiquidCalc/styles";

const Choose = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #b7c7d6;
  border-radius: 1rem;
  padding-top: 1rem;
  width: 95%;
`;

const YanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  svg {
    position: absolute;
    top: 30%;
    left: 10%;
    justify-self: right;
    align-self: right;
    width: 5rem;
    height: 2.8rem;
  }
`;
const Ul = styled.ul`
  padding: 0px 20px 0px 0px;
`;

const Calc = styled.div`
  margin-top: -4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* overflow: scroll; */
  .seperator {
    width: 95%;
    height: 1px;
    margin: 1rem 0;
    background-color: #b7c7d6;
  }
  h5 {
    padding: 0;
    font-size: 16px;
    margin: 0;
    margin-bottom: 2rem;
    /* justify-self: center; */
    text-align: center;
    color: #2e3841;
  }
  .error {
    width: 80vw;
    color: red;
    font-size: medium;
  }
  .note {
    width: 80vw;
    color: #2e3841;
    font-size: medium;
  }
`;

const StyledButton = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 5rem;
  background-color: #3195ed;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: large;
`;

const Results = styled.div`
  width: 90%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
  .result {
    span {
      direction: ltr;
      margin-right: 0.5rem;
    }
    display: flex;
  }
  h4 {
    align-self: center;
  }

  .asd {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
    }
  }
`;
interface ColorWrapProps {
  wrapperColor: string;
}
const ColorWrap = styled.div<ColorWrapProps>`
  border-color: ${(props) => props.wrapperColor};
  border: 0.3rem solid;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
`;

export const ColdnessCalc = ({
  setShouldShow,
  setToolTipMessage,
  scrollFunction,
}: CalcProps) => {
  const values = ["רוח וטמפ'", "עומס קור", `מדד צינת הרוח (WCI)`];
  const [selected, setSelected] = useState(`מדד צינת הרוח (WCI)`);

  const [windSpeed, setWindSpeed] = useState("");
  const [temp, setTemp] = useState("");
  const [wrapperColor, setWrapperColor] = useState("#fcfcfc");

  const [windCold, setWindCold] = useState("");

  const [coldLevel, setColdLevel] = useState("");
  const [icon, setIcon] = useState("");
  useEffect(() => {
    console.log(icon);
  }, [icon]);

  const [activity, setActivity] = useState("אימון");
  const [effort, setEffort] = useState("שהייה ללא מאמץ");
  const [weather, setWeather] = useState("ללא משקעים");

  const [error, setError] = useState("");
  const [isTakingPlace, setIsTakingPlace] = useState("");
  const [limitations, setLimitations] = useState("");
  const [requiredClothing, setRequiredClothing] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [liquidsReturn, setLiquidReturn] = useState("");

  const [isResults, setIsResults] = useState(false);
  const [openImage, setOpenImage] = useState(false);

  const clearForm = () => {
    setIsResults(false);
    setTemp("");
    setWindSpeed("");
    setWindCold("");
    setColdLevel("");
    setActivity("אימון");
    setEffort("שהייה ללא מאמץ");
    setWeather("ללא משקעים");
  };

  const validateFields = () => {
    if (!activity) {
      setError("* יש לבחור אופי פעילות");
    } else if (!effort) {
      setError("* יש לבחור רמת מאמץ");
    } else if (!weather) {
      setError("* יש לבחור משקעים");
    } else if (!coldLevel && !windCold && !(temp && windSpeed)) {
      setError("* יש למלא מהירות רוח וטמפרטורה / מדד צינת הרוח / עומס קור.");
    } else {
      setError("");
      return true;
    }
    return false;
  };

  const validateFieldsValues = () => {
    if (windSpeed) {
      if (!Number(windSpeed)) {
        setError("מהירות הרוח שהוכנסה אינה ערך מספרי.");
        return false;
      } else if (!between(Number(windSpeed), 0, 100)) {
        setError("מהירות הרוח שהוכנסה איננה בטווח הרשום.");
        return false;
      }
    }

    if (temp) {
      if (!Number(temp)) {
        setError("הטמפרטורה שהוכנסה אינה ערך מספרי.");
        return false;
      } else if (!between(Number(temp), -10, 50)) {
        setError("הטמפרטורה שהוכנסה איננה בטווח הרשום.");
        return false;
      }
    }

    if (windCold) {
      if (Number(windCold) != 0 && !Number(windCold)) {
        setError("מדד צינת הרוח שהוכנס אינו ערך מספרי.");
        return false;
      } else if (!between(Number(windCold), -50, 50)) {
        setError("מדד צינת הרוח שהוכנס איננו בטווח הרשום.");
        return false;
      }
    }

    return true;
  };

  const calculate = () => {
    let tmpWindCold = windCold;
    let tmpColdLevel = coldLevel;
    if (windSpeed && temp && !tmpWindCold) {
      tmpWindCold = String(
        0.045 *
          (5.49 * (Number(windSpeed) / 3.6) ** 0.5 -
            0.56 * (Number(windSpeed) / 3.6) +
            5.81) *
          (1.8 * Number(temp) - 59.4) +
          33
      );
    } else if (tmpColdLevel && !tmpWindCold) {
      switch (tmpColdLevel) {
        case "ללא עומס (0)":
          tmpWindCold = "מעל 8";
          break;

        case "קל (1)":
          tmpWindCold = "בין 1 ל-8";
          break;

        case "בינוני (2)":
          tmpWindCold = "בין מינוס 3 ל-1";
          break;

        case "כבד (3)":
          tmpWindCold = "מתחת למינוס 3";
          break;
      }
    }
    if (!tmpColdLevel && tmpWindCold) {
      const numericWindCold = Number(tmpWindCold);
      if (8 <= numericWindCold) {
        tmpColdLevel = "ללא עומס (0)";
      } else if (1 <= numericWindCold && numericWindCold < 8) {
        tmpColdLevel = "קל (1)";
      } else if (-3 <= numericWindCold && numericWindCold < 1) {
        tmpColdLevel = "בינוני (2)";
      } else if (numericWindCold < -3) {
        tmpColdLevel = "כבד (3)";
      }
    }

    if (weather == "שלג" || weather == "ברד") {
      tmpColdLevel = "כבד (3)";
    }
    if (activity == "אימון") {
      if (weather == "גשם") {
        if (tmpColdLevel == "ללא עומס (0)") {
          tmpColdLevel = "קל (1)";
        } else if (tmpColdLevel == "קל (1)") {
          tmpColdLevel = "בינוני (2)";
        } else if (tmpColdLevel == "בינוני (2)") {
          tmpColdLevel = "כבד (3)";
        }
      } else if (
        weather == "שלג" &&
        effort !== "שהייה ללא מאמץ" &&
        effort !== "שינה"
      ) {
        setAdditionalInfo(
          `לפי פ"מ 61.0109 כל אימון בשלג מחייב אישור עקרוני של מפקד מז"י/אלוף מרחבי.`
        );
        setIsTakingPlace(
          `לא ניתן לבצע פעילות, לפי פ"מ 61.0109 כל אימון בשלג מחייב אישור עקרוני של מפקד מז"י/אלוף מרחבי.`
        );
      } else setIsTakingPlace(`ניתן לבצע פעילות`);
      if (weather !== "שלג") {
        setIsTakingPlace((training.isTakingPlace as any)[effort][tmpColdLevel]);
      }
      setRequiredClothing((training.clothing as any)[effort][tmpColdLevel]);
      if (weather !== "ללא משקעים") {
        setLimitations((training.limitations as any)[effort][tmpColdLevel]);
      } else {
        setLimitations(
          (training.limitationsNoRain as any)[effort][tmpColdLevel]
        );
      }
      if (
        weather === "ברד" ||
        weather === "שלג" ||
        (tmpColdLevel === "כבד (3)" && weather === "ללא משקעים") ||
        ((tmpColdLevel === "כבד (3)" || tmpColdLevel === "בינוני (2)") &&
          weather === "גשם")
      ) {
        if (effort !== "שהייה ללא מאמץ" && effort !== "שינה") {
          setIsTakingPlace("לא ניתן לבצע אימון אלא באישור מפקד האוגדה");
          setIcon("red");
          if (weather === "שלג") {
            setLimitations(
              (training.limitationsNoRain as any)[effort][tmpColdLevel]
            );
            setIsTakingPlace(
              `לא ניתן לבצע פעילות אלא באישור מפקד האוגדה, לפי פ"מ 61.0109 כל אימון בשלג מחייב אישור עקרוני של מפקד מז"י/אלוף מרחבי.`
            );
          } else {
            if (
              weather !== "ללא משקעים" &&
              weather !== "ברד" &&
              weather !== "גשם"
            ) {
              setLimitations(
                (training.limitations as any)[effort][tmpColdLevel]
              );
            } else {
              setLimitations(
                (training.limitationsNoRain as any)[effort][tmpColdLevel]
              );
            }
          }
          setRequiredClothing((training.clothing as any)[effort][tmpColdLevel]);
        } else {
          setIsTakingPlace("ניתן לבצע פעילות בהתאם למגבלות");
          if (weather === "שלג") {
            setLimitations((training.limitations as any)[effort][tmpColdLevel]);
            setIsTakingPlace(` ניתן לבצע פעילות בהתאם להגבלות.`);
          } else {
            if (weather !== "ללא משקעים") {
              setLimitations(
                (training.limitations as any)[effort][tmpColdLevel]
              );
            } else {
              setLimitations(
                (training.limitationsNoRain as any)[effort][tmpColdLevel]
              );
            }
          }
          setRequiredClothing((training.clothing as any)[effort][tmpColdLevel]);
        }
        if (weather === "גשם" && tmpColdLevel === "בינוני (2)") {
          if (effort !== "שהייה ללא מאמץ" && effort !== "שינה") {
            setIsTakingPlace("ניתן לבצע פעילות");
            setIcon("green");
          }
        }
      }
    } else if (activity == "מיון פיזי") {
      if (weather == "גשם") {
        if (tmpColdLevel == "ללא עומס (0)") {
          tmpColdLevel = "קל (1)";
        } else if (tmpColdLevel == "קל (1)") {
          tmpColdLevel = "בינוני (2)";
        } else if (tmpColdLevel == "בינוני (2)") {
          tmpColdLevel = "כבד (3)";
        }
      }
      setRequiredClothing((examination.clothing as any)[effort][tmpColdLevel]);

      if (weather === "גשם" && tmpColdLevel === "קל (1)") {
        setIsTakingPlace(
          "ניתן לבצע פעילות עד שעתיים בלבד (לרבות זמני המנוחה במהלך השעתיים), שלאחריהן ישהו חצי שעה באותו אזור מוגן מגשם ורוח שעומס הקור בו הוא 0."
        );
        setLimitations(
          "אין לבצע פעילות נוספת בעומס קור 1 באותה יממה. יש לקחת בחשבון בתכנון הפעילות את זמן המעבר בין תאי השטח באופן שיאפשר הגעה למקום בו עומס הקור הוא אפס בתום השעתיים. יש להפסיק את המיון בכל מצב בו קיימת תחזית לסופת ברקים או ברד באזור הביצוע. יש להפסיק את המיון בכל מצב בו יורד גשם במשך 30 דקות ומעלה. יש להפסיק את המיון בכל מצב בו יורד ברד או שלג. על מפקד המיון לוודא כי מיד עם הפסקת הפעילות תתבצע החלפה מיידית של בגדים, גרביים ונעליים ללבוש יבש וחם. "
        );
        setIcon("green");
        setRequiredClothing(
          "על מפקד המיון לוודא כי ברשותו של כל מועמד במיון יש לבוש ונעליים יבשות להחלפה במקרה הצורך"
        );
      } else if (tmpColdLevel === "קל (1)" && weather !== "גשם") {
        setIsTakingPlace(
          "ניתן לבצע פעילות עד שעתיים בלבד (לרבות זמני המנוחה במהלך השעתיים), שלאחריהן ישהו חצי שעה באותו אזור מוגן מגשם ורוח שעומס הקור בו הוא 0."
        );
        setLimitations(
          "אין לבצע פעילות נוספת בעומס קור 1 באותה יממה. יש לקחת בחשבון בתכנון הפעילות את זמן המעבר בין תאי השטח באופן שיאפשר הגעה למקום בו עומס הקור הוא אפס בתום השעתיים. יש להפסיק את המיון בכל מצב בו קיימת תחזית לסופת ברקים או ברד באזור הביצוע."
        );
        setIcon("green");
        setRequiredClothing(
          "על מפקד המיון לוודא כי ברשותו של כל מועמד במיון יש לבוש ונעליים יבשות להחלפה במקרה הצורך"
        );
      } else if (weather == "גשם" && tmpColdLevel == "קל (1)") {
        setIsTakingPlace("ניתן לבצע פעילות בהתאם למגבלות");
        setLimitations(
          "ניתן לבצע פעילות בתנאים הבאים: ניתן לבצע פעילות גופנית עד שעתיים בלבד (לרבות זמני המנוחה במהלך השעתיים), שלאחריהן ישהו חצי שעה באזור מוגן מגשם ורוח שעומס הקור בו הוא 0 (ללא עומס קור). אין לבצע פעילות נוספת בעומס קור 1 במהלך אותה יממה, יש להפסיק את הפעילות בכל מצב בו יורד גשם מעל חצי שעה."
        );
        setIcon("orange");
      } else {
        setIsTakingPlace(
          (examination.isTakingPlace as any)[effort][tmpColdLevel]
        );

        setLimitations((examination.limitations as any)[effort][tmpColdLevel]);
      }
      // setRequiredClothing((examination.clothing as any)[effort][tmpColdLevel]);
    }
    setLiquidReturn((liquids as any)[effort]["ללא עומס (0)"]);
    setIsResults(true);
    setColdLevel(tmpColdLevel);
    if (
      fixNumber(tmpWindCold, 1) === "0.0" ||
      fixNumber(tmpWindCold, 1) === "NaN"
    ) {
      setWindCold(tmpWindCold);
    } else {
      setWindCold(fixNumber(tmpWindCold, 1));
    }
    console.log("רמת מאמץ: ", effort);
    console.log("מדד צינת הרוח: " + tmpWindCold);
    console.log("עומס קור: " + tmpColdLevel);
  };

  const handleCalc = () => {
    if (validateFields() && validateFieldsValues()) {
      if (weather == "ללא משקעים" && coldLevel == "קל (1)") {
        setIcon("orange");
      }
      calculate();
      scrollFunction();
    }
  };

  return (
    <>
      {isResults && !openImage && (
        <Results>
          <h4>{`הגבלות לפעילות בעומס קור`}</h4>
          <p className="result">
            <b>{`מדד צינת הרוח (WCI) הוא:`} </b>
            <span>{windCold}</span>
          </p>

          <p>
            <b>{`מגבלות בהתאם לדרגת עומס קור:`} </b>
            {coldLevel}
          </p>
          <p className="asd">
            {icon === "red" ? (
              <CgCloseO style={{ width: "5rem", height: "2rem" }} color="red" />
            ) : isTakingPlace === "ניתן לבצע פעילות" ||
              "ניתן לבצע פעילות בהתאם למגבלות" ||
              icon === "green" ? (
              <BiCheckCircle color="green" />
            ) : icon === "orange" ? (
              <Exclamation />
            ) : (
              <CgCloseO style={{ width: "5rem", height: "2rem" }} color="red" />
            )}
            <b>{`${isTakingPlace}`}</b>
          </p>
          <ColorWrap wrapperColor={wrapperColor}>
            <p>
              <b>{`הגבלות:`} </b>
              {weather === "גשם" &&
              coldLevel === "קל (1)" &&
              activity === "מיון פיזי"
                ? sortLimitatiom
                : limitations}
              {/* {limitations} */}
            </p>

            <p>
              <b>{`הערות:`} </b>
              {activity === "מיון פיזי" &&
              coldLevel === "ללא עומס (0)" &&
              weather === "ללא משקעים"
                ? detailsList
                : activity === "מיון פיזי"
                ? detailsList2
                : activity === "אימון" &&
                  coldLevel === "כבד (3)" &&
                  weather === "שלג" &&
                  (effort === "שהייה ללא מאמץ" || effort === "שינה")
                ? `יש לבצע מדידה של עומס קור בכל שעה. לא ניתן לבצע מאמץ אלא באישור מפקד האוגדה. לפי פ"מ 61.0109 כל אימון בשלג מחייב אישור עקרוני של מפקד מז"י/אלוף מרחבי`
                : activity === "אימון" &&
                  coldLevel === "כבד (3)" &&
                  (effort === "שהייה ללא מאמץ" || effort === "שינה")
                ? "יש לבצע מדידה של עומס קור בכל שעה. לא ניתן לבצע מאמץ אלא באישור מפקד האוגדה"
                : "יש לבצע מדידה של עומס קור בכל שעה."}
              <p>
                {coldLevel === "ללא עומס (0)" && activity !== "מיון פיזי"
                  ? "אם טמפרטורת הסביבה היא 26 מעלות ומעלה, יש למדוד עומס חום."
                  : ""}
              </p>
            </p>

            {activity !== "מיון פיזי" && (
              <p>
                <b>{`לבוש נדרש:`} </b>
                {requiredClothing}
              </p>
            )}
            {activity === "מיון פיזי" && (
              <p>
                <b>{`לבוש נדרש:`} </b>
                {switchClothes}
              </p>
            )}

            {isTakingPlace === "ניתן לבצע פעילות" && (
              <>
                <p>
                  <b>{`החזר נוזלים: `} </b>
                  {`${liquidsReturn} מ"ל (${
                    Number(liquidsReturn) / 1000
                  } ליטר) מים לשעה.`}
                </p>
                <p
                  className={"note"}
                >{`* תקף לאדם השוקל 70 ק"ג ביום שמשי. לחישוב מדוייק אנא גש למחשבון נוזלים.`}</p>
              </>
            )}

            {/* {additionalInfo && (
              <p>
                <b>{`מידע נוסף:`} </b>
                {additionalInfo}
              </p>
            )} */}
          </ColorWrap>
          <StyledButton onClick={clearForm}>{"לחישוב מחדש"}</StyledButton>
          <BottomDiv />
        </Results>
      )}
      {!isResults && !openImage && (
        <Calc>
          <TitleP>
            המידע המוצג ביישומון זה נועד למטרות תכנון ואינו מחליף מדידה בשטח
            ביצוע הפעילות, בהתאם לפקודות. עומס הקור הקובע הינו זה שנמדד בסביבה
            בה מבוצעת הפעילות (רכב, רק"מ, עמדה וכו')
          </TitleP>
          <HeatCalcToolTip
            text={`הממשק הינו תמצית של הורא"ב כרך א', חלק ב' הוראה 1.1 והוראת קחצ"ר מס' 2, 1 ו-4 אך אינה באה להחליפן. אימון בבגדי מגן פחמי ומסיכות אב"כ יבוצע ע"פ המפורט בהורא"ב חה"ן 9.1`}
            top={0.4}
            right={92}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          >
            <div></div>
          </HeatCalcToolTip>

          <Abutton
            onClick={() => {
              setOpenImage(true);
              setIsResults(false);
            }}
          >
            לחץ למדידה נכונה של תנאי אקלים
          </Abutton>
          <ToggleSwitch
            width="7rem"
            fontsize="12px"
            values={values}
            selected={selected}
            setSelected={setSelected}
          />
          {selected === `מדד צינת הרוח (WCI)` && (
            <YanWrapper>
              <KestrelCold />

              <CalcInput
                placeholder="לדוגמה - 25"
                value={windCold}
                setValue={setWindCold}
                topText={`ערך הנמדד בקסטרל (מדד צינת הרוח (WCI))`}
                bottomText={"טווח : מינוס 50 עד 50"}
                disabled={!!coldLevel || !!windSpeed || !!temp ? true : false}
                fontsize="14px"
              />
            </YanWrapper>
          )}
          {/* <div className="seperator"></div> */}
          {selected === "עומס קור" && (
            <>
              {" "}
              <ImageSelect
                title={"דרגת עומס קור"}
                options={tempOptions}
                setSelected={setColdLevel}
                selected={coldLevel}
                disabled={!!windCold || !!windSpeed || !!temp ? true : false}
                setShouldShow={setShouldShow}
                setToolTipMessage={setToolTipMessage}
              />
              {coldLevel == "ללא עומס (0)" && (
                <p>*אם טמפרטורת הסביבה היא 26°C ומעלה, יש למדוד עומס חום.</p>
              )}
            </>
          )}
          {selected === "רוח וטמפ'" && (
            <>
              <CalcInput
                placeholder="לדוגמה - 60"
                value={windSpeed}
                setValue={setWindSpeed}
                topText={'מהירות רוח (קמ"ש)'}
                bottomText={"טווח: 0 עד 100"}
                disabled={!!windCold || !!coldLevel ? true : false}
              />

              <CalcInput
                placeholder="לדוגמה - 25"
                value={temp}
                setValue={setTemp}
                topText={"טמפרטורה (°C)"}
                bottomText={"טווח : מינוס 10 עד 50"}
                disabled={!!windCold || !!coldLevel ? true : false}
              />
            </>
          )}

          <ImageSelect
            title={"אופי פעילות"}
            options={activityOptions}
            setSelected={setActivity}
            selected={activity}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          />

          <ImageSelect
            title={"סוג הפעילות והמאמצים"}
            options={
              activity == "מיון פיזי"
                ? effortOptions.filter((e) => e.text != "שינה")
                : effortOptions
            }
            setSelected={setEffort}
            selected={effort}
            isExam={activity == "מיון פיזי" ? true : false}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          />

          <ImageSelect
            title={"משקעים"}
            options={weatherOptions}
            setSelected={setWeather}
            selected={weather}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          />
          {(weather == "שלג" || weather == "ברד") && (
            <p className="note">
              {
                "*שימו לב- דרגת עומס הקור שבחרתם יכולה לעלות כתוצאה מבחירת משקעים."
              }
            </p>
          )}
          {error && <p className="error">{error}</p>}
          <StyledButton
            onClick={() => {
              handleCalc();
            }}
          >
            {"חישוב"}
          </StyledButton>
          <BottomDiv />
        </Calc>
      )}
      {openImage ? (
        <ImageWrapperrrrr>
          <StyledButton onClick={() => setOpenImage(false)}>חזור</StyledButton>
          <ConditionModaL />
          <BottomDiv />
        </ImageWrapperrrrr>
      ) : (
        ""
      )}
    </>
  );
};

const tempOptions: Option[] = [
  {
    Img: Temp4,
    text: "ללא עומס (0)",
    textt: "8 ומעלה",
    isSelected: false,
    color: "#8bfaf6",
  },
  {
    Img: Temp3,
    text: "קל (1)",
    textt: "1-8",

    isSelected: false,
    color: "#18D1CC",
  },
  {
    Img: Temp2,
    text: "בינוני (2)",
    textt: "3- עד 1",

    isSelected: false,
    color: "#2799B2",
  },
  {
    Img: Temp1,
    text: "כבד (3)",
    textt: "3- ומטה",
    isSelected: false,
    color: "#065E71",
  },
];

const activityOptions: Option[] = [
  {
    Img: Sort,
    text: "מיון פיזי",
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: Exercise,
    text: "אימון",
    isSelected: false,
    color: "#365ABA",
  },
];

const effortOptions: Option[] = [
  {
    Img: Effort5,
    text: "שינה",
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: Effort4,
    text: "שהייה ללא מאמץ",
    explanationExam: "תרגיל חשיבה במנוחה.",
    explanation:
      "- עמדה\n\
    - רכב\n\
    - הסתתרות\n\
    - שכיבה על אלונקה\n\
    וכו`",
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: Effort3,
    text: "מאמץ קל",
    explanationExam:
      "- הליכה עד למרחק של 500 מטר רצוף ובקצב של עד 4 קמ\"ש (כ-8 דקות ל500 מטר), ולא מעל 2 ק\"מ ביממה, על בגדי ב' או בגדי ספורט ללא משקל נוסף.\n\
    - תרגילים טכניים הכוללים מאמץ גופני קל, כגון: פירוק והרכבת נשק וכדו'.",
    explanation:
      '- מטווחים נייחים.\n\
    - הליכה מעל 500 מטר ולא יותר מ2000 מטר ליום (לרבות אפוד ונשק).\n\
    - תרגול טכני (פרוק והרכבת נשק קל, קשר, ע"ר, וכו`).\n\
    - אימונים ע"ג רכב ורק"מ (ללא פעילות רגלית).',
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: Effort2,
    text: "מאמץ בינוני",
    explanationExam:
      '- תרגול נשיאת תד"ל, מנשא ואלונקה. \n\
    - תרגילים טכניים הכולים מאמץ גופני, כגון: חפירות, הקמת מאהל, פק"לי כוח וכדו\'.',
    explanation:
      '- תרגילים \\ אימונים רגליים.\n\
    - מסע רגיל\\תנועה רגלית (עד 6 קמ"ש במישור עם אפוד, נשק ואגמ"ק) עד 30 ק"מ משוקלל.\n\
    - ניווטים.\n\
    - מטווחים בתנועה או לאחר מאמץ.\n\
    - אימוני כוח וקרב מגע בסיסי.\n\
    - ריצה קצב קל.',
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: Effort1,
    text: "מאמץ קשה",
    explanationExam:
      "- מסע, ריצה, בוחן כש\"ג, זחילות, שקים, סוציומטרי אלונקה ונשיאת משקל, הליכה עם קורה, הליכה עם אוהל וכדו'",
    explanation:
      '- תרגילים \\ אימונים רגליים.\n\
    - תנועה רגלית בתנאי שטח קשים.\n\
    - מסע רגיל\\פק"לים ותנועה רגלית מעל 30 ק"מ משוקלל.\n\
    - מסע מזורז (מעל 6 קמ"ש).\n\
    - מסע אלונקות.\n\
    - קרב מגע מתקדם.\n\
    - ריצות קצב\\מרחק.',
    isSelected: false,
    color: "#365ABA",
  },
];

const weatherOptions: Option[] = [
  {
    Img: weather4,
    text: "ללא משקעים",
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: weather3,
    text: "גשם",
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: weather2,
    text: "ברד",
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: weather1,
    text: "שלג",
    isSelected: false,
    color: "#365ABA",
  },
];

const listA = (
  <div>
    <Ul>
      <li>מכנס תחתון וגופיה תחתונה</li>
      <li>מדי עבודה (מדי ב')</li>
      <li>מעיל פליז</li>
      <li>חרמונית (סרבל מרופד) או מעיל ומכנס מרופדים (מעיל ומכנס דובון) </li>
      <li> שקיות חימום (לפי הנחיית קרפ"ר)</li>
      <li>כובע</li>
      <li>כפפות</li>
      <li>גרביים</li>
      <li>שק"ש</li>
      <li>מזרן מבודד מהרצפה (מזרן מארבים) במקרה של מגע עם הקרקע</li>
    </Ul>
    <h4>רשימה א' מפקודת קרפ"ר 602.005.01</h4>
  </div>
);
const listB = (
  <div>
    <Ul>
      <li>מכנס תחתון וגופיה תחתונה</li>
      <li>מדי עבודה (מדי ב')</li>
      <li>מעיל פליז</li>
      <li>אם יש גשם/צפי לגשם - מעיל ומכנס סערה (חלפ"ס)</li>
      <li>כובע</li>
      <li>כפפות</li>
      <li>גרביים</li>
      <li>נעליים צבאיות</li>
      <li>זוג מדים יבשים</li>
      <li>מכנס, גופייה, תחתונים וגרביים יבשים</li>
      <li>שק"ש</li>
      <li> מזרן מבודד מהרצפה (מזרן מארבים) במקרה של מגע עם הקרקע</li>
    </Ul>
    <h4>רשימה ב' מפקודת קרפ"ר 602.005.01</h4>
  </div>
);

const listC = (
  <div>
    <Ul>
      <li>מכנס תחתון וגופיה תחתונה</li>
      <li>מדי עבודה (מדי ב')</li>
      <li>מעיל פליז</li>
      <li>אם יש גשם/צפי לגשם - מעיל ומכנס סערה (חלפ"ס)</li>
      <li>כובע</li>
      <li>כפפות</li>
      <li>גרביים</li>
      <li>נעליים קנדיות (באימון של עד שעתיים רצוף לא חובה)</li>
      <li>זוג מדים יבשים</li>
      <li>מכנס, גופייה, תחתונים וגרביים יבשים</li>
      <li>שק"ש</li>
      <li> מזרן מבודד מהרצפה (מזרן מארבים) במקרה של מגע עם הקרקע</li>
      <li>חרמונית (סרבל מרופד) או מעיל ומכנס מרופדים (מעיל ומכנס דובון)</li>
      <li>שקיות חימום (לפי הנחיית קרפ"ר)</li>
    </Ul>
    <h4>רשימה ג' מפקודת קרפ"ר 602.005.01</h4>
  </div>
);

const listD = (
  <div>
    <h4>רשימה ד' מפקודת קרפ"ר 602.005.01</h4>

    <Ul>
      <li> מכנס תחתון וגופיה תחתונה (עדיף טרמקס)</li>
      <li>מדי עבודה (מדי ב')</li>
      <li>גרביים ונעליים צבאיות</li>
      <li>מכנס, גופייה, תחתונים וגרביים יבשים</li>
      <li>זוג מדים יבשים</li>
    </Ul>
  </div>
);
const limitationList = (
  <div>
    <Ul>
      <li> כל חייל יצויד בלבוש המפורט ברשימה ג'</li>
      <li>אין לבצע שהייה בביגוד רטוב של יותר מ-15 דקות.</li>
      <li>
        לאחר כל שעתיים שהייה בתנאי קור (או לאחר כל 3 שעות במידה ויש נעליים
        קנדיות) תבוצע הפסקה בת 30 דקות לפחות במקום מחומם ומוגן מרוח ומגשם ותינתן
        שתייה חמה.
      </li>
    </Ul>
  </div>
);
const limitationList2 = (
  <div>
    <Ul>
      <li>בהינתן אישור, כל חייל יצויד בלבוש ברשימה ד'.</li>
      <li>
        לאחר כל 60 דקות פעילות תינתן לכל חייל הפסקה של 10 דקות בה תינתן שתייה
        חמה.
      </li>
      <li>
        מיד בסיום האימון יוחלף הביגוד לביגוד יבש. אין לבצע שהייה בבגדים רטובים
        יותר מ-15 דקות.
      </li>
    </Ul>
  </div>
);
const limitationList3 = (
  <div>
    <Ul>
      <li>כל חייל יצויד בלבוש המפורט ברשימה ג'.</li>
      <li>אין לבצע שהייה בביגוד רטוב יותר מ-15 דקות.</li>
    </Ul>
  </div>
);
const limitationList4 = (
  <div>
    <Ul>
      <li>כל חייל יצויד בלבוש המפורט ברשימה ד'. </li>
      <li>
        לאחר כל 60 דקות פעילות תינתן לכל חייל הפסקה של 10 דקות בה תינתן שתייה
        חמה.{" "}
      </li>
      <li>
        מיד בסיום האימון יוחלף הביגוד לביגוד יבש. אין לבצע שהייה בביגוד רטוב של
        יותר מ-15 דקות.
      </li>
    </Ul>
  </div>
);
const detailsList = (
  <div>
    <Ul>
      <li>
        באחריות מפקד האימון לוודא מדידת תנאי עומס קור ותיעודם כל 60 דקות החל
        משעתיים לפני תחילת המיון ובמהלכו.
      </li>
      <li>
        באחריות מפקד המיון לקבל את תחזית מזג אוויר ותנאי עומס קור ערב תחילת
        המיון מחמ"ל הרפואה הפיקודי. במקרה של הבדל בין תחזית עומס הקור למדידה
        בשטח בו מתבצע המיון, תכריע המדידה בשטח.
      </li>
      <li> אם טמפרטורת הסביבה היא 26 מעלות ומעלה, יש למדוד עומס חום</li>
    </Ul>
  </div>
);
const detailsList2 = (
  <div>
    <Ul>
      <li>
        באחריות מפקד האימון לוודא מדידת תנאי עומס קור ותיעודם כל 60 דקות החל
        משעתיים לפני תחילת המיון ובמהלכו.
      </li>
      <li>
        באחריות מפקד המיון לקבל את תחזית מזג אוויר ותנאי עומס קור ערב תחילת
        המיון מחמ"ל הרפואה הפיקודי. במקרה של הבדל בין תחזית עומס הקור למדידה
        בשטח בו מתבצע המיון, תכריע המדידה בשטח.
      </li>
    </Ul>
  </div>
);
const sortLimitatiom = (
  <div>
    <Ul>
      <li>אין לבצע פעילות נוספת בעומס קור 1 באותה יממה.</li>
      <li>
        יש לקחת בחשבון בתכנון הפעילות את זמן המעבר בין תאי השטח באופן שיאפשר
        הגעה למקום בו עומס הקור הוא אפס בתום השעתיים.
      </li>
      <li>
        יש להפסיק את המיון בכל מצב בו קיימת תחזית לסופת ברקים או ברד באזור
        הביצוע. יש להפסיק את המיון בכל מצב בו יורד גשם במשך 30 דקות ומעלה. יש
        להפסיק את המיון בכל מצב בו יורד ברד או שלג.
      </li>
      <li>
        על מפקד המיון לוודא כי מיד עם הפסקת הפעילות תתבצע החלפה מיידית של בגדים,
        גרביים ונעליים ללבוש יבש וחם.{" "}
      </li>
    </Ul>
  </div>
);

const ListCandD = (
  <div>
    <h4>רשימה ד' מפקודת קרפ"ר 602.005.01</h4>
    <ul>
      <li>מכנס תחתון וגופיה תחתונה</li>
      <li>מדי עבודה (מדי ב')</li>
      <li>מעיל פליז</li>
      <li>אם יש גשם/צפי לגשם - מעיל ומכנס סערה (חלפ"ס)</li>
      <li>כובע</li>
      <li>כפפות</li>
      <li>גרביים</li>
      <li>נעליים קנדיות (באימון של עד שעתיים רצוף לא חובה)</li>
      <li>זוג מדים יבשים</li>
      <li>מכנס, גופייה, תחתונים וגרביים יבשים</li>
      <li>שק"ש</li>
      <li> מזרן מבודד מהרצפה (מזרן מארבים) במקרה של מגע עם הקרקע</li>
      <li>חרמונית (סרבל מרופד) או מעיל ומכנס מרופדים (מעיל ומכנס דובון)</li>
      <li>שקיות חימום (לפי הנחיית קרפ"ר)</li>
    </ul>
    <h4>רשימה ג' מפקודת קרפ"ר 602.005.01</h4>

    <ul>
      <li> מכנס תחתון וגופיה תחתונה (עדיף טרמקס)</li>
      <li>מדי עבודה (מדי ב')</li>
      <li>גרביים ונעליים צבאיות</li>
      <li>מכנס, גופייה, תחתונים וגרביים יבשים</li>
      <li>זוג מדים יבשים</li>
    </ul>
  </div>
);

const training = {
  isTakingPlace: {
    שינה: {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "בינוני (2)": "ניתן לבצע פעילות",
      "כבד (3)": "ניתן לבצע פעילות בהתאם למגבלות",
    },
    "שהייה ללא מאמץ": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "בינוני (2)": "ניתן לבצע פעילות",
      "כבד (3)": "ניתן לבצע פעילות בהתאם למגבלות",
    },
    "מאמץ קל": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "בינוני (2)": "ניתן לבצע פעילות",
      "כבד (3)": "לא ניתן לבצע פעילות",
    },
    "מאמץ בינוני": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "בינוני (2)": "ניתן לבצע פעילות",
      "כבד (3)": "לא ניתן לבצע פעילות",
    },
    "מאמץ קשה": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "בינוני (2)": "ניתן לבצע פעילות",
      "כבד (3)": "לא ניתן לבצע פעילות",
    },
  },

  limitationsNoRain: {
    שינה: {
      "ללא עומס (0)": `תתבצע בתוך שק"ש כאשר החייל לבוש עם ביגוד תחתון ארוך ומדי ב'.
`,
      "קל (1)": "כל חייל יצויד בלבוש עפ\"י רשימה א' ויישן בתוך אוהל או מבנה.",
      "בינוני (2)":
        "כל חייל יצויד בלבוש עפ\"י רשימה א' ויישן בתוך אוהל/מבנה שיש בו אמצעי חימום פעיל ותקין.",
      "כבד (3)":
        "כל חייל יצויד בלבוש עפ\"י רשימה א' ויישן בתוך אוהל/מבנה שיש בו אמצעי חימום פעיל ותקין.",
    },
    "שהייה ללא מאמץ": {
      "ללא עומס (0)": "כל חייל יצויד בלבוש המפורט ברשימה ב'.",
      "קל (1)": limitationList,
      "בינוני (2)": limitationList,
      "כבד (3)": limitationList,
    },

    "מאמץ קל": {
      "ללא עומס (0)":
        "כל חייל יצויד בלבוש המפורט ברשימה ד'.אין הגבלת פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים.",
      "קל (1)": limitationList3,
      "בינוני (2)": limitationList3,
      "כבד (3)": limitationList2,
    },

    "מאמץ בינוני": {
      "ללא עומס (0)":
        "כל חייל יצויד בלבוש המפורט ברשימה ד'.אין הגבלת פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים.",
      "קל (1)": limitationList4,
      "בינוני (2)": limitationList4,
      "כבד (3)": limitationList2,
    },

    "מאמץ קשה": {
      "ללא עומס (0)":
        "כל חייל יצויד בלבוש המפורט ברשימה ד'.אין הגבלת פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים.",
      "קל (1)": limitationList4,
      "בינוני (2)": limitationList4,
      "כבד (3)": limitationList2,
    },
  },
  limitations: {
    שינה: {
      "ללא עומס (0)": `תתבצע בתוך שק"ש כאשר החייל לבוש עם ביגוד תחתון ארוך ומדי ב'.
`,
      "קל (1)": "כל חייל יצויד בלבוש עפ\"י רשימה א' ויישן בתוך אוהל או מבנה.",
      "בינוני (2)":
        "כל חייל יצויד בלבוש עפ\"י רשימה א' ויישן בתוך אוהל/מבנה שיש בו אמצעי חימום פעיל ותקין.",
      "כבד (3)":
        "כל חייל יצויד בלבוש עפ\"י רשימה א' ויישן בתוך אוהל/מבנה שיש בו אמצעי חימום פעיל ותקין.",
    },
    "שהייה ללא מאמץ": {
      "ללא עומס (0)": "כל חייל יצויד בלבוש המפורט ברשימה ב'.",
      "קל (1)": limitationList,
      "בינוני (2)": limitationList,
      "כבד (3)": limitationList,
    },

    "מאמץ קל": {
      "ללא עומס (0)":
        "כל חייל יצויד בלבוש המפורט ברשימה ד'.אין הגבלת פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים.",
      "קל (1)":
        "כל חייל יצויד בלבוש המפורט ברשימה ג', אין לבצע שהייה בביגוד רטוב של יותר מ-15 דקות.",
      "בינוני (2)":
        "כל חייל יצויד בלבוש המפורט ברשימה ג', אין לבצע שהייה בביגוד רטוב של יותר מ-15 דקות.",
      "כבד (3)": `פעילות מותנית באישור מפקד האוגדה. בהינתן אישור, נדרש לבצע פעילות בהתאם לפירוט ברשימה ד'. במידה ואין אישור של מפקד האוגדה, נדרש לבצע פעילות שהייה ללא מאמץ (מומלץ לבצע חישוב מחדש בהתאם לתנאים הרלוונטים) בהתאם לפירוט ברשימה ג'.`,
    },

    "מאמץ בינוני": {
      "ללא עומס (0)":
        "כל חייל יצויד בלבוש המפורט ברשימה ד'.אין הגבלת פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים.",
      "קל (1)": limitationList4,
      "בינוני (2)": limitationList4,
      "כבד (3)": `פעילות מותנית באישור מפקד האוגדה. בהינתן אישור, נדרש לבצע פעילות בהתאם לפירוט ברשימה ד'. במידה ואין אישור של מפקד האוגדה, נדרש לבצע פעילות שהייה ללא מאמץ (מומלץ לבצע חישוב מחדש בהתאם לתנאים הרלוונטים) בהתאם לפירוט ברשימה ג'.`,
    },

    "מאמץ קשה": {
      "ללא עומס (0)":
        "כל חייל יצויד בלבוש המפורט ברשימה ד'.אין הגבלת פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים.",
      "קל (1)":
        "כל חייל יצויד בלבוש המפורט ברשימה ד'.לאחר כל 60 דקות פעילות תינתן לכל חייל הפסקה של 10 דקות בה תינתן שתייה חמה. מיד בסיום האימון יוחלף הביגוד לביגוד יבש. אין לבצע שהייה בביגוד רטוב של יותר מ-15 דקות.",
      "בינוני (2)":
        "כל חייל יצויד בלבוש המפורט ברשימה ד'.לאחר כל 60 דקות פעילות תינתן לכל חייל הפסקה של 10 דקות בה תינתן שתייה חמה. מיד בסיום האימון יוחלף הביגוד לביגוד יבש. אין לבצע שהייה בביגוד רטוב של יותר מ-15 דקות.",
      "כבד (3)": `פעילות מותנית באישור מפקד האוגדה. בהינתן אישור, נדרש לבצע פעילות בהתאם לפירוט ברשימה ד'. במידה ואין אישור של מפקד האוגדה, נדרש לבצע פעילות שהייה ללא מאמץ (מומלץ לבצע חישוב מחדש בהתאם לתנאים הרלוונטים) בהתאם לפירוט ברשימה ג'.`,
    },
  },

  clothing: {
    שינה: {
      "ללא עומס (0)": "אין",
      "קל (1)": listA,
      "בינוני (2)": listA,
      "כבד (3)": listA,
    },
    "שהייה ללא מאמץ": {
      "ללא עומס (0)": listB,
      "קל (1)": listC,
      "בינוני (2)": listC,
      "כבד (3)": listC,
    },
    "מאמץ קל": {
      "ללא עומס (0)": listD,
      "קל (1)": listC,
      "בינוני (2)": listC,
      "כבד (3)": listD,
    },
    "מאמץ בינוני": {
      "ללא עומס (0)": listD,
      "קל (1)": listD,
      "בינוני (2)": listD,
      "כבד (3)": listD,
    },
    "מאמץ קשה": {
      "ללא עומס (0)": listD,
      "קל (1)": listD,
      "בינוני (2)": listD,
      "כבד (3)": listD,
    },
  },
};

const oneStar =
  "אין לבצע כל פעילות בתנאי רטיבות (שהיה במים, כניסה ויציאה ממים, פעילות בבגדים רטובים מכל סיבה שהיא). ניתן לבצע פעילות גופנית עד שעתיים בלבד (לרבות זמני המנוחה במהלך השעתיים, שלאחריהן ישהו חצי שעה באיזור מוגן מגשם ורוח שעומס הקור בו הוא 0. אין לבצע פעילות נוספת בעומס קור 1 במהלך אותה יממה. יש לקחת בחשבון בתכנון הפעילות את זמן המעבר בין תנאי השטח באופן שיאפשר הגעה למקום בו עומס הקור הוא אפס בתום השעתיים). ";

const twoStars =
  "יש להפסיק את פעילות המיון במצב בו קיימת תחזית לסופת ברקים או ברד באזור ביצוע המיון.";

const switchClothes =
  "על מפקד המיון לוודא כי ברשותו של כל מועמד במיון יש לבוש ונעליים יבשות להחלפה במקרה הצורך.";

const examination = {
  isTakingPlace: {
    שינה: {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "ניתן לבצע פעילות",
      "בינוני (2)": "לא ניתן לבצע פעילות",
      "כבד (3)": "לא ניתן לבצע פעילות",
    },
    "שהייה ללא מאמץ": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "לא ניתן לבצע פעילות",
      "בינוני (2)": "לא ניתן לבצע פעילות",
      "כבד (3)": "לא ניתן לבצע פעילות",
    },
    "מאמץ קל": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "לא ניתן לבצע פעילות",
      "בינוני (2)": "לא ניתן לבצע פעילות",
      "כבד (3)": "לא ניתן לבצע פעילות",
    },
    "מאמץ בינוני": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "לא ניתן לבצע פעילות",
      "בינוני (2)": "לא ניתן לבצע פעילות",
      "כבד (3)": "לא ניתן לבצע פעילות",
    },
    "מאמץ קשה": {
      "ללא עומס (0)": "ניתן לבצע פעילות",
      "קל (1)": "לא ניתן לבצע פעילות",
      "בינוני (2)": "לא ניתן לבצע פעילות",
      "כבד (3)": "לא ניתן לבצע פעילות",
    },
  },

  limitations: {
    שינה: {
      "ללא עומס (0)": `שינה בתוך שק"ש. אין הגבלת פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים.`,
      "קל (1)": `שינה בתוך אוהל או מבנה. אין הגבלת פעילות, מעבר למתחייב מהוראות הבטיחות באימונים בתנאי אקלים רגילים.`,
      "בינוני (2)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
      "כבד (3)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
    },

    "שהייה ללא מאמץ": {
      "ללא עומס (0)": twoStars,
      "קל (1)": `יש להפסיק את פעילות המיון. סייג – ניתן לבצע פעילות גופנית עד שעתיים בלבד (לרבות זמני המנוחה במהלך השעתיים), שלאחריהן ישהו חצי שעה באזור מוגן מגשם ורוח שעומס הקור בו הוא 0 (ללא עומס קור). אין לבצע פעילות נוספת בעומס קור 1 במהלך אותה יממה. יש לוודא החלפה ללבוש חם ויבש מיד עם הפסקת הפעילות.`,
      "בינוני (2)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
      "כבד (3)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
    },

    "מאמץ קל": {
      "ללא עומס (0)": twoStars,
      "קל (1)": `יש להפסיק את פעילות המיון. סייג – ניתן לבצע פעילות גופנית עד שעתיים בלבד (לרבות זמני המנוחה במהלך השעתיים), שלאחריהן ישהו חצי שעה באזור מוגן מגשם ורוח שעומס הקור בו הוא 0 (ללא עומס קור). אין לבצע פעילות נוספת בעומס קור 1 במהלך אותה יממה. יש לוודא החלפה ללבוש חם ויבש מיד עם הפסקת הפעילות`,
      "בינוני (2)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
      "כבד (3)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
    },

    "מאמץ בינוני": {
      "ללא עומס (0)": twoStars,
      "קל (1)": `יש להפסיק את פעילות המיון. סייג – ניתן לבצע פעילות גופנית עד שעתיים בלבד (לרבות זמני המנוחה במהלך השעתיים), שלאחריהן ישהו חצי שעה באזור מוגן מגשם ורוח שעומס הקור בו הוא 0 (ללא עומס קור). אין לבצע פעילות נוספת בעומס קור 1 במהלך אותה יממה. יש לוודא החלפה ללבוש חם ויבש מיד עם הפסקת הפעילות`,

      "בינוני (2)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
      "כבד (3)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
    },

    "מאמץ קשה": {
      "ללא עומס (0)": twoStars,
      "קל (1)": `יש להפסיק את פעילות המיון. סייג – ניתן לבצע פעילות גופנית עד שעתיים בלבד (לרבות זמני המנוחה במהלך השעתיים), שלאחריהן ישהו חצי שעה באזור מוגן מגשם ורוח שעומס הקור בו הוא 0 (ללא עומס קור). אין לבצע פעילות נוספת בעומס קור 1 במהלך אותה יממה. יש לוודא החלפה ללבוש חם ויבש מיד עם הפסקת הפעילות`,
      "בינוני (2)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
      "כבד (3)":
        "לא ניתן לבצע פעילות בהתאם להוראת קחצ''ר לביצוע מיונים פיזיים ולכתיבת תיק מיון.",
    },
  },

  clothing: {
    שינה: {
      "ללא עומס (0)": listA + switchClothes,
      "קל (1)": listA + switchClothes,
      "בינוני (2)": "אין לבצע אימונים.",
      "כבד (3)": "אין לבצע אימונים.",
    },
    "שהייה ללא מאמץ": {
      "ללא עומס (0)": listB + switchClothes,
      "קל (1)": listC + switchClothes,
      "בינוני (2)": "אין לבצע אימונים.",
      "כבד (3)": "אין לבצע אימונים.",
    },
    "מאמץ קל": {
      "ללא עומס (0)": listD + switchClothes,
      "קל (1)": listC + switchClothes,
      "בינוני (2)": "אין לבצע אימונים.",
      "כבד (3)": "אין לבצע אימונים.",
    },
    "מאמץ בינוני": {
      "ללא עומס (0)": listD + switchClothes,
      "קל (1)": listD + switchClothes,
      "בינוני (2)": "אין לבצע אימונים.",
      "כבד (3)": "אין לבצע אימונים.",
    },
    "מאמץ קשה": {
      "ללא עומס (0)": listD + switchClothes,
      "קל (1)": listD + switchClothes,
      "בינוני (2)": "אין לבצע אימונים.",
      "כבד (3)": "אין לבצע אימונים.",
    },
  },
};

const liquids = {
  שינה: {
    "ללא עומס (0)": 0,
  },
  "שהייה ללא מאמץ": {
    "ללא עומס (0)": 50,
  },
  "מאמץ קל": {
    "ללא עומס (0)": 250,
  },
  "מאמץ בינוני": {
    "ללא עומס (0)": 500,
  },
  "מאמץ קשה": {
    "ללא עומס (0)": 750,
  },
};
