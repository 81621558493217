import axios from "axios";
import React, { Dispatch, useState } from "react";
import { useRecoilValue } from "recoil";
import { BASE_URL } from "../../../api/constants";
import trashB from "../../../assets/Svgs/trashB.svg";
import trashY from "../../../assets/Svgs/trashY.svg";
import { userInfo } from "../../../store/userInfo";
import { TreeNodeType } from "../../TreeNode";

interface IProps {
  setText: Dispatch<React.SetStateAction<string>>;
  shortName: string;
  node: TreeNodeType;
  refreshParent?: Function;
}

export const WarFileDelete = ({
  setText,
  shortName,
  node,
  refreshParent,
}: IProps) => {
  const [trashLogo, setTrashLogo] = useState(trashB);
  const user = useRecoilValue(userInfo);

  return (
    <button
      onTouchStart={() => setTrashLogo(trashY)}
      onMouseDown={() => setTrashLogo(trashY)}
      onMouseUp={() => setTrashLogo(trashB)}
      onTouchEnd={() => setTrashLogo(trashB)}
      onClick={() => {
        setText("טוען..");
        return axios
          .post(
            `${BASE_URL}/warTree/delete`,
            { node },
            {
              headers: {
                id: user.user.userPrincipalName,
              },
              withCredentials: true,
            }
          )
          .then((res) => {
            setText("הקובץ נמחק.");
            refreshParent && refreshParent();
            return res.data;
          })
          .catch((err) => {
            console.log(err);
            setText("המערכת לא הצליחה למחוק את הקובץ.");
            return;
          });
      }}
    >
      <img className={"star"} src={trashLogo} alt={"מועדפים"} />
    </button>
  );
};
