import { Escape, PopupWrapper, Wrapper } from "./styles";

interface IProps {
  children: React.ReactNode;
  setMarkup: React.Dispatch<React.SetStateAction<string>>;
}

export const Popup = ({ children, setMarkup }: IProps) => {
  return (
    <Wrapper>
      <PopupWrapper>
        <Escape onClick={() => setMarkup("")}>X</Escape>
        {children}
      </PopupWrapper>
    </Wrapper>
  );
};
