import { ChangeEvent } from "react";
import { FieldWrapper, Span } from "../styles";
import {
  DateAndTime,
  DateInputt,
  FieldNameDate,
  FieldWrapperDate,
  Time,
} from "./styles";

interface IProps {
  DateValue: string;
  TimeValue: string;
  DateOnChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  TimeOnChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}
export const DateAndTimeField = ({
  DateOnChange,
  DateValue,
  TimeOnChange,
  TimeValue,
}: IProps) => {
  const now = new Date();
  const currentTime = now.getHours() + ":" + now.getMinutes();
  var today, dd, mm, yyyy;
  today = new Date();
  dd = today.getDate();
  mm = today.getMonth() + 1;
  yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  const currentDate = yyyy + "-" + mm + "-" + dd;

  const DisableDates = () => {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = today.getDate();
    mm = today.getMonth() + 1;
    yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <DateAndTime>
      <FieldWrapperDate>
        <FieldNameDate>
          תאריך<Span>*</Span>
        </FieldNameDate>
        <DateInputt
          type="date"
          value={DateValue}
          max={DisableDates()}
          onChange={DateOnChange}
        ></DateInputt>
      </FieldWrapperDate>
      <FieldWrapper>
        <FieldNameDate>
          שעה<Span>*</Span>
        </FieldNameDate>
        <Time type="time" value={TimeValue} onChange={TimeOnChange} />
      </FieldWrapper>
    </DateAndTime>
  );
};
