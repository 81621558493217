import styled from "styled-components";
import blueBg from "../../assets/blueBackgroung.jpeg";


export const HeaderWarper = styled.div`
  position: fixed;
  bottom: 0;
  background-image: ${() => `url(${blueBg})`};
  z-index: 10;
  width: 100vw;
  height: 7vh;
  bottom: 0;
`;

export const GoldLine = styled.div`
  background-color: #eacda3;
  height: 0.5vh;
  margin-bottom: 2vh;
`;

export const HeaderStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const Div = styled.div`
  color: #fff;
  font-family: "Assistant";
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Div2 = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Assistant";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 1vw;
`;
