
import styled, { keyframes ,css } from "styled-components";
import { useHistory } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { newSearch, searchWord } from "../../store/search";
import { useEffect, useState } from "react";
import { ReactComponent as Magnifier } from "../../assets/Svgs/magnifier.svg";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";

import { LastSearches } from "../LastSearches";
import { BsXLg } from "react-icons/bs";
import { shouldShowSearch } from "../Header/Header";


export const SearchField = () => {
  const user = useRecoilValue(userInfo);
  const history = useHistory();
  const [lastSearch, setLastSearch] = useState(false);
  const [arrLstSearch, setArrLstSearch] = useState<Array<string>>([]);
  const [searchTerm, setSearchTerm] = useRecoilState(searchWord);
  const [isSearch, setIsSearch] = useRecoilState(newSearch);
  const setShouldShowSearch = useSetRecoilState(shouldShowSearch);

  const isOpen = useRecoilValue(shouldShowSearch);
  const setIsOpen = useSetRecoilState(shouldShowSearch);

  const [shouldRender, setShouldRender] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);//
  useEffect(() => {
    setShouldRender(true)
  }, []);
  function handleChange(e: any) {
    setSearchTerm(e.target.value);
  }

  const handleSearch = () => {
    if (searchTerm) {
      history.push("/search");
      setIsSearch(true);
    }
  };
  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/user/getLastSearches`,
        {
          email: user.user.userPrincipalName,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setArrLstSearch(res.data.arrLastSearches);
      });
  }, []);

  useEffect(() => {
    setIsSearch(false);
  }, [searchTerm]);
  const handleClose = () => {
    setIsOpen(false);
  };
  useEffect(()=>{
    if(isFirstLoad && isOpen){
      console.log("first time")
      setIsFirstLoad(false);
    }
  },[isOpen])

  
  return (
    <div>

    <SearchWarper
      className={ isFirstLoad ? "no-animation" : isOpen ? "updates-open" : "updates-closed"}
    >
        <SearchFieldWarper>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <div
              style={{ color: "#020203", fontSize: "20px" }}
              onClick={() => {
                setShouldShowSearch(false);
                setSearchTerm("");
                setLastSearch(false);
              }}
            >
            </div>
           <BsXLg onClick={handleClose} /> 
            <input
              onChange={(e) => handleChange(e)}
              value={searchTerm}
              onClick={() => {
                setLastSearch(true);
              }}
              onBlur={(e) => {
                setTimeout(() => {
                  setLastSearch(false);
                }, 520);
              }}
            />
            <div
              onClick={() => {
                setSearchTerm("");
                setLastSearch(false);
              }}
            >
              נקה
            </div>
            <SeperateDiv></SeperateDiv>
            <button
              type="submit"
              onClick={() => {
                handleSearch();
                axios
                  .post(
                    `${BASE_URL}/user/lastSearches`,
                    {
                      email: user.user.userPrincipalName,
                      lastSearch: searchTerm,
                    },
                    {
                      withCredentials: true,
                    }
                  )
                  .then((res) => {
                    setArrLstSearch(res.data.arrLastSearches);
                  });
              }}
            >
              <Magnifier />
            </button>
          </form>
        </SearchFieldWarper>

      </SearchWarper>
      <Abso>
        {lastSearch &&
          arrLstSearch.map((item) => {
            return <LastSearches setSearchTerm={setSearchTerm} name={item} />;
          })}
      </Abso>
    </div>
  );
};

const Abso = styled.div`
  position: absolute;
  right: 1rem;
  background-color: #f3efef;
  width: 90%;
  z-index: -1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const SearchFieldWarper = styled.div`
  width: 90%;
  margin-right: 0.5rem;

  form {
    display: flex;
    height: 4vh;

    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    background: #cdeaff;
    align-items: center;
    width: 100%;
    input {
      border: unset;
      width: 100%;
      outline: none;
      height: 2vh;
      font-size: 18px;
      background: #cdeaff;
    }
    svg {
      height: 1rem;
      width: 1rem;
    }
    button {
      background: none;
      border: unset;
      display: flex;
      align-items: center;
    }
  }
`;
const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
    height: 0;
  }
  25% {
    height: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    height: 6vh;
  }
`;
const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
    height: 6vh;

  }
  to {
    transform: translateY(-100%);
    opacity: 0;
    height: 0;
    }
`;

const SearchWarper = styled.div`
  &.updates-open {
  
        animation: ${slideIn} 0.9s ease forwards;

  }
  &.updates-closed {

        animation: ${slideOut} 0.9s ease forwards;
 
  }
  &.no-animation {
    display: none;
  }
  display: flex;
  width: 100%;
  //margin-bottom: 3vh;
  align-items: center;
`;
const SeperateDiv = styled.div`
  width: 0.2vw;
  height: 3vh;
  background-color: black;
  margin-right: 2vw;
`;
