import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import axios from "axios";
import { Center } from "./Center";
import { IUpdate } from "../../AdminsUpdates/Update";
import { BASE_URL } from "../../../api/constants";
import { BsBellFill, BsXLg } from "react-icons/bs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { shouldShowUpdates } from "../../Header/Header";

interface IProps {
  setMarkup: React.Dispatch<React.SetStateAction<string>>;
}


export const Updates = ({ setMarkup }: IProps) => {
  const [updates, setUpdates] = useState<IUpdate[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isOpen = useRecoilValue(shouldShowUpdates);
  const setIsOpen = useSetRecoilState(shouldShowUpdates);

  const [shouldRender, setShouldRender] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);


  useEffect(() => {
    setShouldRender(true)
  }, []);

  
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${BASE_URL}/adminUpdates/getUserUpdates`, {
        withCredentials: true,
      })
      .then((res) => {
        res?.data?.success ? setUpdates(res.data.updates) : setUpdates([]);

        setIsLoading(false);
      });
  }, []);
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(()=>{
    if(isFirstLoad && isOpen){
      setIsFirstLoad(false);
    }
  },[isOpen])

  return (
    <UpdatesWrapper
      className={isFirstLoad ? "no-animation" : isOpen ? "updates-open" : "updates-closed"}
    >
      <Top>
        <BsXLg onClick={handleClose} />

        <P>
          <Vector />
          <P0>התרעות</P0>
          <Vector />
        </P>
        <BsBellFill />
      </Top>
      <Center setMarkup={setMarkup} isLoading={isLoading} updates={updates} />
      <Bottom />
    </UpdatesWrapper>
  );
};

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
    height: 0;
  }
  25% {
    height: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    height: 16vh;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
    height: 16vh;

  }
  to {
    transform: translateY(-100%);
    opacity: 0;
    height: 0;
    }
`;

const UpdatesWrapper = styled.div`
  &.updates-open {
        animation: ${slideIn} 0.9s ease forwards;

  }
  &.updates-closed {
        animation: ${slideOut} 0.9s ease forwards;

  }
  &.no-animation {
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 90vw;
  margin-bottom: 3vh;
  margin-top: 0.1vh;
  position: relative;

  &.notactive {
    display: flex;
    justify-content: center;
    &::before {
      content: "";
      background-color: gray;
      opacity: 0.4;
      z-index: 2;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
    &::after {
      position: absolute;
      align-self: center;
      text-align: center;
      content: "בקרוב..";
      font-size: 1.5rem;
      font-weight: 600;
      transform: rotate(340deg);
      color: black;
      z-index: 3;
    }
  }
`;

const Top = styled.div`
  width: 100%;
  font-weight: 600;
  height: 5vh;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: row;
  background-color: #cdeaff;
  border-bottom: 1px solid rgba(80, 101, 132, 0.63);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
`;
const P = styled.div`
  color: #23344e;
  width: 80vw;
  font-family: "Assistant";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;

  text-align: center;
`;
const P0 = styled.p`
  margin-left: 1vw;
  margin-right: 1vw;
`;

const Bottom = styled.div`
  width: 99.2%;
  height: 1rem;
  display: flex;
  background-color: #cdeaff;
  border: 1px solid #cdeaff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
`;
const Vector = styled.div`
  width: 26px;
  height: 2px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-right: 10rem;
  background: linear-gradient(
    90deg,
    #23344e 93.72%,
    #ffffff 101.37%,
    #dfe1e5 101.38%,
    rgba(255, 255, 255, 0) 101.39%
  );
`;
