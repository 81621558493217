import { MapSearch } from "./mapSearch";
import { StyledMapContainer, MapAlert, Div, MapWrapper } from "../styles";
import { TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import { MapMarker } from "../../MapMarkerIcon/index";
import axios from "axios";

interface Iprop {
  locationAdress: string;
  latitude: number;
  longitude: number;
  setLatitude: React.Dispatch<number>;
  setLongitude: React.Dispatch<number>;
  setLocationAdress: React.Dispatch<string>;
}

function GetLocation({
  setLattitude,
  setLongitude,
  setLocationAdress,
}: {
  setLattitude: React.Dispatch<number>;
  setLongitude: React.Dispatch<number>;
  setLocationAdress: React.Dispatch<string>;
}) {
  useMapEvents({
    click: (e) => {
      axios
        .get("https://trueway-geocoding.p.rapidapi.com/ReverseGeocode", {
          params: {
            location: `${e.latlng.lat}, ${e.latlng.lng}`,
            language: "he",
          },
          headers: {
            "X-RapidAPI-Key":
              "04a5326abdmsha71520ecdbb9581p1b4894jsna172689f0f5e",
            "X-RapidAPI-Host": "trueway-geocoding.p.rapidapi.com",
          },
        })
        .then((res) => {
          setLocationAdress(res.data.results[0].address);
        })
        .catch(function (error) {
          console.error(error);
        });
      setLattitude(e.latlng.lat);
      setLongitude(e.latlng.lng);
    },
  });
  return null;
}

export const Map = ({
  latitude,
  locationAdress,
  longitude,
  setLatitude,
  setLocationAdress,
  setLongitude,
}: Iprop) => {
  return (
    <MapWrapper>
      <MapAlert>שים לב! לאחר חיפוש מיקום יש לדקור את המיקום במפה</MapAlert>
      <Div>
        <StyledMapContainer center={[32.058731, 34.849529]} zoom={13}>
          <GetLocation
            setLattitude={setLatitude}
            setLongitude={setLongitude}
            setLocationAdress={setLocationAdress}
          />
          <MapSearch />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[latitude, longitude]} icon={MapMarker}>
            <Popup>{locationAdress}</Popup>
          </Marker>
        </StyledMapContainer>
      </Div>
    </MapWrapper>
  );
};
