import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useFile } from "../../hooks/useFile";
import { VideoWrapper } from "./videoView.styles";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { IconContext } from "react-icons";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
interface IProps {
  source?: string;
  type: string;
}

export const VideoView = ({ source, type }: IProps) => {
  const videoRef = useRef(null);
  const { setTreeFile } = useFile();
  const [favorite, setFavorite] = useState(false);
  const [showFavorite, setShowFavorite] = useState(false);
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;
  const { treeFile } = useFile();
  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/likes/isFavorite`,
        { email: email, fileName: treeFile.filename },
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result.data) {
          setFavorite(result.data.likeStatus);
          setShowFavorite(true);
        }
      });
  }, []);
  return (
    <>
      <Wrapper style={{ height: "100%", width: "96vw" }}>
        <DivFavoriteandReturn>
          <FileViewButton
            onClick={() => {
              setTreeFile({
                fileSrc: undefined,
                fileType: undefined,
                filename: undefined,
              });
            }}
          >
            חזור
          </FileViewButton>
          <IconeHer
            onClick={() => {
              setFavorite((prev) => !prev);
              axios
                .post(
                  `${BASE_URL}/likes/toggleFavorite`,
                  { email: email, fileName: treeFile.filename },
                  {
                    withCredentials: true,
                  }
                )
                .then((result) => {
                  if (result.data) {
                  }
                });
            }}
          >
            {showFavorite ? (
              favorite ? (
                <IconContext.Provider
                  value={{ color: "#ED8A19", size: "2.5rem" }}
                >
                  <div>
                    <AiFillStar />
                  </div>
                </IconContext.Provider>
              ) : (
                <IconContext.Provider value={{ size: "2.5rem" }}>
                  <div>
                    <AiOutlineStar />
                  </div>
                </IconContext.Provider>
              )
            ) : (
              <></>
            )}
          </IconeHer>
        </DivFavoriteandReturn>

        {source && (
          <VideoWrapper>
            <video ref={videoRef} controls>
              <source src={source} type={type} />
            </video>
          </VideoWrapper>
        )}
      </Wrapper>
    </>
  );
};

const DivFavoriteandReturn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconeHer = styled.div`
  height: 3rem;
  width: 2rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FileViewButton = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 2rem;
  max-width: 60vw;
  background-color: #3195ed;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: large;
`;
