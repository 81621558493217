import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 10;

  left: 0;
  top: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84%;
  width: 100%;
  overflow: auto;
  background-color: rgba(127, 127, 127, 0.6);
`;
export const Wrapper = styled.div`
  width: max-content;
`;
export const X = styled.div`
  text-align: right;
  font-size: 1.2rem;
`;
export const ModalBody = styled.div`
  position: fixed;
  margin: 10% auto;
  padding: 2rem;
  width: 50%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  white-space: pre-line;

  /* border-radius: 10vw; */
  background-color: white;
`;

export const TextDiv = styled.div`
  /* width: 50vw;
  font-size: 3rem;
  height: 20vh; */
`;

interface TooltipProps {
  top: number;
  right: number;
}

export const ToolTipContainer = styled.div<TooltipProps>`
  position: sticky;
  z-index: 6;

  .svg {
    z-index: 2;
    position: fixed;
    margin-top: ${(props) => `${props.top}rem`};
    right: ${(props) => `${props.right}%`};
    fill: gray;
    width: 1.15rem;
    height: 1.15rem;
  }
`;
