import axios from "axios";
import React, { Dispatch, useState } from "react";
import { BASE_URL } from "../../../api/constants";
import shareB from "../../../assets/Svgs/shareB.svg";
import shareY from "../../../assets/Svgs/shareY.svg";

interface IProps {
  setText: Dispatch<React.SetStateAction<string>>;
  filename: string;
  email: string;
  shortName: string;
}

function copyToClipboard(string: string) {
  let textarea;
  let result;

  try {
    textarea = document.createElement("textarea");
    textarea.setAttribute("readonly", "true");
    textarea.setAttribute("contenteditable", "true");
    textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand("copy");
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    if (textarea) document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    const copyHotkey = isMac ? "⌘C" : "CTRL+C";
    result = prompt("אנא סמן והעתק את הקישור", string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
}

const fileShare = async (email: string, filename: string) => {
  return axios
    .get(`${BASE_URL}/warTree/downloadFile/${filename}`, {
      withCredentials: true,
    })
    .then((res) => {
      axios.post(
        `${BASE_URL}/fileLogs/sendFileLog`,
        { email, action: "Share", fileName: filename },
        {
          withCredentials: true,
        }
      );
      res.data = res.data.replace(/\ /g, "%20");
      return res.data;
    })
    .catch((err) => {
      console.log(err);

      return false;
    });
};

export const WarFileShare = ({ setText, shortName, email, filename }: IProps) => {
  const [shareLogo, setShareLogo] = useState(shareB);

  const setTheName = () => {
    setText(shortName);
  };

  return (
    <button
      onTouchStart={() => setShareLogo(shareY)}
      onMouseDown={() => setShareLogo(shareY)}
      onMouseUp={() => setShareLogo(shareB)}
      onTouchEnd={() => setShareLogo(shareB)}
      onClick={() => {
        setText("טוען...");
        fileShare(email, filename).then((link: string) => {
          copyToClipboard(link);
          setText("קישור לקובץ הועתק בהצלחה.");
          setTimeout(setTheName, 3000);
        });
      }}
    >
      <img className={"share"} src={shareLogo} alt={"שיתוף"} />
    </button>
  );
};
