import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Home } from "./components/Home/Home";
import { Files } from "./components/Files";
import { Login } from "./components/Login";
import { Contact } from "./components/Contact";
import { LogOut } from "./components/LogOut";
import { Search } from "./components/Search";
import { PrivateRoute } from "./atoms/PrivateRoute";
import { useRecoilState } from "recoil";
import { userInfo } from "./store/userInfo";
import axios from "axios";
import { BASE_URL, isDevOrLocal } from "./api/constants";
import { useQuery } from "react-query";
import { Physiological } from "./components/Physiological";
import { useEffect, useState } from "react";
import AdminUpdates from "./components/AdminsUpdates";
import { DashBoard } from "./components/DashBoard";
import { SmartBasePage } from "./components/SmartBasePage";
import { FavoritesUser } from "./components/FavoritesUser";
import { SafetyReports } from "./components/SafetyReports";
import { NewFiles } from "./components/NewFiles";
import WarFiles from "./components/WarFiles";

interface FullData {
  user?: Object;
  permission?: string;
}

function App() {
  const [user, setUser] = useRecoilState(userInfo);
  const [isLogin, setIslogin] = useState(false);
  const getUserInfo = async () => {
    let fullData: FullData = {};
    const { data } = await axios.get(`${BASE_URL}/auth/isLogin`, {
      withCredentials: true,
    });
    if (data) {
      setIslogin(true);
      fullData.user = data;
      const res = await axios.get(`${BASE_URL}/user/getPermissions`, {
        withCredentials: true,
      });
      if (res.data?.success) {
        fullData.permission = res.data.permission;
      }
    }
    return fullData;
  };

  const { isLoading } = useQuery("user", getUserInfo, {
    onSuccess: (data) => {
      setIslogin(true);
      setUser({
        hasPermission: true,
        user: data.user,
        permissions: data?.permission ? [data.permission] : [],
      });
    },
    onError: () => setUser({ hasPermission: false }),
    retry: false,
  });

  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setLoadingProgress((prevProgress) => (prevProgress + 50) % 105);
    }, 1000);

    return () => clearInterval(progressInterval);
  }, [isLoading]);

  return (
    <Router>
      <div>
        {isLoading ? (
          <Login
            isLogin={isLogin}
            isLoading={isLoading}
            progress={loadingProgress}
          />
        ) : !user.hasPermission ? (
          <div>
            <Switch>
              <Route path="/">
                <Login isLoading={isLoading} />
              </Route>
            </Switch>
          </div>
        ) : (
          <div>
            <Switch>
              <PrivateRoute exact={true} path="/favoritesUser">
                <FavoritesUser />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/dashboard">
                <DashBoard />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/files">
                <Files />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/safetyliterature">
                <NewFiles
                  rootId={
                    isDevOrLocal
                      ? "653a178230e8ade0e459197c"
                      : "663b1bbbcfa914a6b907b906"
                  }
                  name="ספרות הבטיחות"
                />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/professionalareas">
                <NewFiles
                  rootId={
                    isDevOrLocal
                      ? "6540c18930e8ade0e459197f"
                      : "663b1e3dcfa914a6b907b909"
                  }
                  name="תחומים מקצועיים"
                />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/safetysystem">
                <NewFiles
                  rootId="655096ee30e8ade0e4591983"
                  name="מערכות הבטיחות"
                />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/safetyschool">
                <NewFiles
                  rootId={
                    isDevOrLocal
                      ? "655097de30e8ade0e4591984"
                      : "663b1ec0cfa914a6b907b90a"
                  }
                  name={`ביה"ס לבטיחות`}
                />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/subjects">
                <NewFiles
                  rootId={
                    isDevOrLocal
                      ? "655097f730e8ade0e4591985"
                      : "663b1edccfa914a6b907b90b"
                  }
                  name="נושאים במיקוד"
                />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/videoscatalog">
                <NewFiles
                  rootId={
                    isDevOrLocal
                      ? "6550981330e8ade0e4591986"
                      : "663b1f09cfa914a6b907b90d"
                  }
                  name="קטלוג הסרטונים"
                />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/researchcatalog">
                <NewFiles
                  rootId={
                    isDevOrLocal
                      ? "66150db810aeb63c1b88e717"
                      : "663b1eeecfa914a6b907b90c"
                  }
                  name="קטלוג התחקירים"
                />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/warFiles">
                <WarFiles />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/physiological">
                <Physiological />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/safetyreports">
                <SafetyReports />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/search">
                <Search />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/adminUpdates">
                <AdminUpdates />
              </PrivateRoute>

              <PrivateRoute exact={true} path="/smartBasePage">
                <SmartBasePage />
              </PrivateRoute>

              {/* <PrivateRoute exact={true} path="/pageLinks">
                <PageLinks />
              </PrivateRoute> */}

              <Route path="/contact" exact={true} component={Contact} />
              <Route path="/logout" component={LogOut} />
              <PrivateRoute exact={false} path="/">
                <Home />
              </PrivateRoute>
            </Switch>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
