import React from "react";
import styled from "styled-components";

interface IProps {
  placeholder: string;
  value: string;
  topText?: string;
  bottomText?: string;
  disabled?: boolean;
  setValue: React.Dispatch<string>;
  fontsize?: string;
}
interface SizeIprops {
  fontsize?: string;
}

const CalcInputStyle = styled.div<SizeIprops>`
  background-color: #dce7f1;
  border-radius: 2rem;
  width: 80vw;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  input {
    text-align: right;
    unicode-bidi: plaintext;
    align-self: center;
    justify-self: center;
    border: none;
    border-radius: 1rem;
    width: 80%;
    padding: 1rem 0;
    font-weight: 700;
    padding-right: 1rem;
    ::placeholder {
      font-weight: 700;
      padding-right: 0.2rem;
    }
    :focus {
      outline: none;
    }
  }
  p {
    font-size: 14px;
    font-weight: 600;
  }
  p,
  h4 {
    color: #2e3841;
    /* font-size: 13px; */
    ${(props) => props.fontsize && `font-size: ${props.fontsize};`}
    margin: 0.75rem 0;
    margin-right: 12%;
    font-weight: 600;
    /* padding: 0; */
  }
`;

export const CalcInput = ({
  placeholder,
  value,
  setValue,
  topText,
  bottomText,
  disabled,
  fontsize,
}: IProps) => {
  return (
    <CalcInputStyle fontsize={fontsize}>
      <h4>{topText}</h4>
      <input
        type="text"
        disabled={disabled ? disabled : false}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <p>{bottomText}</p>
    </CalcInputStyle>
  );
};
