import React, { Dispatch } from "react";
import styled from "styled-components";
import { FaFileSignature } from "react-icons/fa";

interface IProps {
  filename: string;
  setPopUpRename?: Dispatch<React.SetStateAction<boolean>>;
  setNameFile?: Dispatch<React.SetStateAction<string>>;
}

export const FileRename = ({
  setNameFile = () => {},
  filename,
  setPopUpRename = () => {},
}: IProps) => {
  return (
    <>
      <ButtonIcon
        onClick={() => {
          setNameFile(filename.slice(0, filename.length - 4));
          setPopUpRename(true);
        }}
      >
        <FaFileSignature />
      </ButtonIcon>
    </>
  );
};

const ButtonIcon = styled.div`
  color: #8fc8fa;
  width: 33.06px;
  height: 45.125px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
