import styled from "styled-components";
import { BASE_URL } from "../api/constants";
import bg from "../assets/topBlueBg.png";
import blueBg from "../assets/blueBackgroung.jpeg";
import greyLine from "../assets/greyLine.png";

import { ReactComponent as Scope } from "../assets/login/scope.svg";
import logo from "../assets/homepage/logo.png";
import { useState } from "react";
import { LoadingPage } from "./LoadingPage";
import { MiddleShield } from "./Home/MiddleShield";
interface Iprops {
  progress?: number;
  isLoading: boolean;
  isLogin?: boolean;
}

export const Login = ({ progress, isLoading, isLogin }: Iprops) => {
  const [clickedLogin, setClickedLogin] = useState(false);
  return (
    <>
      <Wrapper showBg>
        <TopWrapper showBg></TopWrapper>
        <MiddleShieldWrapper>
          <MiddleShield />
        </MiddleShieldWrapper>
        <Title>בטיחות בכף היד</Title>
        <SmallTitle>מבק"א יבשה</SmallTitle>
        <LastTitle>מעצבים תרבות לניצחון בטוח</LastTitle>
        {isLoading ? (
          <LoginButtonWrapper>
            <LoadingLoginButtonContainer>
              <LoadingLoginButton
                style={{ width: `${progress}%` }}
              ></LoadingLoginButton>
            </LoadingLoginButtonContainer>
          </LoginButtonWrapper>
        ) : (
          <LoginButtonWrapper>
            <NewLoginButton href={`${BASE_URL}/auth/microsoft`}>
              לחץ להתחברות
            </NewLoginButton>
          </LoginButtonWrapper>
        )}

        <GreyLineWrapper>
          <img src={greyLine}></img>
        </GreyLineWrapper>
        {!isLogin && (
          <>
            {" "}
            <LastTitle>עדיין לא יצרת משתמש Myidf?</LastTitle>
            <MyIdf href="https://my.idf.il/">לחץ כאן</MyIdf>
          </>
        )}
      </Wrapper>
    </>
    // <Wrapper showBg={!clickedLogin}>
    //   {!clickedLogin ? (
    //     <>
    //       <Header>
    //         <p>בטיחות בכף היד</p>
    //         <img src={logo} />
    //       </Header>
    //       <LoginComp>
    //         <LoginButton>
    //           <Link
    //             onClick={() => {
    //               setClickedLogin(true);
    //             }}
    //             href={`${BASE_URL}/auth/microsoft`}
    //           >
    //             <p>כניסה למערכת</p>
    //           </Link>
    //         </LoginButton>
    //         <Scope />
    //       </LoginComp>
    //       <Footer>
    //         <p>עדיין לא יצרת משתמש MyIdf?</p>
    //         <a href="https://my.idf.il/">לחץ כאן</a>
    //       </Footer>
    //     </>
    //   ) : (
    //     <LoadingPage />
    //   )}
    // </Wrapper>
  );
};

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  overflow: hidden;
  img {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 8rem;
    width: 8rem;
  }
  a {
    /* text-decoration: none; */
    color: white;
  }
  color: white;
`;

const Header = styled.div`
  z-index: 2;
  width: 100vw;
  height: 30vh;
  padding-bottom: 1rem;
  background-color: #2e3841;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: white;
  }
  img {
    /* width: 16.5rem; */
    /* height: 14.3rem; */
    width: auto;
    height: auto;
    max-height: 60%;
  }
`;

const LoginComp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
  svg {
    position: relative;
    z-index: 1;
    width: calc(10rem * 1.9);
    height: calc(10rem * 1.9);
    position: absolute;
    fill: white;
    opacity: 0.1;
  }
`;

const Link = styled.a`
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #2e3841;
  }
`;

const LoginButton = styled.div`
  position: relative;
  z-index: 2;
  background-color: #d8b214;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 30px 51px rgba(40, 43, 48, 0.7);
`;

interface IProps {
  showBg: boolean;
}

const TopWrapper = styled.div<IProps>`
  height: 25vh;
  width: 100vw;
  background-image: ${(props) => (props.showBg ? `url(${bg})` : "none")};
  background-repeat: no-repeat;
  background-size: cover;
  /* position: absolute; */
`;
const Wrapper = styled.div<IProps>`
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-image: ${(props) => (props.showBg ? `url(${blueBg})` : "none")};
  background-repeat: no-repeat;
  background-size: cover;
`;
const MiddleShieldWrapper = styled.div`
  display: flex;
  position: absolute !important;
  margin-top: -7vh;
  width: 100vw;
  height: 20vh;
`;
const Title = styled.div`
  display: flex;
  width: 100vw;
  height: 20vh;
  color: white;
  font-size: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;
const SmallTitle = styled.div`
  display: flex;
  width: 100vw;
  height: 5vh;
  color: white;
  font-size: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: -6vh;
  font-weight: 500;
`;
const LastTitle = styled.div`
  display: flex;
  width: 100vw;
  height: 5vh;
  color: white;
  font-size: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: -1vh;
`;
const MyIdf = styled.a`
  display: flex;
  width: 100vw;
  height: 5vh;
  color: white;
  font-size: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: -1vh;
`;
const LoginButtonWrapper = styled.div`
  display: flex;

  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
`;
const GreyLineWrapper = styled.div`
  display: flex;

  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  margin-bottom: 2vh;
`;
const NewLoginButton = styled.a`
  text-decoration: none;
  display: flex;
  width: 45vw;
  height: 4vh;
  color: white;
  background-color: rgba(217, 217, 217, 0.3);
  font-size: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: -1vh;
  border-radius: 1rem;
  font-weight: 600;
`;
export const LoadingLoginButtonContainer = styled.div`
  width: 45vw;
  height: 4vh;
  background-color: rgba(217, 217, 217, 0.3);
  border-radius: 1rem;
  overflow: hidden;
  text-align: center;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
`;
export const LoadingLoginButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 238, 192, 1);
  transition: width 0.8s ease-in-out;
`;
