import { useEffect, useState } from "react";
import {
  SortWrapper,
  ActivityType,
  VideoActivityType,
  VideoType,
  Year,
  Option,
  BottomWrapper,
  TopWrapper,
  FirstLabel,
  SecondLabel,
  ThirdLabel,
  LastLabel,
  SortButton,
  ResetButton,
} from "./styles";
export interface IProps {
  isFilm: boolean;
  bottomText: string;
  onClick: () => void;
  sortForm: SortForm;
  setSortForm: any;
  validData: boolean;
}
export interface SortForm {
  activityType: string;
  individualActivity: string;
  FilmType: string;
  researchType: string;
  year: number;
}
export const SortFiles = ({
  isFilm,
  bottomText,
  onClick,
  sortForm,
  validData,
  setSortForm,
}: IProps) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1984 },
    (_, index) => currentYear - index
  );
  const [Text, setText] = useState(bottomText);
  useEffect(() => {
    if (bottomText === "סנן") {
      setText("סנן");
    } else setText("העלאה");
  }, []);
  const resetFields = () => {
    setSortForm({
      activityType: "",
      individualActivity: "",
      FilmType: "",
      researchType: "",
      year: 0,
    });
  };

  return (
    <>
      <SortWrapper>
        <TopWrapper width="100vw" height="1vh">
          <FirstLabel>מאפיין תחומי</FirstLabel>
          <SecondLabel>מאפיין פעילות הפרט</SecondLabel>
          {isFilm ? (
            <ThirdLabel>סוג סרט</ThirdLabel>
          ) : (
            <ThirdLabel>סוג תחקיר</ThirdLabel>
          )}

          <LastLabel>שנה</LastLabel>
        </TopWrapper>
        <TopWrapper width="100vw" height="5vh">
          <ActivityType
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSortForm({ ...sortForm, activityType: e.target.value });
            }}
            value={sortForm.activityType}
          >
            <option value="">בחר\י</option>
            {activityTypeArray.map((value, index) => {
              return <Option key={index}>{value}</Option>;
            })}
          </ActivityType>
          <VideoActivityType
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSortForm({ ...sortForm, individualActivity: e.target.value });
            }}
            value={sortForm.individualActivity}
          >
            <option value="">בחר\י</option>

            {individualActivity.map((value, index) => {
              return (
                <Option key={index} value={value}>
                  {value}
                </Option>
              );
            })}
          </VideoActivityType>
          {isFilm ? (
            <VideoType
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSortForm({ ...sortForm, FilmType: e.target.value });
              }}
              value={sortForm.FilmType}
            >
              <option value="">בחר\י</option>
              {FilmType.map((value, index) => {
                return (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                );
              })}
            </VideoType>
          ) : (
            <VideoType
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSortForm({ ...sortForm, researchType: e.target.value });
              }}
              value={sortForm.researchType}
            >
              <option value="">בחר\י</option>
              {researchType.map((value, index) => {
                return (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                );
              })}
            </VideoType>
          )}

          <Year
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSortForm({ ...sortForm, year: e.target.value });
            }}
            value={sortForm.year}
          >
            <option value="">בחר\י</option>
            {years.map((value, index) => {
              return (
                <Option key={index} value={value}>
                  {value}
                </Option>
              );
            })}
          </Year>
        </TopWrapper>
      </SortWrapper>
      <BottomWrapper>
        <SortButton onClick={onClick}>{Text}</SortButton>
        <SortButton onClick={resetFields}>{"נקה"}</SortButton>
      </BottomWrapper>
    </>
  );
};

const activityTypeArray = [
  "נשק ומקלעים",
  "אמצעי הרום הקרוב לקרקע",
  "תחמושת",
  `חריגות ירי/תנועה של כוחות בשטחי אימונים
  `,
  "מזג אוויר",
  `נפילות/חבלות`,
  'טג"ח',
  'ירי דו"צ',
  `רק"ם וצמ"ה קרביים
  `,
  `בטיחות בדרכים`,
  `אווירי`,
  `שת"פ חיל האוויר`,
  `ימי`,
  `שת"פ חיל הים`,
  `ספורט`,
  `כושר גופני או קרבי`,
  `ייעודי עורף`,
  `אש`,
  `חומרים מסוכנים`,
  `עבודה`,
  `אמל"ח`,
];
const individualActivity = [
  `אימון`,
  `הכשרה`,
  `שגרה`,
  `פנאי`,
  `חופשה`,
  `פע"ם/לחימה
  `,
];
const researchType = [`תחקיר דגל`, `תחקיר מומלץ`, `תחקיר רגיל`, `תחקיר אדום`];
const FilmType = [`הדרכה`, `שחזור`, `כתבה`, `סרטון סיכום שנתי`, `סרטון חודשי`];
