import styled from "styled-components";
interface Iprops {
  width: string;
  height: string;
}

export const SortWrapper = styled.div`
  width: 100vw;
  height: 5vh;
  margin-bottom: 6vh;
  display: flex;
  flex-direction: column;
`;
export const TopWrapper = styled.div<Iprops>`
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  margin-right: 1vw;
  margin-bottom: 3vh;
  display: flex;
  flex-direction: row;
`;
export const Wrapper = styled.div`
  width: 90vw;
  height: 5vh;
  margin-right: 2vw;
  margin-bottom: 3vh;
  display: flex;
  flex-direction: row;
`;

export const ActivityType = styled.select`
  width: 25vw;
  height: 5vh;
  color: white;
  border-radius: 0.5rem;
  border-color: none;
  background-color: #23344e;
`;
export const VideoActivityType = styled.select`
  width: 33vw;
  height: 5vh;
  margin-right: 3vw;
  border-radius: 0.5rem;
  color: white;
  background-color: #23344e;
`;

export const VideoType = styled.select`
  width: 17vw;
  height: 5vh;
  margin-right: 3vw;
  border-radius: 0.5rem;

  color: white;
  background-color: #23344e;
`;

export const Year = styled.select`
  width: 13vw;
  height: 5vh;
  margin-right: 3vw;
  border-radius: 0.5rem;

  color: white;
  background-color: #23344e;
`;
export const Option = styled.option`
  width: 25vw;
  height: 5vh;
`;
export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;
export const SortButton = styled.div`
  background-color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  font-family: "Assistant";
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border-radius: 2rem;
  border: none;
  margin-bottom: 1rem;
  width: 20vw;
  height: 3.5vh;
  margin-left: 3vw;
`;
export const ResetButton = styled.div`
  background-color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  font-family: "Assistant";
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border-radius: 2rem;
  border: none;
  margin-bottom: 1rem;
  width: 20vw;
  height: 3vh;
  margin-left: 2vw;
`;
export const FirstLabel = styled.div`
  color: white;
  margin-right: 2vw;

  width: 25vw;
`;
export const SecondLabel = styled.div`
  color: white;
  margin-right: 2vw;
  width: 35vw;
`;
export const ThirdLabel = styled.div`
  color: white;
  width: 20vw;
  margin-right: 3vw;
`;
export const LastLabel = styled.div`
  color: white;
  width: 13vw;
  margin-right: 3vw;
`;
