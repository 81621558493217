import axios from "axios";
import React, { Dispatch, useState } from "react";
import { BASE_URL } from "../../../api/constants";
import eyeB from "../../../assets/Svgs/eyeB.svg";
import eyeY from "../../../assets/Svgs/eyeY.svg";
import { useFile } from "../../../hooks/useFile";
interface IProps {
  //   showModal: boolean;
  setText: Dispatch<React.SetStateAction<string>>;
  setRefresh?: Dispatch<React.SetStateAction<boolean>>;
  setFile?: Dispatch<React.SetStateAction<string>>;
  filename: string;
  email: string;
  shortName: string;
}

const filePreview = (email: String, filename: string) => {
  return axios
    .get(`${BASE_URL}/warTree/downloadFile/${filename}`, {
      withCredentials: true,
    })
    .then((res) => {
      axios.post(
        `${BASE_URL}/fileLogs/sendFileLog`,
        { email, action: "Preview", fileName: filename },
        {
          withCredentials: true,
        }
      );
      return res.data;
    })
    .catch((err) => {
      console.log(err);

      return false;
    });
};

export const WarFilePreview = ({
  setText,
  setFile,
  setRefresh,
  shortName,
  email,
  filename,
}: IProps) => {
  const [eyeLogo, setEyeLogo] = useState(eyeB);
  const { setFileSrc, setFileName } = useFile();
  return (
    <button
      onTouchStart={() => setEyeLogo(eyeY)}
      onMouseDown={() => setEyeLogo(eyeY)}
      onMouseUp={() => setEyeLogo(eyeB)}
      onTouchEnd={() => setEyeLogo(eyeB)}
      onClick={() => {
        setText("טוען...");
        filePreview(email, filename).then((result) => {
          setFile && setFile(result);
          setFileSrc(result);
          setFileName(filename);
          setText(shortName);
          setRefresh && setRefresh(true);
        });
      }}
    >
      <img className={"eye"} src={eyeLogo} alt={"צפייה"} />
    </button>
  );
};
