import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { TreeNode, TreeNodeType } from "../TreeNode";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { BASE_URL } from "../../api/constants";
import { Plus } from "../../assets/Svgs/Plus";
import { Modal } from "../../atoms/Modal";
import { UploadModal } from "../UploadModal";
import usePermissions from "../../hooks/usePermissions";
import { FileView } from "../FileView";
import { VideoView } from "../VideoView";
import { useFile } from "../../hooks/useFile";
import { AiModal } from "../File/FilePopup/AiModal";
import { useRecoilValue } from "recoil";
import { aiState, shouldShowState } from "../sumPdf";

export const Files = () => {
  const [nodes, setNodes] = useState<TreeNodeType[]>([]);
  const [IsThereNodes, setIsThereNodes] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const aiAnswer = useRecoilValue(aiState);
  const shouldShow = useRecoilValue(shouldShowState);

  const [selectedFolder, setSelectedFolder] = useState<
    // TreeNodeType |
    (TreeNodeType & { onClickFunction: Function }) | undefined
  >(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { isContentManagerUser, isAdminUser } = usePermissions();
  const [file, setFile] = useState<string>("");
  useEffect(() => {
    console.log(file);
  }, [file]);
  const { treeFile, resetFile } = useFile();
  useEffect(() => {
    if (treeFile) {
      resetFile();
    }
    axios
      .get(`${BASE_URL}/tree/root`, { withCredentials: true })
      .then((res) => setNodes(res.data));
  }, []);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);
  return (
    <DefaultContainer plus={true}>
      {selectedFolder && (
        <>
          {isContentManagerUser && (
            <div>
              <PlusButton onClick={() => setIsOpen(true)}>
                <Plus size={26} />
              </PlusButton>
              {/* <Modal showModal={isOpen} setShowModal={setIsOpen}> */}
              <UploadModal
                setIsThereNodes={setIsThereNodes}
                selectedFolder={selectedFolder}
                setShowModal={setIsOpen}
                showModal={isOpen}
                isFilmCatalog={false}
              />
              {/* </Modal> */}
            </div>
          )}
        </>
      )}
      {treeFile.fileSrc && (
        <FileViewWrapper>
          {treeFile.fileType?.toLowerCase() == "mp4" ? (
            <VideoView type={"video/mp4"} source={treeFile.fileSrc} />
          ) : (
            <FileView setRefresh={setRefresh} setFile={setFile} file={file} />
          )}
        </FileViewWrapper>
      )}

      <AiModal shouldShow={shouldShow}>{aiAnswer}</AiModal>
      <FilesWrapper file={treeFile.fileSrc}>
        {nodes.map((node, i) => {
          return (
            <TreeNode
              key={i}
              refresh={refresh}
              setRefresh={setRefresh}
              setFile={setFile}
              node={node}
              isSecondLevel={true}
              setSelectedFolder={setSelectedFolder}
            />
          );
        })}
      </FilesWrapper>
    </DefaultContainer>
  );
};

export default Files;

const FilesWrapper = styled.div<{ file?: string }>`
  display: ${(props) => (props?.file ? "none" : "")};
`;

const FileViewWrapper = styled.div`
  position: absolute;
  display: flex;
  background-color: white;
  width: 100vw;

  justify-content: center;
  z-index: 2;
`;

const PlusButton = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: #3c85dc;
  border-radius: 2rem;
  position: fixed;
  top: 6.5rem;
  right: calc(50vw - 1.5rem);
  margin: auto;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
`;
