import {
  FieldName,
  FieldWrapper,
  InjuriesNumberSelectt,
  Span,
} from "../styles";
import { Selectt } from "./styles";
import { ChangeEvent } from "react";
interface IProp {
  fieldLabel: string;
  placeHolder?: string;
  maxLength?: number;
  value: string | number | string[];
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  data?: string[];
  isRequired?: boolean;
  type?: string;
}

export const Field = ({
  fieldLabel,
  onChange,
  value,
  data,
  isRequired,
  maxLength,
  placeHolder,
  type,
}: IProp) => {
  return (
    <FieldWrapper>
      <FieldName>
        {fieldLabel}
        {isRequired && <Span>*</Span>}
      </FieldName>
      {data && data?.length > 0 ? (
        <Selectt value={value} onChange={onChange}>
          {data.map((result) => {
            return <option value={result}>{result}</option>;
          })}
        </Selectt>
      ) : (
        <InjuriesNumberSelectt
          value={value}
          placeholder={placeHolder}
          maxLength={maxLength}
          onChange={onChange}
          type={type}
        ></InjuriesNumberSelectt>
      )}
    </FieldWrapper>
  );
};
