import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  direction: rtl;
  z-index: 100;
`;

export const PopupWrapper = styled.div`
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  align-items: right;
  justify-content: flex-start;
  overflow: auto;
  direction: rtl;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    width: 90vw;
    height: 90vh;
  }
`;

export const Escape = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  top: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.2); */
  z-index: 100;
`;
