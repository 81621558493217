import { useHistory } from "react-router";
import styled from "styled-components";
import { isDevOrLocal } from "../../api/constants";
import { useSetRecoilState } from "recoil";
import { shouldShowSearch } from "./Header";

export const Center = () => {
  const history = useHistory();
  const setShouldShowSearch = useSetRecoilState(shouldShowSearch);

  return (
    <div>
      {isDevOrLocal && <CenterDiv>בדיקות</CenterDiv>}

      <CenterDiv
        onClick={() => {
          history.push("/");
          setShouldShowSearch(false);
        }}
      >
        בטיחות בכף היד
      </CenterDiv>
    </div>
  );
};

const CenterDiv = styled.div`
  color: #fff;
  text-align: center;
  font-family: Assistant;
  width: 50vw;
  font-size: 1.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
