import axios from "axios";

import { atom } from "recoil";
interface IData {
  url: string;
  messages: { role: string; content: string }[];
  sourceId?: string;
}

export const aiState = atom({
  key: "aiState", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});
export const shouldShowState = atom({
  key: "shouldShowState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const isLoading = atom({
  key: "isLoading", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const SumPdf = (question: string, url: string) => {
  //   const onClick = () => {

  const config = {
    headers: {
      "x-api-key": "sec_WzfRIG50wpSrzvzQ2yFTV7e7FIeAGP4C",
      "Content-Type": "application/json",
    },
  };

  const data: IData = {
    url: url,

    messages: [
      {
        role: "user",
        content: question,
      },
    ],
  };
  const content = axios
    .post("https://api.chatpdf.com/v1/sources/add-url", data, config)
    .then((response: any) => {
      //   console.log("Source ID:", response.data.sourceId);
      data.sourceId = response.data.sourceId;
      return axios
        .post("https://api.chatpdf.com/v1/chats/message", data, config)
        .then((response: any) => {
          //   console.log("Result:", response.data.content);
          return response.data.content;
        })
        .catch((error: any) => {
          console.error("Error:", error.message);
          console.log("Response:", error.response.data);
        });
    })
    .catch((error: any) => {
      console.log("Error:", error.message);
      console.log("Response:", error.response.data);
      console.log(url);
    });

  //   };
  return content;
};
