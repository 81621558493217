import { Wrapper } from "../styles";
import { Bold, Li, PageWrapper, Title, TitleWrapper } from "./styles";
import React from "react";
// import ScreenShot_3 from "../../../../assets/Screenshot_3.png";
import IsotonyImage from "../../../../../assets/kastelImage.png";

export const ConditionModaL = () => {
  return (
    <>
      <PageWrapper>
        <TitleWrapper>
          <Title>קביעת תנאי האקלים במהלך פעילות\אימון</Title>
          <img src={IsotonyImage} alt="" />
        </TitleWrapper>
        <Wrapper>
          <Li>
            <Bold>א. </Bold>
            באחריות מפקד היחידה המתאמנת, מרמת מ"פ ומעלה ומקביליו, לוודא כי
            מתבצעת מדידת תנאי עומס חום או קור באמצעות מכשיר "קסטרל" בשטח האימון
            כמפורט בסעיף 10 ד' להורא"ב כרך א', חלק ב', הוראה 1.1 ותיעודה
          </Li>
          <Li>
            <Bold>ב. </Bold>
            קביעת תנאי האקלים בשטח ביצוע הפעילות תבוצע על ידי חובש או מט"ב או כל
            גורם שהוסמך לכך על ידי קצין הרפואה היחידתי
          </Li>
          <Li>
            <Bold>ג. </Bold>
            מדידת תנאי האקלים בפעלות (אימון, טיול, פעילות מבצעית וכיוב') באמצעות
            קסטרל תיעשה:
          </Li>
          <Li>
            <Bold>ד. </Bold>
            לפני הפעילות- ב-30 הדקות הקודמות לתחילת הפעילות
          </Li>
          <Li>
            <Bold>ה. </Bold>
            במהלך הפעילות - מרגע תחילת הפעילות ועד סיומה, תתבצע מדידת עומס
            חום/קור בכל 3 שעות
          </Li>
          <Li>
            <Bold>ו. </Bold>
            בנוסף לאמור לעיל, מדידת תנאי עומס חום או קור ותיעודם תתבצע 4 פעמים
            ביממה בשעות הבאות: 8:00, 11:00, 17:00, 23:00.
          </Li>
          <Li>
            <Bold>ז. </Bold>
            בעומס חום מעל 23.0 יחידות אי נוחות (יא"נ) ובעומס קור בדרגה 1 ומעלה,
            על מפקד הכוח (מ"פ לכל הפחות) המבצע את הפעילות לוודא מדידה של עומס
            חום או קור בטרם ובמהלך האימונים כל 60 דקות במהלך הפעילות ועד סיומה
            ולהתעדכן בתוצאה
          </Li>
          <Li>
            <Bold>ח. </Bold>
            עומס חום או קור הקובע הינו זה הנמדד בחלל בו מתבצעת הפעילות (לדוגמה:
            רכב, רק"מ, עמדה, מבנה וכיו"ב)
          </Li>
          <Li>
            <Bold>ט. </Bold>
            בכל מקרה של הבדל בין תחזית עומס החום או הקור המתפרסמת באמצעי המדיה
            השונים לבין מדידה בשטח בו מתבצע האימון, תכריע המדידה בשטח הפעילות
          </Li>
        </Wrapper>
      </PageWrapper>
    </>
  );
};
