
import physioligical from "../assets/Svgs/physioligical.svg";
import Icon1 from "../assets/Svgs/Redesign/New Icons 001-06.svg";
import Icon2 from "../assets/Svgs/Redesign/New Icons 002-06.svg";
import Icon3 from "../assets/Svgs/Redesign/New Icons 003-06.svg";
import Icon4 from "../assets/Svgs/Redesign/New Icons 004-06.svg";
import Icon5 from "../assets/Svgs/Redesign/New Icons 005-06.svg";
import Icon6 from "../assets/Svgs/Redesign/New Icons 006-06 (1).svg";
import Icon7 from "../assets/Svgs/Redesign/New Icons 007-06.svg";
import Icon8 from "../assets/Svgs/Redesign/New Icons 008-06.svg";
import Icon9 from "../assets/Svgs/Redesign/New Icons 009-06.svg";
import Icon10 from "../assets/Svgs/Redesign/New Icons 010-06.svg";
import Icon11 from "../assets/Svgs/Redesign/New Icons 011-06.svg";
import Icon12 from "../assets/Svgs/Redesign/New Icons 012-06.svg";
import Icon13 from "../assets/Svgs/Redesign/New Icons 013-06.svg";
import Icon14 from "../assets/Svgs/Redesign/New Icons 014-06.svg";
import Icon15 from "../assets/Svgs/Redesign/New Icons 015-06.svg";
import Icon18 from "../assets/Svgs/Redesign/New Icons 018-06.svg";
import Icon21 from "../assets/Svgs/Redesign/New Icons 021-06.svg";
import Icon22 from "../assets/Svgs/Redesign/New Icons 022-06.svg";
import Icon24 from "../assets/Svgs/Redesign/New Icons 024-06.svg";
import Icon25 from "../assets/Svgs/Redesign/New Icons 025-06.svg";
import Icon26 from "../assets/Svgs/Redesign/New Icons 026-06.svg";
import Icon27 from "../assets/Svgs/Redesign/New Icons 027-06.svg";
import Icon28 from "../assets/Svgs/Redesign/New Icons 028-06.svg";
import Icon29 from "../assets/Svgs/Redesign/New Icons 029-06.svg";
import Icon30 from "../assets/Svgs/Redesign/New Icons 030-06.svg";
import Icon31 from "../assets/Svgs/Redesign/New Icons 031-06.svg";
import Icon32 from "../assets/Svgs/Redesign/New Icons 032-06.svg";
import Icon33 from "../assets/Svgs/Redesign/New Icons 033-06.svg";
import Icon34 from "../assets/Svgs/Redesign/New Icons 034-06.svg";
import Icon35 from "../assets/Svgs/Redesign/New Icons 035-06.svg";
import Icon36 from "../assets/Svgs/Redesign/New Icons 036-06.svg";
import Icon37 from "../assets/Svgs/Redesign/New Icons 037-06.svg";
import Icon38 from "../assets/Svgs/Redesign/New Icons 038-06.svg";
import Books from "../assets/Svgs/Redesign/Books.svg";
import GraduationHat from "../assets/Svgs/Redesign/Graduation.svg";
import Light from "../assets/Svgs/Redesign/light.svg";
import Laptop from "../assets/Svgs/Redesign/Laptop.svg";
import Pdf from "../assets/Svgs/pdf.svg";
import MP4 from "../assets/Svgs/mp4.svg";
import styled from "styled-components";
import { CARDS } from "../constants";

interface IProps {
  text: string;
  url?: string;
  color: string;
  bgColor: string;
  isActive: boolean;
  onClickFunc?: () => void;
  textSize?: Number;
}

interface cardProps {
  color: string;
  isActive: boolean;
  textSize?: number;
}

interface listProps {
  name: string;
  textSize: string;
  onClickFunc?: () => void;
  isLastPlace: boolean;
}

// const logos: any = {
//   'ענ"א': location,
//   "הידע בבטיחות": contentManager,
//   "ניהול הבטיחות": safteySchedule,
//   פיזיולוגי: physioligical,
//   "הכשרות וקורסים": academyIcon,
//   "ספרות הבטיחות": Raz,
//   "קטלוג התחקירים": Pdf,
//   pdf: Pdf,
//   "קטלוג הסרטונים": MP4,
//   mp4: MP4,
//   'ביה"ס לבטיחות': GraduationHat,
//   "מערכות בטיחות": SafetySystem,
//   "נושאים במיקוד": Light,
//   "תחומים מקצועיים": HandBag,
//   "בטיחות באימונים והכשרות": Tank,
//   "בסיס חכם": coneIcon,
//   "אינדקס שריפות": FireLogo,
// };
const logos: any = {
  [CARDS[0]]: Icon1,
  [CARDS[1]]: Icon2,
  [CARDS[2]]: Icon3,
  [CARDS[3]]: Icon4,
  [CARDS[4]]: Icon5,
  [CARDS[5]]: Icon6,
  [CARDS[6]]: Icon7,
  [CARDS[7]]: Icon8,
  [CARDS[8]]: Icon9,
  [CARDS[9]]: Icon10,
  [CARDS[10]]: Icon11,
  [CARDS[11]]: Icon12,
  [CARDS[12]]: Icon13,
  [CARDS[13]]: Icon14,
  [CARDS[14]]: Icon15,
  [CARDS[15]]: Icon21,
  [CARDS[16]]: Icon22,
  [CARDS[17]]: Icon24,
  [CARDS[18]]: Icon25,
  [CARDS[19]]: Icon26,
  [CARDS[20]]: Icon27,
  [CARDS[21]]: Icon28,
  [CARDS[22]]: Icon29,
  [CARDS[23]]: Icon30,
  [CARDS[24]]: Icon31,
  [CARDS[25]]: Icon32,
  [CARDS[26]]: Icon33,
  [CARDS[27]]: Icon34,
  [CARDS[28]]: Icon35,
  [CARDS[29]]: Icon36,
  [CARDS[30]]: Icon37,
  [CARDS[31]]: Icon38,
  [CARDS[32]]: Books,
  [CARDS[33]]: Laptop,
  [CARDS[34]]: Icon18,
  [CARDS[35]]: GraduationHat,
  [CARDS[36]]: Light,
  [CARDS[37]]: MP4,
  [CARDS[38]]: Pdf,
  [CARDS[39]]: physioligical,
};

export const Card = ({
  text,
  url,
  color,
  bgColor,
  isActive,
  onClickFunc,
  textSize,
}: IProps) => {
  let logo = text;
  const fileType = text.split(".").slice(-1)[0];
  if (fileType === "pdf" || "mp4") {
    logo = fileType;
  }

  return (
    <CardWrapper
      onClick={() => {
        isActive && onClickFunc && onClickFunc();
      }}
      color={color}
      isActive={isActive}
      className={isActive ? "" : "active"}
    >
      <ImgWrapper color={bgColor} isActive={isActive}>
        {logos[logo] && (
          <img
            style={{
              alignSelf: "center",
              width: "1.2rem",
              height: "1.2rem",
            }}
            src={logos[logo]}
            alt="logo"
          />
        )}
      </ImgWrapper>
      <p
        style={{
          margin: "0",
          // whiteSpace: "nowrap",
          fontSize: `${textSize}px`,
          lineHeight: "1.5rem",
          width: "35vw",
        }}
      >
        {text}
      </p>
      {/* <Link to={`${url}/${text}`}>Rendering with React</Link> */}
    </CardWrapper>
  );
};
export const List = ({
  name,
  onClickFunc,
  isLastPlace,
  textSize,
}: listProps) => {
  return (
    <>
      <ListDiv
        textSize={textSize}
        name=""
        isLastPlace={isLastPlace}
        onClick={() => {
          onClickFunc && onClickFunc();
        }}
      >
        {name}
      </ListDiv>
    </>
  );
};
const ListDiv = styled.div<listProps>`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* min-width: 50vw; */
  /* max-width: 80vw; */
  height: 4.5vh;
  border-radius: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: ${(p: listProps) => (p.isLastPlace ? "white" : "black")};
  background-color: ${(p: listProps) => (p.isLastPlace ? "#506584" : "white")};
`;

const CardWrapper = styled.div<cardProps>`
  background-color: white;
  padding: 0.3rem 2rem;
  margin: 0.5rem 0.5rem 0 0.5rem;
  /* width: 20vw; */
  height: 9vh;
  border-radius: 1rem;
  color: ${(p: cardProps) => p.color};
  font-size: medium;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &.active {
    &::before {
      content: "";
      background-color: gray;
      opacity: 0.4;
      z-index: 2;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
    &::after {
      position: absolute;
      align-self: center;
      content: "בקרוב..";
      font-size: 1.5rem;
      font-weight: 600;
      transform: rotate(340deg);
      color: black;
      z-index: 3;
    }
  }
`;

const ImgWrapper = styled.div<cardProps>`
  background-color: ${(p: cardProps) => p.color};
  border-radius: 2rem;
  padding: 0.8rem;
  /* width: 2vw;
  height: 2.5vh; */
  display: flex;
  justify-content: center;
  /* @media (max-width: 768px) {
    margin-top: 1rem;
    width: 8vw;
    height: 3vh;
  } */
`;

