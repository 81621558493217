import { CorrectedSelect, FieldName, FieldWrapper } from "../styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { ReactNode } from "react";

interface IProps {
  value: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
  specialEventsArr: {
    name: string;
    value: number;
  }[];
}

export const SpecialEvent = ({ onChange, value, specialEventsArr }: IProps) => {
  return (
    <FieldWrapper>
      <FieldName>מקרים חריגים</FieldName>
      <FormControl>
        <CorrectedSelect>
          <Select
            sx={{
              width: "87.5%",
              marginRight: 2.1,
              borderRadius: 0,
              font: "inherit",
              fontFamily: "inherit",
              borderColor: "#a39e9e",
              backgroundColor: "#e4e4e4",
              direction: "rtl",
              height: 40,
              color: "#333",
            }}
            multiple
            displayEmpty
            value={value}
            onChange={onChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <p>בחר/י</p>;
              }
              return selected.join(", ");
            }}
          >
            {specialEventsArr.map((event) => (
              <MenuItem
                key={event.name}
                value={event.name}
                sx={{ whiteSpace: "normal", paddingRight: 0, width: 300 }}
              >
                <Checkbox checked={value.indexOf(event.name) > -1} />
                <ListItemText primary={event.name} />
              </MenuItem>
            ))}
          </Select>
        </CorrectedSelect>
      </FormControl>
    </FieldWrapper>
  );
};
