import React from "react";
import styled from "styled-components";

interface IProps {
  respondentNameId: string;
  respondentDate: Date;
}

export const RespondentNameTime = ({
  respondentNameId,
  respondentDate,
}: IProps) => {
  let date = new Date(respondentDate);
  return (
    <>
      <Body>
        <h4>שם המשיב</h4>
        <Content>
          <Label id="name">{respondentNameId}</Label>
          <Label id="date">
            <div>{date.getFullYear()}/</div>
            <div>{date.getMonth() + 1}/</div>
            <div>{date.getDate()}</div>
          </Label>
        </Content>
      </Body>
    </>
  );
};

const Body = styled.div`
  background-color: #dce7f1;
  border-radius: 2rem;
  width: 80vw;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  h4 {
    color: #2e3841;
    margin: 0.75rem 0;
    margin-right: 12%;
    font-weight: 600;
  }
`;

const Label = styled.div`
  /* border: 1px solid #acaaaa; */
  border-radius: 15px;
  color: black;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;

  &&#name {
    color: #2e3841;
    width: 6rem;
  }
  &&#date {
    font-weight: bold;
    width: 4rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 1rem;
  width: 80%;
  align-items: center;
`;
