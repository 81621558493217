import React, { Dispatch } from "react";
import styled from "styled-components";
import { ImageOption, Option } from "../ImageOption";

interface IProps {
  title: string;
  options: Option[];
  setSelected: Dispatch<React.SetStateAction<string>>;
  selected: string;
  disabled?: boolean;
  isExam?: boolean;
  setShouldShow: React.Dispatch<React.SetStateAction<boolean>>;
  setToolTipMessage: React.Dispatch<React.SetStateAction<string>>;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: auto;
  min-width: 90vw;
  max-width: 95vw;
  /* overflow: auto; */
  .imagesWrapper {
    display: flex;
    overflow: auto;
    width: auto;
    min-width: 100%;
    max-width: 100vw !important;
    /* justify-content: space-between; */
    ::-webkit-scrollbar {
      display: none;
    }
  }
  h4 {
    color: #2e3841;
  }
`;

export const ImageSelect = ({
  title,
  options,
  setSelected,
  selected,
  disabled,
  isExam,
  setToolTipMessage,
  setShouldShow,
}: IProps) => {
  const handleOptionClick = (option: Option) => {
    if (!disabled && selected !== option.text) {
      setSelected(option.text);
    } else {
      setSelected("");
    }
  };
  return (
    <Wrapper>
      <h4>{title}</h4>
      <div className="imagesWrapper">
        {options.map((option) => {
          return (
            <ImageOption
              key={option.text}
              onClick={(e) => handleOptionClick(option)}
              isSelected={disabled ? false : selected == option.text}
              disabled={disabled}
              Img={option.Img}
              text={option.text}
              textt={option?.textt}
              number={option?.number}
              color={option?.color}
              explanation={option?.explanation}
              explanationExam={option?.explanationExam}
              isExam={isExam}
              setShouldShow={setShouldShow}
              setToolTipMessage={setToolTipMessage}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};
