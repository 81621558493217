import styled from "styled-components";

export const Wrapper = styled.div`
  width: 90%;
  /* margin-right: 10%; */
  direction: ltr;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  height: 50vh;
  .DraftEditor-root {
    overflow-y: auto;
    height: 200px;
  }
`;
