// Burger.js
import React, { useEffect, useState } from "react";
import { Menu } from "./Menu";
import styled from "styled-components";
import blueBg from "../../assets/blueBackgroung.jpeg";
import Shield from "../../assets/homepage/logo.png";
import { atom } from "recoil";
import { useHistory } from "react-router";

interface IProps {
  open: Boolean;
  numberNotification: number;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const shouldShowFavorites = atom({
  key: "shouldShowFavorites", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});
export const updatesNumber = atom({
  key: "updatesNumber", // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});
export const closeContactNumber = atom({
  key: "contactNumber", // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});

export const BurgerMenu = ({
  open,
  setMenuOpen,
  numberNotification,
}: IProps) => {
  const [style, setStyle] = React.useState("");
  const [counter, setCounter] = useState(0);
  const history = useHistory();
  useEffect(() => {
    if (counter) setStyle(open ? "slide-in-from-left" : "close");
    setCounter((value) => value + 1);
  }, [open]);

  return (
    <BigWrapper style={{ overflow: "hidden" }}>
      <FullWrapper open={open} className={style} background="blue">
        <Menu
          numberNotification={numberNotification}
          setMenuOpen={setMenuOpen}
          open={open}
        />

        <TopWrapper>
          <ImgWrapper
            onClick={() => {
              setMenuOpen(false);
              history.push("/");
            }}
          >
            <Img src={Shield} alt=""></Img>
          </ImgWrapper>
          <CloseButton
            onClick={() => {
              setMenuOpen(false);
              setStyle("close");
            }}
          >
            <p
              onClick={() => {
                console.log(true);

                setMenuOpen(false);
                setStyle("close");
              }}
            >
              X
            </p>
            {/* <Arrow /> */}
          </CloseButton>
        </TopWrapper>
        {/* <GoldLine></GoldLine> */}

        <InvisblePart
          onClick={() => {
            setMenuOpen(false);
            setStyle("close");
          }}
        ></InvisblePart>
      </FullWrapper>
    </BigWrapper>
  );
};

const CloseButton = styled.div`
  position: absolute;
  /* width: 3rem; */
  /* height: 3rem; */
  color: white;
  p {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 500;
  }
  top: 2.5vh;
  left: calc(0vw + 1rem);
  transform: scaleX(1.5);
  z-index: 6;
  opacity: 1;
`;

const InvisblePart = styled.div`
  width: unset;
  height: 100vh;
  z-index: 100;
`;
const BigWrapper = styled.div`
  height: 100vh;
`;
interface IPropsWrapper {
  open: Boolean;
  background?: string;
}

const FullWrapper = styled.div<IPropsWrapper>`
  position: absolute;
  background-image: ${(props: IPropsWrapper) =>
    props.background === "blue"
      ? `url(${blueBg})`
      : props.background === "grey"
      ? `url(${blueBg})`
      : `url(${blueBg})`};
  background-size: cover;
  top: 0;
  left: 0;
  height: 100%;
  width: 80vw;
  z-index: 20;
  opacity: 0.96;
  overflow: hidden;
  visibility: hidden;
`;
export const Img = styled.img`
  width: 6rem;
  height: 3rem;
`;
export const TopWrapper = styled.div`
  width: 70vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
`;
export const ImgWrapper = styled.div`
  position: absolute;
  top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
   right: 2rem; 
`;
