import styled, { keyframes } from "styled-components";

export const TopWrapper = styled.div`
  width: 75vw;
  height: 6vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-top: 2px solid #6d5b44;
  border-bottom: 2px solid #6d5b44;
  margin-top: 1.1vh;
  .p {
    color: white;
  }
`;
export const PersonalSectionWrapper = styled.div`
  width: 75vw;
  height: 8vh;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #8080809a;
`;
export const BigSectionWrapper = styled.div`
  width: 75vw;
  height: 5vh;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #8080809a;
`;
const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
    height: 0;
  }
  25% {
    height: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    height: 12vh;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
    height: 12vh;

  }
  to {
    transform: translateY(-100%);
    opacity: 0;
    height: 0;
    }
`;


export const SectionWrapper = styled.a`
  &.updates-open {
        animation: ${slideIn} 0.9s ease forwards;

  }
  &.updates-closed {
        animation: ${slideOut} 0.9s ease forwards;

  }
  &.no-animation {
    display: none;
  }

  display: flex;
  align-items: center;
  /* justify-content: right; */
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  width: 70vw;
  height: 5vh;
  margin-right: 8vw;
  text-decoration: none;
`;

export const PersonalArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  color: #eacda3;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  /* height: 12vh; */
  margin-right: 7vw;
`;
export const UserName = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 5vh;
  margin-right: 8vw;
`;
export const Img = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 3vw;
  border: none;
`;
export const TimronImg = styled.img`
  width: 2rem;
  height: 2rem;
  margin-left: 2vw;
  margin-right: -1vw;
  border: none;
`;
export const AlertsImg = styled.img`
  margin-right: -3vw;
  margin-left: 2vw;
  margin-bottom: 1vh;
  border: none;
`;
export const PersonalImg = styled.img`
  width: 2.2rem;
  height: 2.3rem;
  margin-left: 1vw;
  margin-top: 1vh;
  border: none;
`;
export const NewImg = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: -2vw;
  margin-left: 3vw;
  margin-bottom: 1vh;
  border: none;
`;
export const Option = styled.div``;
// export const SectionWrapper=styled.div``
