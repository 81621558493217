import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 10;

  left: 0;
  top: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: auto;
  background-color: rgba(127, 127, 127, 0.6);
`;
export const Wrapper = styled.div`
  width: max-content;
`;
export const Text = styled.div`
  display: flex;
  margin-right: 0.7rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const YesandNo = styled.div``;
export const CheckBoxWrapper = styled.div`
  width: 30vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
`;
export const X = styled.div`
  /* align-items: r; */
  justify-content: flex-start;
  text-align: right;
  font-size: 1.2rem;
`;
export const ModalBody = styled.div`
  position: fixed;
  margin: 10% auto;
  /* padding: 2rem; */
  width: 80vw;
  height: 40vh;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* white-space: pre-line; */

  /* border-radius: 10vw; */
  background-color: white;
`;
export const TitleP = styled.p`
  color: red;
  text-align: right;
  margin-right: 2vw;
  /* margin-bottom: 1vh; */
  /* height: 2vh; */
`;
export const TitlePp = styled.p`
  color: red;
  text-align: center;
`;
export const Abutton = styled.button`
  width: 80vw;
  height: 4vh;
  /* margin: 1rem 0; */
  /* padding: 0.5rem 5rem; */
  margin-bottom: 4vh;
  background-color: #5d7b95;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: large;
`;
export const ImageWrapperrrrr = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
