import React, { useEffect, useState, Dispatch } from "react";
import axios from "axios";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { BASE_URL, isDevOrLocal } from "../../api/constants";
import { Plus } from "../../assets/Svgs/Plus";
import Arrow from "../../assets/Svgs/ARROWS.svg";
import usePermissions from "../../hooks/usePermissions";
import {
  ArrowImg,
  BackButton,
  BackWrapper,
  BigWrap,
  CardsColWrapper,
  CardsWrapper,
  HomePageWrap,
  ListColWrapper,
  ListWrap,
  ListWrapper,
  NewWrapper,
  NoNodes,
  NoNodesWrap,
  PlusButton,
  PopupWrapper,
} from "./styles";
import { Card, List } from "../../atoms/Card";
import { atom, selector, useRecoilState, useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { TreeNodeType } from "../TreeNode";
import { useFile } from "../../hooks/useFile";
import { FilePopup } from "./FilePopup";
import { SumPdf, aiState, isLoading, shouldShowState } from "../sumPdf";
import { RenamePopup } from "../RenamePopup";
import { NewCard } from "../../atoms/NewCard/newCard";
import { FileCard } from "../../atoms/FileCard/fileCard";
import { FolderCard } from "../../atoms/FolderCard";
import { SortFiles } from "./SortFiles";

const folderUprising = (a: any, b: any) => {
  const nodeA = a?.props?.node ? a.props.node : a;
  const nodeB = b?.props?.node ? b.props.node : b;
  // const nodeB = b.props.node;
  if ("children" in nodeA && !("children" in nodeB)) {
    return -1;
  } else if ("children" in nodeB && !("children" in nodeA)) {
    return 1;
  }
  return 0;
};
export interface ListOption {
  name: string | undefined;
}
interface IProps {
  rootId: string | undefined;
  setFile?: Dispatch<React.SetStateAction<string>>;
  setSelectedFolder?: Dispatch<
    React.SetStateAction<
      // TreeNodeType
      (TreeNodeType & { onClickFunction: Function }) | undefined
    >
  >;
  name: string;
  setNodes: Dispatch<React.SetStateAction<TreeNodeType[]>>;
  nodes: TreeNodeType[];
  setIsCatalog: Dispatch<React.SetStateAction<boolean>>;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  setIsThereNodes: Dispatch<React.SetStateAction<boolean>>;
  setisFilmCatalog: Dispatch<React.SetStateAction<boolean>>;
  isFilmCatalog: boolean;
  isThereNodes: boolean;
  showModal: boolean;
}

export const NewFolder = ({
  rootId,
  setFile,
  setSelectedFolder,
  name,
  setNodes,
  nodes,
  setIsCatalog,
  setShowModal,
  showModal,
  isThereNodes,
  setIsThereNodes,
  isFilmCatalog,
  setisFilmCatalog,
}: IProps) => {
  const history = useHistory();
  const { setTreeFile } = useFile();

  const { isContentManagerUser, isAdminUser } = usePermissions();
  // const [nodes, setNodes] = useState<TreeNodeType[]>([]);
  const [node, setNode] = useState<TreeNodeType>();
  const [isPdf, setIsPdf] = useState(false);

  const [evenNodes, setEvenNodes] = useState<TreeNodeType[]>([]);
  const [oddNodes, setOddNodes] = useState<TreeNodeType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isNodesLoading, setIsNodesLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [FilesMessage, setFilesMessage] = useState(false);
  const [message, setMessage] = useState<string>("לא נמצאו קבצים");
  const [fileName, setfileName] = useState<string>("");
  const [videoCatalogID, setVideoCatalogID] = useState<string>("");
  const [researchCatalogID, setResearchCatalogID] = useState<string>("");
  const setAiAnswer = useSetRecoilState(aiState);
  const setShouldShow = useSetRecoilState(shouldShowState);
  const setIsLoading = useSetRecoilState(isLoading);
  const [popUpRename, setPopUpRename] = useState<boolean>(false);
  const [nameFile, setNameFile] = useState<string>("");
  const [afterLoadingPopUP, setAfterLoadingPopUp] = useState(false);
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [validData, setValidData] = useState(false);
  const [sortForm, setSortForm] = useState({
    activityType: "",
    individualActivity: "",
    FilmType: "",
    researchType: "",
    year: 0,
  });
  const [textSize, setTextSize] = useState("16px");

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);
  useEffect(() => {
    if (name === "קטלוג הסרטונים") {
      setisFilmCatalog(true);
      setIsCatalog(true);
    } else if (name === "קטלוג התחקירים") {
      setIsCatalog(true);
    } else setIsCatalog(false);
  }, [name]);
  useEffect(() => {
    if (isDevOrLocal) {
      setVideoCatalogID("6550981330e8ade0e4591986");
      setResearchCatalogID("66150db810aeb63c1b88e717");
    } else {
      setVideoCatalogID("663b1f09cfa914a6b907b90d");
      setResearchCatalogID("663b1eeecfa914a6b907b90c");
    }
  }, []);

  const [children, setChildren] = useState([]);
  const [treeArray, setTreeArray] = useState<TreeNodeType[]>([]);

  const { treeFile, resetFile } = useFile();

  const splitStringArrayEvenOdd = (arr: TreeNodeType[]) => {
    const evenArray: TreeNodeType[] = [];
    const oddArray: TreeNodeType[] = [];

    arr.map((str, i) => {
      if (i % 2 === 0) {
        evenArray.push(str);
      } else {
        oddArray.push(str);
      }
    });
    setOddNodes(oddArray);
    setEvenNodes(evenArray);
  };

  const HandleDeleteFile = (fileId: string) => {
    const deleteFileId = nodes.filter((node) => {
      return node._id !== fileId;
    });
    setNodes(deleteFileId);
  };

  const handleFolderClick = async (node: TreeNodeType) => {
    const fileeType = node.name.split(".").slice(-1)[0];
    if (node.displayName.length > 20) {
      setTextSize("14px");
    }
    setShowModal(false);
    if (fileeType === "pdf" || fileeType === "mp4") {
      setFileType(fileeType);
      setShowModal(true);
      setTreeFile({
        filename: node.name,
        fileType: fileeType,
        fileSrc: undefined,
      });
      setNode(node);
      setfileName(node.displayName);
    } else {
      setIsPdf(false);

      setTreeArray((prev: any) => {
        if (
          treeArray.length > 0 &&
          treeArray[treeArray.length - 1].displayName === node.displayName
        ) {
          return [node];
        } else return [...prev, node];
      });
      let nodeRecoil: any;
      setIsNodesLoading(true);
      axios
        .get(`${BASE_URL}/tree/getChildren/${node._id}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.length === 0) {
            // No children, stop loading
            setIsNodesLoading(false);
            setIsThereNodes(false);
            return;
          }

          setChildren(res.data);
          if (
            (res.data[0].displayName.split(".").slice(-1)[0] === "pdf" ||
              res.data[0].displayName.split(".").slice(-1)[0] === "mp4") &&
            !isFilmCatalog &&
            name !== "קטלוג התחקירים"
          ) {
            setIsPdf(true);
          } else setIsPdf(false);
          setIsNodesLoading(false);
        });
      nodeRecoil = { ...node };
      if (nodeRecoil && !nodeRecoil?.onClickFunction) {
        // nodeRecoil.onClickFunction = refreshChildren(node);
      }
      setSelectedFolder && setSelectedFolder(nodeRecoil);
      // } else {
      //   setIsOpen((prev) => !prev);
      //   if (node?.parent) {
      //     axios
      //       .get(`${BASE_URL}/tree/getFile/${node?.parent}`, {
      //         withCredentials: true,
      //       })
      //       .then((res) => {
      //         const parent = res.data;
      //         nodeRecoil = { ...parent };
      //         if (nodeRecoil) {
      //           nodeRecoil.onClickFunction = refreshChildren;
      //         }
      //         setSelectedFolder && setSelectedFolder(nodeRecoil);
      //       });
      //   }
      // }
      setRefresh(true);
    }
  };

  const handleSort = async () => {
    if (
      sortForm.FilmType !== "" ||
      sortForm.activityType !== "" ||
      sortForm.individualActivity !== "" ||
      sortForm.researchType !== "" ||
      sortForm.year !== 0
    ) {
      setIsNodesLoading(true);
      let url = `${BASE_URL}/tree/filtering`;
      await axios.post(url, { sortForm, isFilmCatalog }).then((res) => {
        setIsNodesLoading(false);

        if (res.data.length === 0) {
          setFilesMessage(true);
          setMessage("לא נמצאו קבצים");

          setNodes([]);
        } else {
          setFilesMessage(false);
          setNodes(res.data);
        }
      });
    } else {
      if (name === "קטלוג הסרטונים") {
        setIsNodesLoading(true);
        axios
          .get(`${BASE_URL}/tree/getChildren/${videoCatalogID}`, {
            withCredentials: true,
          })
          .then((res) => {
            setIsNodesLoading(false);
            setFilesMessage(false);

            setNodes(res.data);
          });
      } else {
        setIsNodesLoading(true);

        axios
          .get(`${BASE_URL}/tree/getChildren/${researchCatalogID}`, {
            withCredentials: true,
          })
          .then((res) => {
            setIsNodesLoading(false);
            setFilesMessage(false);

            setNodes(res.data);
          });
      }
    }
  };

  const handleListClick = async (node: TreeNodeType) => {
    const fileeType = node.name.split(".").slice(-1)[0];
    setIsPdf(false);

    let newName = node.displayName;
    const index = treeArray.findIndex((item) => item === node);
    setIsThereNodes(true);
    setFilesMessage(false);
    if (index === -1) {
      // Node not found in the list, add it
      setTreeArray((prev) => [...prev, node]);
    } else {
      // Node found in the list, remove it and all nodes after it
      setTreeArray((prev) => prev.slice(0, index + 1));
    }
    setShowModal(false);
    if (fileeType === "pdf" || fileeType === "mp4") {
      setFileType(fileeType);
      setShowModal(true);
      setTreeFile({
        filename: node.name,
        fileType: fileeType,
        fileSrc: undefined,
      });
      setNode(node);
    } else {
      let nodeRecoil: any;
      setIsNodesLoading(true);
      axios
        .get(`${BASE_URL}/tree/getChildren/${node._id}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.length === 0) {
            // No children, stop loading
            setIsNodesLoading(false);
            setIsThereNodes(false);
            return;
          }

          setChildren(res.data);

          setIsNodesLoading(false);
        });
      nodeRecoil = { ...node };
      if (nodeRecoil && !nodeRecoil?.onClickFunction) {
        nodeRecoil.onClickFunction = refreshChildren(node);
      }
      setSelectedFolder && setSelectedFolder(nodeRecoil);
      // } else {
      //   setIsOpen((prev) => !prev);
      //   if (node?.parent) {
      //     axios
      //       .get(`${BASE_URL}/tree/getFile/${node?.parent}`, {
      //         withCredentials: true,
      //       })
      //       .then((res) => {
      //         const parent = res.data;
      //         nodeRecoil = { ...parent };
      //         if (nodeRecoil) {
      //           nodeRecoil.onClickFunction = refreshChildren;
      //         }
      //         setSelectedFolder && setSelectedFolder(nodeRecoil);
      //       });
      //   }
      // }
      setRefresh(true);
    }
  };

  useEffect(() => {
    if (treeFile) {
      resetFile();
    }
    axios
      .post(`${BASE_URL}/tree/bigFolder`, { rootId }, { withCredentials: true })
      .then((res) => {
        setNodes(res.data);
        splitStringArrayEvenOdd(res.data);
      });
  }, []);

  const refreshChildren = async (node: TreeNodeType) => {
    axios
      .get(`${BASE_URL}/tree/getChildren/${node._id}`, {
        withCredentials: true,
      })

      .then((res) => {
        setChildren(res.data);

        // setRefresh(true);
      });
  };

  useEffect(() => {
    splitStringArrayEvenOdd(nodes);

    // handleFolderClick(nodes[])
    if (nodes.length > 0) {
      if (nodes[0]._id === rootId) {
        handleFolderClick(nodes[0]);
      }
    }
  }, [nodes]);

  useEffect(() => {
    if (children) {
      setNodes(children);
    }
  }, [children]);
  useEffect(() => {
    if (treeArray[treeArray.length - 2] === treeArray[treeArray.length - 1]) {
      treeArray.pop();
    }
  }, [treeArray]);

  return (
    <div>
      {/* <DefaultContainer background={true}> */}
      {!isNodesLoading && (
        <ListWrapper>
          <ListColWrapper>
            <HomePageWrap
              onClick={() => {
                history.push("/");
              }}
            >
              מסך הבית
            </HomePageWrap>

            {treeArray.map((node, index) => {
              const isLastPlace = index === treeArray.length - 1;

              return (
                <React.Fragment key={node._id}>
                  <ArrowImg src={Arrow} alt="arrow"></ArrowImg>

                  <ListWrap isLastPlace={isLastPlace}>
                    <List
                      textSize={textSize}
                      isLastPlace={isLastPlace}
                      onClickFunc={() => {
                        handleListClick(node).then(() => {
                          setNodes(children);
                        });
                      }}
                      name={node.displayName}
                    />
                  </ListWrap>
                </React.Fragment>
              );
            })}
          </ListColWrapper>
        </ListWrapper>
      )}

      {isNodesLoading && (
        <div className="loader">
          טוען <span></span>
        </div>
      )}
      {FilesMessage && (
        <NoNodesWrap>
          <NoNodes>{message}</NoNodes>
        </NoNodesWrap>
      )}
      {!isThereNodes && !isNodesLoading && (
        <NoNodesWrap>
          <NoNodes>{message}</NoNodes>
        </NoNodesWrap>
      )}
      {(name === "קטלוג הסרטונים" && !isNodesLoading) ||
      (name === "קטלוג התחקירים" && !isNodesLoading) ? (
        <SortFiles
          validData={validData}
          onClick={handleSort}
          // isFilm={isFilmCatalog}
          bottomText={"סנן"}
          isFilm={isFilmCatalog}
          setSortForm={setSortForm}
          sortForm={sortForm}
        />
      ) : (
        ""
      )}
      <div>
        {isPdf &&
          isThereNodes &&
          nodes.map((node, i) => {
            return (
              <FolderCard
                key={node._id}
                isIcon={node.isIcon}
                isActive={true}
                text={node.displayName}
                color="#0a0b0b"
                bgColor="#f5f5f5"
                onClickFunc={() => {
                  handleFolderClick(node).then(() => {
                    // setNodes(children);
                  });
                  // history.push("/safetyliterature/safetybooks");
                  // axios.post(
                  //   `${BASE_URL}/areaLogs/addAreaLog`,
                  //   { email, name: CARDS[0] },
                  //   {
                  //     withCredentials: true,
                  //   }
                  // );
                }}
              />
            );
          })}
      </div>
      {!isNodesLoading && !isPdf && isThereNodes && (
        // <BigWrap>
        <CardsWrapper>
          {name === "קטלוג הסרטונים" || name === "קטלוג התחקירים" ? (
            <>
              <CardsColWrapper>
                {evenNodes.map((node, i) => {
                  return (
                    <FileCard
                      key={node._id}
                      isActive={true}
                      text={node.displayName}
                      color="#0a0b0b"
                      bgColor="#f5f5f5"
                      onClickFunc={() => {
                        handleFolderClick(node);
                        // history.push("/safetyliterature/safetybooks");
                        // axios.post(
                        //   `${BASE_URL}/areaLogs/addAreaLog`,
                        //   { email, name: CARDS[0] },
                        //   {
                        //     withCredentials: true,
                        //   }
                        // );
                      }}
                    />
                  );
                })}
              </CardsColWrapper>
            </>
          ) : (
            <NewWrapper>
              {nodes.map((node, i) => {
                return (
                  <FolderCard
                    isActive={true}
                    key={i}
                    isIcon={node.isIcon}
                    text={node.displayName}
                    color="#0a0b0b"
                    bgColor="#f5f5f5"
                    onClickFunc={() => {
                      handleFolderClick(node).then(() => {
                        // setNodes(children);
                      });
                      // history.push("/safetyliterature/safetybooks");
                      // axios.post(
                      //   `${BASE_URL}/areaLogs/addAreaLog`,
                      //   { email, name: CARDS[0] },
                      //   {
                      //     withCredentials: true,
                      //   }
                      // );
                    }}
                  />
                );
              })}
            </NewWrapper>
          )}

          {name === "קטלוג הסרטונים" || name === "קטלוג התחקירים" ? (
            <CardsColWrapper>
              {oddNodes.map((node, i) => {
                return (
                  <FileCard
                    key={node._id}
                    isActive={true}
                    text={node.displayName}
                    color="#0a0b0b"
                    bgColor="#f5f5f5"
                    onClickFunc={() => {
                      handleFolderClick(node);
                      // history.push("/safetyliterature/safetybooks");
                      // axios.post(
                      //   `${BASE_URL}/areaLogs/addAreaLog`,
                      //   { email, name: CARDS[0] },
                      //   {
                      //     withCredentials: true,
                      //   }
                      // );
                    }}
                  />
                );
              })}
            </CardsColWrapper>
          ) : (
            // <CardsColWrapper>
            //   {nodes.map((node, i) => {
            //     return (
            //       <FolderCard
            //         key={node._id}
            //         isIcon={node.isIcon}
            //         isActive={true}
            //         text={node.displayName}
            //         color="#0a0b0b"
            //         bgColor="#f5f5f5"
            //         onClickFunc={() => {
            //           handleFolderClick(node).then(() => {
            //             setNodes(children);
            //           });
            //           // history.push("/safetyliterature/safetybooks");
            //           // axios.post(
            //           //   `${BASE_URL}/areaLogs/addAreaLog`,
            //           //   { email, name: CARDS[0] },
            //           //   {
            //           //     withCredentials: true,
            //           //   }
            //           // );
            //         }}
            //       />
            //     );
            //   })}
            // </CardsColWrapper>
            ""
          )}
        </CardsWrapper>
        // </BigWrap>
      )}
      {popUpRename && (
        <RenamePopup
          afterLoadingPopUP={afterLoadingPopUP}
          nameFile={nameFile} //שם הקובץ
          setNameFile={setNameFile}
          loadingPopUp={loadingPopUp} //הסתרה של הכן/לא
          setLoadingPopUp={setLoadingPopUp}
          setAfterLoadingPopUp={setAfterLoadingPopUp} //אחרי שהוא לחץ כן
          node={node}
          setRefresh={setRefresh} //מרנדר את התצוגה מחדש אחרי השינוי
          setPopUpRename={setPopUpRename} //הצגת הפופ אפ
          refreshParent={refreshChildren}
        />
      )}
      {/* <BackWrapper>
        <BackButton>חזור</BackButton>
      </BackWrapper> */}
      <PopupWrapper>
        <FilePopup
          fileType={fileType}
          node={node}
          onClickFunc={(question, url) => {
            if (url != "") {
              SumPdf(question, url).then((value) => {
                setAiAnswer(value);
                setShouldShow(true);
                setIsLoading(false);
              });
            } else console.log("no url");
          }}
          showModal={showModal}
          setRefresh={setRefresh}
          setShowModal={setShowModal}
          setFile={setFile}
          setNameFile={setNameFile}
          setPopUpRename={setPopUpRename}
          filename={fileName}
          setfileName={setfileName}
          refreshParent={HandleDeleteFile}
        />
      </PopupWrapper>

      {/* </DefaultContainer> */}
    </div>
  );
};
