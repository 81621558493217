import styled from "styled-components";

export const TextArea = styled.textarea`
  width: 83vw;
  margin-right: 5vw;
  height: 10vh;
  background-color: #e4e4e4;
  color: white;
  outline: none;
  font: inherit;

  font-size: 1rem;
  /* border-radius: 2vw; */
  display: block;
  float: right;
  /* color-adjust: black; */
  color: #070707;
  background-image: none;
  border: 1px solid #a39e9e;
  border-radius: 0px;
`;
