import styled from "styled-components";
import Filmkatalog from "../../assets/film ktatalog 1.png";
import { useEffect, useRef, useState } from "react";
interface IProps {
  text: string;
  url?: string;
  color: string;
  bgColor: string;
  isActive: boolean;
  onClickFunc?: () => void;
  textSize?: Number;
}

interface cardProps {
  color: string;
  isActive: boolean;
  textSize?: number;
  height: number | null;
}


// const logos: any = {
//   'ענ"א': location,
//   "הידע בבטיחות": contentManager,
//   "ניהול הבטיחות": safteySchedule,
//   פיזיולוגי: physioligical,
//   "הכשרות וקורסים": academyIcon,
//   "ספרות הבטיחות": Raz,
//   "קטלוג התחקירים": Pdf,
//   pdf: Pdf,
//   "קטלוג הסרטונים": MP4,
//   mp4: MP4,
//   'ביה"ס לבטיחות': GraduationHat,
//   "מערכות בטיחות": SafetySystem,
//   "נושאים במיקוד": Light,
//   "תחומים מקצועיים": HandBag,
//   "בטיחות באימונים והכשרות": Tank,
//   "בסיס חכם": coneIcon,
//   "אינדקס שריפות": FireLogo,
// };

export const FileCard = ({
  text,
  color,
  isActive,
  onClickFunc,
}: IProps) => {
  let logo = text;
  const fileType = text.split(".").slice(-1)[0];
  const realText = text.slice(0, -4);
  if (fileType === "pdf" || "mp4") {
    logo = fileType;
  }
  const [height, setHeight] = useState("");
  const [textHeight, setTextHeight] = useState<number | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (text.length < 30) {
      setHeight("13vh");
    } else if (text.length < 40) {
      setHeight("15vh");
    } else if (text.length < 50) {
      setHeight("17vh");
    } else if (text.length < 80) {
      setHeight("26vh");
    } else setHeight("28vh");
  }, [text]);
  useEffect(() => {
    if (textRef.current) {
      setTextHeight(textRef.current.clientHeight);
    }
  }, [realText]);

  return (
    <Wrapper
      onClick={() => {
        isActive && onClickFunc && onClickFunc();
      }}
    >
      <CardWrapper
        onClick={() => {
          isActive && onClickFunc && onClickFunc();
        }}
        color={color}
        isActive={isActive}
        className={isActive ? "" : "active"}
        height={textHeight}
      >
        {" "}
      </CardWrapper>
      <Text ref={textRef}>{realText}</Text>
      <BottomDiv>
        {fileType === "mp4" ? (
          <Img src={Filmkatalog}></Img>
        ) : (
          <BottomText>תחקיר בטיחות</BottomText>
        )}
      </BottomDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 11px;
  width: 35vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2vh;
`;

const CardWrapper = styled.div<cardProps>`
  border-radius: 11px;
  min-width: 35vw;
  height: ${(p: cardProps) => (p.height ? `${p.height + 40}px` : "auto")};
  background-color: #ffffff;
  position: absolute;
  transform: translate3d(0, 0, 0);

  mix-blend-mode: soft-light !important;
  /* background-filter: grayscale(80%); */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
`;

const Text = styled.p`
  width: 30vw;
  text-align: center;
  font-size: 18px;

  color: #ffffff;
`;
const BottomDiv = styled.div`
  width: 35vw;
  height: 7vh;
  background-color: #ffffff;
  font-size: 18px;
  color: #ffffff;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Img = styled.img`
  width: 3.5rem;
  height: 2.5rem;
`;
const BottomText = styled.b`
  color: #23344e;
  font-size: 20px;
`;
