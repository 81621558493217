import physioligical from "../../assets/runer 1.png";
import Icon1 from "../../assets/Svgs/Redesign/New Icons 001-06.svg";
import Icon2 from "../../assets/Svgs/Redesign/New Icons 002-06.svg";
import Icon3 from "../../assets/Svgs/Redesign/New Icons 003-06.svg";
import Icon4 from "../../assets/Svgs/Redesign/New Icons 004-06.svg";
import Icon5 from "../../assets/Svgs/Redesign/New Icons 005-06.svg";
import Icon6 from "../../assets/Svgs/Redesign/New Icons 006-06 (1).svg";
import Icon7 from "../../assets/Svgs/Redesign/New Icons 007-06.svg";
import Icon8 from "../../assets/Svgs/Redesign/New Icons 008-06.svg";
import Icon9 from "../../assets/Svgs/Redesign/New Icons 009-06.svg";
import Icon10 from "../../assets/Svgs/Redesign/New Icons 010-06.svg";
import Icon11 from "../../assets/Svgs/Redesign/New Icons 011-06.svg";
import Icon12 from "../../assets/Svgs/Redesign/New Icons 012-06.svg";
import Icon13 from "../../assets/Svgs/Redesign/New Icons 013-06.svg";
import Icon14 from "../../assets/Svgs/Redesign/New Icons 014-06.svg";
import Icon15 from "../../assets/Svgs/Redesign/New Icons 015-06.svg";
import Icon18 from "../../assets/Svgs/Redesign/New Icons 018-06.svg";
import Icon21 from "../../assets/Svgs/Redesign/New Icons 021-06.svg";
import Icon22 from "../../assets/Svgs/Redesign/New Icons 022-06.svg";
import Icon24 from "../../assets/Svgs/Redesign/New Icons 024-06.svg";
import Icon25 from "../../assets/Svgs/Redesign/New Icons 025-06.svg";
import Icon26 from "../../assets/Svgs/Redesign/New Icons 026-06.svg";
import Icon27 from "../../assets/Svgs/Redesign/New Icons 027-06.svg";
import Icon28 from "../../assets/Svgs/Redesign/New Icons 028-06.svg";
import Icon29 from "../../assets/Svgs/Redesign/New Icons 029-06.svg";
import Icon30 from "../../assets/Svgs/Redesign/New Icons 030-06.svg";
import Icon31 from "../../assets/Svgs/Redesign/New Icons 031-06.svg";
import Icon32 from "../../assets/Svgs/Redesign/New Icons 032-06.svg";
import Icon33 from "../../assets/Svgs/Redesign/New Icons 033-06.svg";
import Icon34 from "../../assets/Svgs/Redesign/New Icons 034-06.svg";
import Icon35 from "../../assets/Svgs/Redesign/New Icons 035-06.svg";
import Icon36 from "../../assets/Svgs/Redesign/New Icons 036-06.svg";
import Icon37 from "../../assets/Svgs/Redesign/New Icons 037-06.svg";
import books from "../../assets/Svgs/2 1.svg";
import proffesinal from "../../assets/Svgs/proffisional 1.svg";
import school from "../../assets/Svgs/saftiey school 1.svg";
import target from "../../assets/Svgs/target 1.svg";
import researchCatalog from "../../assets/Svgs/Group 695.svg";
import filmCatalog from "../../assets/Svgs/film ktatalog 1 (1).svg";
import Icon38 from "../../assets/Svgs/Redesign/New Icons 038-06.svg";
import Plus from "../../assets/Svgs/plus 2.svg";
import { CARDS } from "../../constants";
import { CardWrapper, ImgWrapper, ListDiv, Text } from "./styles";

interface IProps {
  text: string;
  url?: string;
  color?: string;
  bgColor?: string;
  isActive: boolean;
  onClickFunc?: () => void;
  textSize?: Number;
  marginBottom?: boolean;
  textWidth?: string;
}

interface listProps {
  name: string;
  onClickFunc?: () => void;
}

// const logos: any = {
//   'ענ"א': location,
//   "הידע בבטיחות": contentManager,
//   "ניהול הבטיחות": safteySchedule,
//   פיזיולוגי: physioligical,
//   "הכשרות וקורסים": academyIcon,
//   "ספרות הבטיחות": Raz,
//   "קטלוג התחקירים": Pdf,
//   pdf: Pdf,
//   "קטלוג הסרטונים": MP4,
//   mp4: MP4,
//   'ביה"ס לבטיחות': GraduationHat,
//   "מערכות בטיחות": SafetySystem,
//   "נושאים במיקוד": Light,
//   "תחומים מקצועיים": HandBag,
//   "בטיחות באימונים והכשרות": Tank,
//   "בסיס חכם": coneIcon,
//   "אינדקס שריפות": FireLogo,
// };
const logos: any = {
  [CARDS[0]]: Icon1,
  [CARDS[1]]: Icon2,
  [CARDS[2]]: Icon3,
  [CARDS[3]]: Icon4,
  [CARDS[4]]: Icon5,
  [CARDS[5]]: Icon6,
  [CARDS[6]]: Icon7,
  [CARDS[7]]: Icon8,
  [CARDS[8]]: Icon9,
  [CARDS[9]]: Icon10,
  [CARDS[10]]: Icon11,
  [CARDS[11]]: Icon12,
  [CARDS[12]]: Icon13,
  [CARDS[13]]: Icon14,
  [CARDS[14]]: Icon15,
  [CARDS[15]]: Icon21,
  [CARDS[16]]: Icon22,
  [CARDS[17]]: Icon24,
  [CARDS[18]]: Icon25,
  [CARDS[19]]: Icon26,
  [CARDS[20]]: Icon27,
  [CARDS[21]]: Icon28,
  [CARDS[22]]: Icon29,
  [CARDS[23]]: Icon30,
  [CARDS[24]]: Icon31,
  [CARDS[25]]: Icon32,
  [CARDS[26]]: Icon33,
  [CARDS[27]]: Icon34,
  [CARDS[28]]: Icon35,
  [CARDS[29]]: Icon36,
  [CARDS[30]]: Icon37,
  [CARDS[31]]: Icon38,
  [CARDS[32]]: books,
  [CARDS[33]]: proffesinal,
  [CARDS[34]]: Icon18,
  [CARDS[35]]: school,
  [CARDS[36]]: target,
  [CARDS[37]]: filmCatalog,
  [CARDS[38]]: researchCatalog,
  [CARDS[39]]: physioligical,
  [CARDS[40]]: Plus,
};

export const NewCard = ({
  text,
  url,
  color,
  bgColor,
  isActive,
  onClickFunc,
  textSize,
  marginBottom,
  textWidth,
}: IProps) => {
  let logo = text;
  const fileType = text.split(".").slice(-1)[0];

  if (fileType === "pdf" || "mp4") {
    logo = fileType;
  }

  return (
    <>
      <CardWrapper
        marginBottom={marginBottom}
        onClick={() => {
          isActive && onClickFunc && onClickFunc();
        }}
      >
        <ImgWrapper
          onClick={() => {
            isActive && onClickFunc && onClickFunc();
          }}
          color={bgColor}
          isActive={isActive}
          src={logos[logo]}
          alt="logo"
        />
        <Text
          onClick={() => {
            isActive && onClickFunc && onClickFunc();
          }}
          textWidth={textWidth}
        >
          {text}
        </Text>
      </CardWrapper>
    </>
  );
};
export const List = ({ name, onClickFunc }: listProps) => {
  return (
    <ListDiv
      onClick={() => {
        onClickFunc && onClickFunc();
      }}
    >
      {name}
    </ListDiv>
  );
};
