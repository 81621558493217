import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoCaretBack, IoCaretDown } from "react-icons/io5";
import { FcFolder, FcOpenedFolder } from "react-icons/fc";
import axios from "axios";
import { Spinner, TreeNode, TreeNodeType } from "../TreeNode";
import { LShape } from "../../assets/files/fileTreeSvgs";
import { BASE_URL } from "../../api/constants";
import LineTo from "react-lineto";

interface Props {
  node: TreeNodeType;
  isSecondLevel: boolean;
  refresh: boolean;
  setRefresh: any;
  refreshParent?: Function;
  setFile: React.Dispatch<React.SetStateAction<string>>;
  setSelectedFolder?: React.Dispatch<
    React.SetStateAction<
      // TreeNodeType
      (TreeNodeType & { onClickFunction: Function }) | undefined
    >
  >;
}

const folderUprising = (a: any, b: any) => {
  const nodeA = a?.props?.node ? a.props.node : a;
  const nodeB = b?.props?.node ? b.props.node : b;
  // const nodeB = b.props.node;
  if ("children" in nodeA && !("children" in nodeB)) {
    return -1;
  } else if ("children" in nodeB && !("children" in nodeA)) {
    return 1;
  }
  return 0;
};

export const Folder: React.FC<Props> = ({
  node,
  isSecondLevel,
  setRefresh,
  refresh,
  refreshParent,
  setSelectedFolder,
  setFile,
}) => {
  let firstFile: TreeNodeType | undefined;
  const [children, setChildren] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [toAnchor, setToAnchor] = useState("191% 75%");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (toAnchor === "190% 75%") setToAnchor("191% 75%");
    else setToAnchor("190% 75%");
    setRefresh(true);
  }, [refresh]);

  useEffect(() => {
    setRefresh(true);
  }, [children]);

  const refreshChildren = async () => {
    axios
      .get(`${BASE_URL}/tree/getChildren/${node._id}`, {
        withCredentials: true,
      })

      .then((res) => {
        setChildren(res.data.sort(folderUprising));

        // setRefresh(true);
      });
  };

  const handleFolderClick = async () => {
    let nodeRecoil: any;
    if (!isOpen) {
      setIsLoading(true);
      setIsOpen((prev) => !prev);
      axios
        .get(`${BASE_URL}/tree/getChildren/${node._id}`, {
          withCredentials: true,
        })
        .then((res) => {
          setChildren(res.data);
          setIsLoading(false);
        });
      nodeRecoil = { ...node };
      if (nodeRecoil && !nodeRecoil?.onClickFunction) {
        nodeRecoil.onClickFunction = refreshChildren;
      }
      setSelectedFolder && setSelectedFolder(nodeRecoil);
    } else {
      setIsOpen((prev) => !prev);
      if (node?.parent) {
        axios
          .get(`${BASE_URL}/tree/getFile/${node?.parent}`, {
            withCredentials: true,
          })
          .then((res) => {
            const parent = res.data;
            nodeRecoil = { ...parent };
            if (nodeRecoil) {
              nodeRecoil.onClickFunction = refreshChildren;
            }
            setSelectedFolder && setSelectedFolder(nodeRecoil);
          });
      }
    }
    setRefresh(true);
  };

  return (
    <>
      {!isSecondLevel && <LShape />}
      <FolderNameWrapper
        isOpen={isOpen}
        isEmpty={!node.children?.length}
        onClick={handleFolderClick}
      >
        {isOpen ? (
          <FcOpenedFolder className={`folder ${node._id}`} />
        ) : (
          <FcFolder className={`folder ${node._id}`} />
        )}
        {/* <Rect className={node._id} /> */}
        <div style={{ margin: "1.1rem 0.5rem", bottom: "2rem" }}>
          {node.displayName}
        </div>
        <div style={{ marginTop: ".3rem" }}>
          {node.children?.length ? (
            isOpen ? (
              <IoCaretDown />
            ) : (
              <IoCaretBack />
            )
          ) : (
            <></>
          )}
        </div>
        {/* <button
          onClick={() => {
            axios.post(
              `${BASE_URL}/tree/deleteFolder`,
              { node },
              {
                withCredentials: true,
              }
            );
          }}
        >
          מחיקה
        </button> */}
      </FolderNameWrapper>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {
            <LineTo
              borderWidth={1.5}
              from={node.parent ? node.parent : ""}
              to={node._id}
              fromAnchor={"50% 50%"}
              toAnchor={toAnchor}
              borderColor={"#a1a1a1"}
              zIndex={-1}
            />
          }
          {isOpen && (
            <div>
              <ChildrenWrapper>
                {children &&
                  children
                    .map((child: TreeNodeType, index) => {
                      if (!firstFile && !("children" in child)) {
                        // firstFile = child;
                        return (
                          <TreeNode
                            key={child.displayName}
                            setFile={setFile}
                            firstFile={true}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            isSecondLevel={false}
                            node={child}
                            refreshParent={refreshChildren}
                            setSelectedFolder={setSelectedFolder}
                          />
                        );
                      } else {
                        return (
                          <TreeNode
                            key={child.displayName}
                            refresh={refresh}
                            setFile={setFile}
                            setRefresh={setRefresh}
                            isSecondLevel={false}
                            node={child}
                            refreshParent={refreshChildren}
                            setSelectedFolder={setSelectedFolder}
                          />
                        );
                      }
                    })
                    .sort(folderUprising)}
              </ChildrenWrapper>
            </div>
          )}
        </>
      )}
    </>
  );
};

const FolderNameWrapper = styled.div<{ isOpen: boolean; isEmpty: boolean }>`
  margin-right: 1.6rem;
  height: 50px;
  display: flex;

  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: ${({ isEmpty }) => isEmpty && "gray"};
  font-weight: ${({ isOpen }) => (isOpen ? 600 : 400)};
  .folder {
    margin-top: 5px;
    border-radius: 8px;
    z-index: 1;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
  }
`;

const ChildrenWrapper = styled.div`
  margin-right: 2rem;
`;

const Rect = styled.div`
  margin-top: 5px;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  z-index: 1;
  border-radius: 8px;
  background-color: #8fc8fa;
`;

export default Folder;
