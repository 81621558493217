import styled from "styled-components";
import { MdAccessTime } from "react-icons/md";
import { Dispatch } from "react";

interface IProps {
  setSearchTerm: Dispatch<React.SetStateAction<string>>;
  name: string;
}

export const LastSearches = ({ setSearchTerm, name }: IProps) => {
  return (
    <NameAndIcon
      onClick={() => {
        setSearchTerm(name);
      }}
    >
      <Icon>
        <MdAccessTime />
      </Icon>
      <Name>{name}</Name>
    </NameAndIcon>
  );
};

const NameAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
  margin-right: 0.5rem;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  margin-left: 0.5rem;
  color: #8f8f8f;
`;
