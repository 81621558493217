import styled from "styled-components";

export const UpdatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  max-width: 90vw;
  min-width: 90vw;
  max-height: 30vh;
  margin-bottom: 3vh;
  position: relative;
`;
export const Top = styled.div`
  width: 100%;
  font-weight: 600;
  height: 5vh;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: row;
  background-color: #cdeaff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;
export const P = styled.div`
  color: #23344e;
  width: 80vw;
  height: 5vh;
  font-family: "Assistant";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
export const P0 = styled.p`
  margin-left: 1vw;
  margin-right: 1vw;
`;
export const Vector = styled.div`
  width: 26px;
  height: 2px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-right: 10rem;
  background: linear-gradient(
    90deg,
    #23344e 93.72%,
    #ffffff 101.37%,
    #dfe1e5 101.38%,
    rgba(255, 255, 255, 0) 101.39%
  );
`;
export const MainBottom = styled.div`
  width: 99.2%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cdeaff;
  border: 1px solid #cdeaff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: 1px solid rgba(80, 101, 132, 0.63);
`;
export const ContactBottom = styled.div`
  width: 99.2%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cdeaff;
  border: 1px solid #cdeaff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  flex-direction: column;
`;
export const ButtonBackground = styled.div`
  width: 99.2%;
  /* height: 20vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cdeaff;
`;
export const BottomBackground = styled.div`
  width: 99.2%;
  /* height: 20vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cdeaff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
`;
interface Ibutton {
  border: boolean;
}
export const ButtonWrap = styled.div<Ibutton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #cdeaff;
  height: 15vh;
  border-bottom-left-radius: ${(props) => (props.border ? "1rem" : "")};
  border-bottom-right-radius: ${(props) => (props.border ? "1rem" : "")};
`;
export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 4vh;
  background-color: #23344e;
  color: white;
  border-radius: 0.7rem;
  margin-top: 1vh;
`;
export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const PlusImg = styled.img`
  margin-left: 2vw;
`;
