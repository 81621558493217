import React from "react";
import styled from "styled-components";
import { bool } from "prop-types";
import blueBg from "../../assets/Rectangle 161.png";
import { Middle } from "./Middle";
import { Bottom } from "./Bottom";
import usePermissions from "../../hooks/usePermissions";
import { UserTop } from "./UserTop";
import { AdminTop } from "./AdminTop";

interface IProps {
  open: Boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  background?: string;
  numberNotification: number;
}

export const Menu = ({ open, setMenuOpen, numberNotification }: IProps) => {


  const { isAdminUser } = usePermissions();
  return (
    <StyledMenu
      numberNotification={0}
      setMenuOpen={setMenuOpen}
      background="blue"
      open={open}
    >
      <Options>
        {isAdminUser ? (
          <AdminTop
            numberNotification={numberNotification}
            setMenuOpen={setMenuOpen}
          />
        ) : (
          <UserTop setMenuOpen={setMenuOpen} />
        )}
        <Middle />
        <Bottom />
        {/* <Section>

          <FiLink />
          <p>קישורים</p>
        </Section>
        <Option onClick={() => history.push("/smartBasePage")}>
          - בסיס חכם
        </Option>
        <Option href="https://wiz.medone.idf.il/mu//icr/bot.aspx?l=18795">
          - תמרון בקליק - Bot
        </Option>
        <Option href="https://ims.gov.il/he/Fires">- שירות מטאורולוגי</Option>
        <Section>
          <PersonalArea />
          <p>האזור האישי</p>
        </Section>
        <Option href="/contact">- צור קשר</Option>
        <Option href={`${BASE_URL}/auth/logout`}>- התנתק</Option> */}
      </Options>
    </StyledMenu>
  );
};
Menu.propTypes = {
  open: bool.isRequired,
};



const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
`;

const StyledMenu = styled.nav<IProps>`
  position: absolute;
  background-image: ${(props: IProps) =>
    props.background === "blue" ? `url(${blueBg})` : `url(${blueBg})`};
  /* top: 0; */
  left: 0;
  /* z-index: 100; */
  height: 95vh;
  width: 72vw;
  display: flex;
  flex-direction: column;
`;

