import React, { useEffect, useState } from "react";
import {
  DeleteButton,
  InjuriesNumberSelect,
  InjuriesSelect,
  InjuryWrapper,
} from "./styles";

export interface InjuryOption {
  injuryLevel: string | undefined;
  numOfInjuries: number;
}

export const Injury = ({
  setInjuries,
  injuryLevelInput,
  numOfInjuriesInput,
  placeholder,
  isDisabled,
}: {
  setInjuries: React.Dispatch<React.SetStateAction<InjuryOption[]>>;
  injuryLevelInput: string | undefined;
  numOfInjuriesInput: number | undefined | string;
  placeholder: string | undefined;
  isDisabled: boolean;
}) => {
  const [injuryLevel, setInjuryLevel] = useState<string>(
    injuryLevelInput || ""
  );
  const [numOfInjuries, setNumberOfInjuries] = useState<number | string>(
    numOfInjuriesInput || 0
  );

  useEffect(() => {
    if (
      injuryLevel &&
      numOfInjuries !== "הכנס כמות פצועים" &&
      !isDisabled &&
      injuryLevel !== "בחר/י"
    ) {
      setInjuries((prev) => {
        prev = prev.filter((inj) => inj.injuryLevel !== injuryLevel);
        return [...prev, { injuryLevel, numOfInjuries: Number(numOfInjuries) }];
      });
      setInjuryLevel("בחר/י");
      setNumberOfInjuries(0);
    }
  }, [injuryLevel, numOfInjuries]);

  return (
    <InjuryWrapper>
      <InjuriesNumberSelect
        value={numOfInjuries || ""}
        type="number"
        placeholder="כמות פצועים לפי חומרה"
        onChange={(e) => {
          setNumberOfInjuries(Number(e.target.value));
        }}
      />
      <InjuriesSelect
        disabled={isDisabled}
        value={injuryLevel}
        onChange={(e) => {
          setInjuryLevel(e.target.value);
        }}
      >
        {injuriesLevelArr.map((injuriesLevel) => {
          return <option value={injuriesLevel}>{injuriesLevel}</option>;
        })}
      </InjuriesSelect>

      {isDisabled && (
        <DeleteButton
          onClick={() => {
            setInjuries((prev) => {
              prev = prev.filter((inj) => inj.injuryLevel !== injuryLevel);
              return prev;
            });
          }}
        >
          -
        </DeleteButton>
      )}
    </InjuryWrapper>
  );
};
export const injuriesLevelArr = [
  "בחר/י",
  "ללא פגיעה",
  "פצוע קל (ללא אשפוז)",
  "פצוע קל (שאושפז)",
  "פצוע בינוני",
  "פצוע קשה/אנוש",
  "חלל",
];
