import { Location, P, SvgLocation, SvgWrapper } from "./styles";

interface Iprops {
  locationMes: string;
  setLatitude: React.Dispatch<number>;
  setLongitude: React.Dispatch<number>;
  setLocationMes: React.Dispatch<string>;
}

export const LocationNtz = ({
  locationMes,
  setLatitude,
  setLongitude,
  setLocationMes,
}: Iprops) => {
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const showPosition = (position: {
    coords: { latitude: number; longitude: number };
  }) => {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
    setLocationMes("מיקומך נקלט בהצלחה");
  };

  return (
    <Location>
      <SvgWrapper>
        <SvgLocation
          onClick={getLocation}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
        </SvgLocation>
      </SvgWrapper>
      <P>לחץ לשמירת נ.צ של מיקומך הנוכחי במיקום האירוע</P>
      <b>{locationMes}</b>
    </Location>
  );
};
