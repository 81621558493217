import { ChangeEvent } from "react";
import { FieldName, FieldWrapper, Span } from "../styles";
import { TextArea } from "./styles";

interface IProp {
  fieldLabel: string;
  value: string;
  placeHolder: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  isRequired?: boolean;
}
export const TextAreaField = ({
  fieldLabel,
  onChange,
  value,
  isRequired,
  placeHolder,
}: IProp) => {
  return (
    <FieldWrapper>
      <FieldName>
        {fieldLabel}
        {isRequired && <Span>*</Span>}{" "}
      </FieldName>
      <TextArea
        value={value}
        placeholder={placeHolder}
        onChange={onChange}
      ></TextArea>
    </FieldWrapper>
  );
};
