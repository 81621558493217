import { Div, Div2, GoldLine, HeaderStyle, HeaderWarper } from "./styles";

export const BottomDefaultContainer = () => {
  return (
    <>
      <HeaderWarper>
        <GoldLine />
        <HeaderStyle>
          <Div>מבק"א יבשה - </Div>
          <Div2> מעצבים תרבות לניצחון בטוח</Div2>
        </HeaderStyle>
      </HeaderWarper>
    </>
  );
};
