import styled from "styled-components";

export const BigWrapper = styled.div`
  width: 100vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
  margin-bottom: 1vh;
`;
export const Wrapper = styled.div`
  width: 90vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ImgWrapper = styled.div`
  width: 25vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Img = styled.img`
  width: 11rem;
  height: 12vh;
`;
