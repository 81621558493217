import React, { useState } from "react";

import {
  ModalBackground,
  ModalBody,
  TextDiv,
  BoldWhatsapp,
  X,
  FieldName,
  Answer,
} from "./styles";
import { useSetRecoilState } from "recoil";
import { shouldShowState } from "../../../sumPdf";

interface ModalProps {
  children: React.ReactNode;
  shouldShow: boolean;
}

export const AiModal = ({ children, shouldShow }: ModalProps) => {
  //   const [shouldShow, setShouldShow] = useState(false);
  const setShouldShow = useSetRecoilState(shouldShowState);

  return (
    <>
      {/* <button onClick={() => setShouldShow(true)}>Show</button> */}
      {shouldShow && (
        <ModalBackground onClick={() => setShouldShow(false)}>
          <ModalBody onClick={(e) => e.stopPropagation()}>
            <X onClick={() => setShouldShow(false)}>X</X>
            <Answer>תשובה:</Answer>
            <TextDiv>{children}</TextDiv>
            <FieldName>
              שים לב! תקציר זה אינו מחליף את קריאת ההוראה במלואה. הנך מחויב
              להעמיק בהוראה טרם ביצוע המשימה
            </FieldName>
          </ModalBody>
        </ModalBackground>
      )}
    </>
  );
};
