import React, { useState } from "react";
import { ModalBackground, ModalBody, TextDiv, X } from "../NewToolTip/styles";

interface ModalProps {
  children: React.ReactNode;
  shouldShow: boolean;
  setShouldShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal = ({ children, shouldShow, setShouldShow }: ModalProps) => {
  //   const [shouldShow, setShouldShow] = useState(false);
  return (
    <>
      {/* <button onClick={() => setShouldShow(true)}>Show</button> */}
      {shouldShow && (
        <ModalBackground onClick={() => setShouldShow(false)}>
          <ModalBody onClick={(e) => e.stopPropagation()}>
            <X onClick={() => setShouldShow(false)}>X</X>
            <TextDiv>{children}</TextDiv>
          </ModalBody>
        </ModalBackground>
      )}
    </>
  );
};
