import styled from "styled-components";

export const DateAndTime = styled.div`
  display: flex;
  flex-direction: row;
  width: 92vw;
  /* margin-left: 5vw; */

  justify-content: space-evenly;
`;
export const FieldWrapperDate = styled.div`
  display: flex;
  width: 40vw;
  flex-direction: column;
  margin-bottom: 0.3rem;
  margin-left: 5vw;
`;

export const FieldNameDate = styled.p`
  margin-right: 5vw;
  width: 20vw;
  height: 2vh;
  font-size: 1.2rem;
`;
export const DateInputt = styled.input`
  width: 38vw;
  height: 5vh;
  appearance: none;
  outline: none;
  margin-right: 5vw;
  padding-right: 5px;
  color: white;
  border: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  font-size: 1.2rem;
  font-weight: 600;
  /* display: block; */
  color: #333;
  border: 1px solid #a39e9e;
  -ms-word-break: normal;
  word-break: normal;
  display: inline-block;
  font: inherit;

  /* background-image: linear-gradient(45deg, transparent 50%, #545252 50%),
    linear-gradient(135deg, #545252 50%, transparent 50%);
  background-position: calc(10% - 5px) calc(2em - 12px),
    calc(10% - 1px) calc(0.9em + 6px), 10% 0;
  background-size: 5px 5px, 5px 7px, 5em 3em;
  background-repeat: no-repeat;
  :focus {
    background-image: linear-gradient(45deg, #545252 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #545252 50%);
    background-position: calc(15% - 5.5px) 0.9em, calc(15% - 10px) 0.9em, 10% 0;
    background-size: 4px 6px, 5px 6px, 2.5em 4em;

    background-repeat: no-repeat;
  }
  ::-webkit-calendar-picker-indicator {
    background: none;
  } */
`;
export const Time = styled.input`
  width: 38vw;
  height: 5vh;
  appearance: none;
  outline: none;
  margin-right: 5vw;
  padding-right: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0px;
  /* display: block; */
  color: #333;
  border: 1px solid #a39e9e;
  -ms-word-break: normal;
  word-break: normal;
  display: inline-block;
  font: inherit;

  /* background-image: linear-gradient(45deg, transparent 50%, #545252 50%),
    linear-gradient(135deg, #545252 50%, transparent 50%);
  background-position: calc(10% - 5px) calc(2em - 12px),
    calc(10% - 1px) calc(0.9em + 6px), 10% 0;
  background-size: 5px 5px, 5px 7px, 5em 3em;
  background-repeat: no-repeat;
  :focus {
    background-image: linear-gradient(45deg, #545252 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #545252 50%);
    background-position: calc(15% - 5px) 0.9em, calc(15% - 10px) 0.9em, 10% 0;
    background-size: 4px 6px, 5px 6px, 2.5em 4em;

    background-repeat: no-repeat;
  }
  ::-webkit-calendar-picker-indicator {
    background: none;
  } */
`;
