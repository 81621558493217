export const editorLabels = {
    // Generic
    'generic.add': 'הוסף',
    'generic.cancel': 'בטל',
  
    // BlockType
    'components.controls.blocktype.h1': 'כותרת 1',
    'components.controls.blocktype.h2': 'כותרת 2',
    'components.controls.blocktype.h3': 'כותרת 3',
    'components.controls.blocktype.h4': 'כותרת 4',
    'components.controls.blocktype.h5': 'כותרת 5',
    'components.controls.blocktype.h6': 'כותרת 6',
    'components.controls.blocktype.blockquote': 'מרכאות',
    'components.controls.blocktype.code': 'קוד',
    'components.controls.blocktype.blocktype': 'בלוק',
    'components.controls.blocktype.normal': 'רגיל',
  
    // Color Picker
    'components.controls.colorpicker.colorpicker': 'בחר צבע',
    'components.controls.colorpicker.text': 'טקסא',
    'components.controls.colorpicker.background': 'הדגש',
  
    // Embedded
    'components.controls.embedded.embedded': 'Embedded',
    'components.controls.embedded.embeddedlink': 'Embedded Link',
    'components.controls.embedded.enterlink': 'Enter link',
  
    // Emoji
    'components.controls.emoji.emoji': "אמוג'י",
  
    // FontFamily
    'components.controls.fontfamily.fontfamily': 'גופן',
  
    // FontSize
    'components.controls.fontsize.fontsize': 'גודל גופן',
  
    // History
    'components.controls.history.history': 'היסטוריה',
    'components.controls.history.undo': 'אחורה',
    'components.controls.history.redo': 'קדימה',
  
    // Image
    'components.controls.image.image': 'תמונה',
    'components.controls.image.fileUpload': 'קובץ העלאה',
    'components.controls.image.byURL': 'קישור',
    'components.controls.image.dropFileText': 'העתק קובץ או לחץ כאן כדי להעלות',
  
    // Inline
    'components.controls.inline.bold': 'הדגש',
    'components.controls.inline.italic': 'נטוי',
    'components.controls.inline.underline': 'קו תחתון',
    'components.controls.inline.strikethrough': 'קו חוצה',
    'components.controls.inline.monospace': 'רווח קבוע',
    'components.controls.inline.superscript': 'כתב עילי',
    'components.controls.inline.subscript': 'כתב תחתי',
  
    // Link
    'components.controls.link.linkTitle': 'כותרת קישור',
    'components.controls.link.linkTarget': 'קישור',
    'components.controls.link.linkTargetOption': 'פתח קישור בחלון חדש',
    'components.controls.link.link': 'קישור',
    'components.controls.link.unlink': 'בטל קישור',
  
    // List
    'components.controls.list.list': 'רשימה',
    'components.controls.list.unordered': 'רשימה לא ממוינת',
    'components.controls.list.ordered': 'רשימה ממוינת',
    'components.controls.list.indent': 'הגדל כניסה',
    'components.controls.list.outdent': 'הקטן כניסה',
  
    // Remove
    'components.controls.remove.remove': 'הסר',
  
    // TextAlign
    'components.controls.textalign.textalign': 'יישור טקסט',
    'components.controls.textalign.left': 'שמאל',
    'components.controls.textalign.center': 'מרכז',
    'components.controls.textalign.right': 'ימין',
    'components.controls.textalign.justify': 'יישור',
  };