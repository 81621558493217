import styled from "styled-components";

export const Title = styled.p`
  width: 100vw;
  height: 15vh;
  font-size: 1.5rem;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
`;
export const Bold = styled.b`
  font-size: 1.2rem;
`;
export const TitleWrapper = styled.div`
  width: 90vw;
  height: 17vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;
export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 10vh;
`;
export const Li = styled.div`
  width: 80vw;
  height: fit-content;
  margin-bottom: 0.5rem;
  /* margin-top: 1rem; */
`;
// export const Ol = styled.div`
//   width: 70vw;
//   height: fit-content;
// `;
