import React from "react";
import styled from "styled-components";

interface IProps {
  marginRight?: number;
  text: string;
  clicked: boolean;
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeatCheckBox = ({
  text,
  clicked,
  setClicked,
  marginRight,
}: IProps) => {
  return (
    <>
      <CheckboxContainer marginRight={marginRight} clicked={clicked}>
        <Checkbox clicked={clicked} onClick={() => setClicked((prev) => !prev)}>
          <div className="checkMark">{"✔"}</div>
        </Checkbox>
        <CheckboxText onClick={() => setClicked((prev) => !prev)}>
          {text}
        </CheckboxText>
      </CheckboxContainer>
    </>
  );
};

const CheckboxContainer = styled.div<{
  clicked: boolean;
  marginRight?: number;
}>`
  display: flex;
  justify-content: space-between;

  align-items: center;
  width: 5vw;
  height: 3rem;
  /* margin-right: ${(props) =>
    props.marginRight ? props.marginRight : "3rem"}; */
`;

const Checkbox = styled.div<{ clicked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.1rem;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  border: 1px solid lightgray;
  background-color: ${({ clicked }) => (clicked ? "ForestGreen " : "white")};
  transition: background-color 0.2s ease-in-out;
  .checkMark {
    color: white;
    opacity: ${({ clicked }) => (clicked ? "1" : "0")};
    transition: opacity 0.2s ease-in-out;
  }
`;

const CheckboxText = styled.div`
  /* padding: 0.1rem; */
  font-size: 1rem;
  font-weight: 600;
  margin-top: -0.1rem;
  color: black;
`;
